import { CREATE, UPDATE, GET, UPDATESTEP } from '../actions/project/projectActionTypes';

const initialState = {
    current: {},
    step: '1'
};

export default function project(state = initialState, action) {
    switch (action.type) {
        case CREATE: {
            return {
                ...state,
                current: action.payload
            };
        }
        case UPDATE: {
            return {
                ...state,
                current: action.payload
            };
        }
        case GET: {
            return {
                ...state,
                current: action.payload
            };
        }
        case UPDATESTEP: {
            return {
                ...state,
                step: action.payload
            };
        }
        default:
            return state;
    }
}
