// React
import { useState, useEffect, Fragment } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
    addNotification,
    updateNotification
} from '../../redux/actions/notification/notificationsActions';
import { setCurrentCluster } from '../../redux/actions/cluster/clusterActions';

// Utilities
import APICaller from '../../utils/APICaller';

// Materialize UI Includes
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import {
    Typography,
    TextField,
    Button,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    Stepper,
    Step,
    StepLabel,
    FormControlLabel,
    RadioGroup,
    FormLabel,
    Radio,
    Grid,
    FormHelperText,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

// Materialize Icons
import Check from '@mui/icons-material/Check';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

// Required custom component for the Step Connector to make it look like a line
const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)'
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4'
        }
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4'
        }
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1
    }
}));

// Icon to be used in the Stepper custom component
const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#784af4'
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor'
    }
}));

// Function that controls the icon that will show up in the stepper, either a standard circle or a completed icon if the step is already beyond the stage.
function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className='QontoStepIcon-completedIcon' />
            ) : (
                <div className='QontoStepIcon-circle' />
            )}
        </QontoStepIconRoot>
    );
}

export default function CreateCluster(props) {
    const dispatch = useDispatch();

    // Component Hooks
    const [step, setStep] = useState(0);
    const [err, setErr] = useState(false);
    const [saving, setSaving] = useState(false);
    const [open, setOpen] = useState(false);

    // Form Specific Hooks
    const [classifierFeatSelection, setClassifierFeatSelection] = useState(true);
    const [classifierThreshold, setClassifierThreshold] = useState(0.01);
    const [umapNumNeighbors, setUmapNumNeighbors] = useState(50);
    const [PCA, setPCA] = useState(true);
    const [GA, setGA] = useState(true);
    const [ga_model, setGa_model] = useState('Kmeans');
    const [init, setInit] = useState('k-means++');
    const [kalgo, setKAlgo] = useState('lloyd');
    const [dalgo, setDAlgo] = useState('auto');
    const [metric, setMetric] = useState('euclidean');

    // Because when we save the form, the E target will only include the elements currently visible, we need to set up the Algorithm Selection values as component hooks that we can easily retrieve, which are updated onChange
    const [ga_min, setGa_min] = useState(50);
    const [ga_max, setGa_max] = useState(100);
    const [ga_generations, setGa_generations] = useState(50);
    const [ga_crossoverP, setGa_crossoverP] = useState(0.5);
    const [ga_mutP, setGa_mutP] = useState(0.2);

    // Redux Hooks
    const universe = useSelector(state => state.universe.current);
    const project = useSelector(state => state.project.current);
    const cluster = useSelector(state => state.cluster.current);
    const user = useSelector(state => state.user);
    const notifications = useSelector(state => state.notifications);

    useEffect(() => {}, []);

    // Steps to be used with the Stepper Component
    const steps = ['Basic Settings', 'Algorithm Selection', 'Model Configuration'];

    // Default object to be used when adding a cluster. This default object will be used whenever no configurations want to be used by the user (execute Genetic Algorithm false).
    let defaultClusterJson = {
        universe_id: universe.id,
        user_id: user.id,
        parameters: {
            pca: true,
            classifier_feat_selection: true,
            classifier_threshold: 0.0001,
            genetic_algorithm: {
                execute: true,
                ga_min: 50,
                ga_max: 100,
                ga_model: 'Kmeans',
                ga_generations: 50,
                ga_crossoverP: 0.5,
                ga_mutP: 0.2,
                umap_num_neighbors: 50,
                model: {
                    kmeans: {
                        n_clusters: 4,
                        init: 'k-means++',
                        n_init: 10,
                        max_iter: 300,
                        algorithm: 'lloyd',
                        random_state: 42
                    },
                    db_scan: {
                        eps: 1,
                        min_samples: 5,
                        metric: 'euclidean',
                        algorithm: 'auto',
                        leaf_size: 30,
                        p: 2,
                        n_jobs: 1
                    }
                }
            }
        }
    };

    const handleNext = e => {
        e.preventDefault();
        setStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
        setStep(prevActiveStep => prevActiveStep - 1);
    };

    const cancelClusterCreation = e => {
        e.preventDefault();
        props.goTo(`universe_overview/${parseInt(universe.id)}`);
    };

    const createCluster = async e => {
        e.preventDefault();

        // Set up component hooks to display text on updates and status
        setSaving(true);

        try {
            // If the value of the execute Genetic Algorithm is false, then we bypass the need to retrieve any of the other details on the form, just sending all the default values with the default Array created before.
            let data;
            if (!GA) {
                data = defaultClusterJson;
            } else {
                data = {
                    universe_id: universe.id,
                    user_id: user.id,
                    parameters: {
                        pca: PCA,
                        classifier_feat_selection: classifierFeatSelection,
                        classifier_threshold: classifierThreshold,
                        genetic_algorithm: {
                            execute: GA,
                            ga_min: ga_min,
                            ga_max: ga_max,
                            ga_model: ga_model,
                            ga_generations: ga_generations,
                            ga_crossoverP: ga_crossoverP,
                            ga_mutP: ga_mutP,
                            umap_num_neighbors: umapNumNeighbors
                        }
                    }
                };

                if (ga_model === 'Kmeans') {
                    data.parameters.genetic_algorithm.model = {
                        kmeans: {
                            n_clusters: parseInt(e.target.n_clusters.value),
                            init: init,
                            n_init: parseInt(e.target.n_init.value),
                            max_iter: parseInt(e.target.max_iter.value),
                            algorithm: kalgo,
                            random_state: parseInt(e.target.random_state.value)
                        },
                        db_scan: {
                            eps: 1,
                            min_samples: 5,
                            metric: 'euclidean',
                            algorithm: 'auto',
                            leaf_size: 30,
                            p: 2,
                            n_jobs: 1
                        }
                    };
                } else {
                    data.model = {
                        db_scan: {
                            eps: parseInt(e.target.eps.value),
                            min_samples: parseInt(e.target.min_samples.value),
                            metric: metric,
                            algorithm: dalgo,
                            leaf_size: parseInt(e.target.leaf_size.value),
                            p: parseInt(e.target.pParam.value),
                            n_jobs: parseInt(e.target.n_jobs.value)
                        },
                        kmeans: {
                            n_clusters: 4,
                            init: 'k-means++',
                            n_init: 10,
                            max_iter: 300,
                            algorithm: 'lloyd',
                            random_state: 42
                        }
                    };
                }
            }

            // When creating a cluster, we are calling the API directly. So instead of using the APICaller utility, we simply use axios directly on the code.
            let clusterInfo = await APICaller.clusterRequest('createCluster', data);

            // Open Dialog once we have set up the current cluster on the redux state
            setOpen(true);

            setSaving(false);

            dispatch(setCurrentCluster(clusterInfo.cluster));

            if (notifications.find(n => n.id === universe.id && n.type === 'cluster')) {
                dispatch(
                    updateNotification({
                        id: universe.id,
                        started: new Date(),
                        isNew: true,
                        completed: 'NA',
                        type: 'cluster'
                    })
                );
            } else {
                dispatch(
                    addNotification({
                        id: universe.id,
                        name: universe.universeName,
                        project: project.projectName,
                        started: new Date(),
                        isNew: true,
                        completed: 'NA',
                        type: 'cluster'
                    })
                );
            }

            setSaving(false);
        } catch (error) {
            setSaving(false);
            props.setMessage({
                text: (
                    <Typography>Error calling the clustering system, per {err.message}</Typography>
                ),
                status: 'error'
            });
            props.setOpen(true);
            return;
        }
    };

    const openUniverse = e => {
        e.preventDefault();
        props.goTo(`universe_overview/${universe.id}`);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                pt: 3,
                pb: 3,
                height: '95%'
            }}
        >
            <Box
                sx={{
                    width: 3 / 4,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexFlow: 'column'
                }}
            >
                <Typography variant='h1' sx={{ textAlign: 'left', mb: 2, fontSize: '2.5rem' }}>
                    Create New Cluster
                </Typography>
                <Stepper
                    alternativeLabel
                    activeStep={step}
                    connector={<QontoConnector />}
                    sx={{ width: 1, mb: 4, mt: 4 }}
                >
                    {steps.map(label => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <form onSubmit={e => createCluster(e)} style={{ width: '100%' }}>
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
                            columnGap: 2
                        }}
                    >
                        {/* Step 1 inpusts */}
                        {step === 0 && (
                            <Box sx={{ pb: 3 }}>
                                <FormControl sx={{ width: 1 }}>
                                    <FormLabel id='PCA-row-radio-buttons-group-label'>
                                        Run Principal Component Analysis:
                                    </FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby='PCA-row-radio-buttons-group-label'
                                        name='PCA-radio-buttons-group'
                                        value={PCA}
                                        onChange={e => {
                                            setPCA(e.target.value);
                                        }}
                                    >
                                        <FormControlLabel
                                            value={true}
                                            control={<Radio />}
                                            label='True'
                                        />
                                        <FormControlLabel
                                            value={false}
                                            control={<Radio />}
                                            label='False'
                                        />
                                    </RadioGroup>
                                </FormControl>
                                <FormControl sx={{ width: 1, mt: 2 }}>
                                    <FormLabel id='classifier_feat_selection-row-radio-buttons-group-label'>
                                        Run Classifier Feature Selection:
                                    </FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby='classifier_feat_selection-row-radio-buttons-group-label'
                                        name='classifier_feat_selection-radio-buttons-group'
                                        value={classifierFeatSelection}
                                        onChange={e => {
                                            setClassifierFeatSelection(e.target.value);
                                        }}
                                    >
                                        <FormControlLabel
                                            value={true}
                                            control={<Radio />}
                                            label='True'
                                        />
                                        <FormControlLabel
                                            value={false}
                                            control={<Radio />}
                                            label='False'
                                        />
                                    </RadioGroup>
                                </FormControl>
                                <Grid
                                    sx={{
                                        display: 'grid',
                                        gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
                                        columnGap: 2,
                                        rowGap: 2,
                                        width: 1,
                                        mt: 2
                                    }}
                                >
                                    <TextField
                                        required
                                        sx={{ width: 1 }}
                                        id='classifier_threshold'
                                        label='Classifier Treshold'
                                        variant='filled'
                                        inputProps={{
                                            'data-testid': 'test_classifier_threshold',
                                            min: 0,
                                            max: 1,
                                            step: '.0001'
                                        }}
                                        type='number'
                                        disabled={saving}
                                        value={classifierThreshold}
                                        onChange={e => {
                                            setClassifierThreshold(e.target.value);
                                        }}
                                        helperText='Mean decrease in impurity cutoff value for feature importance'
                                    />
                                    <TextField
                                        sx={{ width: 1 }}
                                        id='umap_num_neighbors'
                                        label='UMAP Number of Neighbors'
                                        variant='filled'
                                        inputProps={{
                                            'data-testid': 'test_umap_num_neighbors',
                                            min: 25,
                                            max: 200
                                        }}
                                        type='number'
                                        disabled={saving}
                                        value={umapNumNeighbors}
                                        onChange={e => {
                                            setUmapNumNeighbors(e.target.value);
                                        }}
                                        helperText='Number of neighbors for umap calculation'
                                    />
                                </Grid>
                            </Box>
                        )}

                        {/* Step 2 inpusts */}
                        {step === 1 && (
                            <Box sx={{ pb: 3 }}>
                                <FormControl sx={{ width: 1 }}>
                                    <FormLabel id='GA-row-radio-buttons-group-label'>
                                        Execute Genetic Algorithm
                                    </FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby='GA-row-radio-buttons-group-label'
                                        name='GA-radio-buttons-group'
                                        value={GA}
                                        onChange={e => {
                                            setGA(e.target.value === 'true' ? true : false);
                                        }}
                                    >
                                        <FormControlLabel
                                            value={true}
                                            control={<Radio />}
                                            label='True'
                                            inputProps={{
                                                'data-testid': 'test_executeGA_true'
                                            }}
                                        />
                                        <FormControlLabel
                                            value={false}
                                            control={<Radio />}
                                            label='False'
                                            inputProps={{
                                                'data-testid': 'test_executeGA_false'
                                            }}
                                        />
                                    </RadioGroup>
                                </FormControl>
                                {GA && (
                                    <Grid
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns:
                                                'repeat(auto-fit, minmax(300px, 1fr))',
                                            columnGap: 2,
                                            rowGap: 2,
                                            width: 1,
                                            mt: 2
                                        }}
                                    >
                                        <TextField
                                            required
                                            sx={{ width: 1 }}
                                            id='ga_min'
                                            label='Minimum Features'
                                            variant='filled'
                                            inputProps={{
                                                'data-testid': 'test_ga_min',
                                                min: 20,
                                                max: 100
                                            }}
                                            type='number'
                                            disabled={saving}
                                            min='0'
                                            value={ga_min}
                                            onChange={e => {
                                                setGa_min(e.target.value);
                                            }}
                                            helperText='Min number of features to be selected by GA'
                                        />
                                        <TextField
                                            required
                                            sx={{ width: 1 }}
                                            id='ga_max'
                                            label='Maximum Features'
                                            variant='filled'
                                            inputProps={{
                                                'data-testid': 'test_algorithmMax',
                                                min: 20,
                                                max: 500
                                            }}
                                            type='number'
                                            disabled={saving}
                                            value={ga_max}
                                            onChange={e => {
                                                setGa_max(e.target.value);
                                            }}
                                            helperText='Max number of features to be selected by GA'
                                        />
                                        <TextField
                                            required
                                            sx={{ width: 1 }}
                                            id='ga_generations'
                                            label='Generations'
                                            variant='filled'
                                            inputProps={{
                                                'data-testid': 'test_ga_generations',
                                                min: 50
                                            }}
                                            type='number'
                                            disabled={saving}
                                            value={ga_generations}
                                            onChange={e => {
                                                setGa_generations(e.target.value);
                                            }}
                                            helperText='Number of GA generations'
                                        />
                                        <TextField
                                            required
                                            sx={{ width: 1 }}
                                            id='ga_crossoverP'
                                            label='Crossover P Value'
                                            variant='filled'
                                            inputProps={{
                                                'data-testid': 'test_ga_crossoverP',
                                                min: 0,
                                                max: 1,
                                                step: '.1'
                                            }}
                                            type='number'
                                            disabled={saving}
                                            value={ga_crossoverP}
                                            onChange={e => {
                                                setGa_crossoverP(e.target.value);
                                            }}
                                            helperText='Probability of crossover between two individuals in GA'
                                        />
                                        <TextField
                                            required
                                            sx={{ width: 1 }}
                                            id='ga_mutP'
                                            label='Mutation P Value'
                                            variant='filled'
                                            inputProps={{
                                                'data-testid': 'test_ga_mutP',
                                                min: 0,
                                                max: 1,
                                                step: '.1'
                                            }}
                                            type='number'
                                            disabled={saving}
                                            value={ga_mutP}
                                            onChange={e => {
                                                setGa_mutP(e.target.value);
                                            }}
                                            helperText='Probability an individual will mutate in GA'
                                        />
                                    </Grid>
                                )}
                            </Box>
                        )}

                        {/* Step 3 inpusts */}
                        {step === 2 && (
                            <Box sx={{ pb: 3 }}>
                                <FormControl sx={{ width: 1 }}>
                                    <FormLabel id='ga_model-row-radio-buttons-group-label'>
                                        Model used for genetic algorithm feature selection:
                                    </FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby='ga_model-row-radio-buttons-group-label'
                                        name='ga_model-radio-buttons-group'
                                        value={ga_model}
                                        onChange={e => {
                                            setGa_model(e.target.value);
                                        }}
                                    >
                                        <FormControlLabel
                                            value='Kmeans'
                                            control={<Radio />}
                                            label='Kmeans'
                                        />
                                        <FormControlLabel
                                            value='DBScan'
                                            control={<Radio />}
                                            label='DBScan'
                                        />
                                    </RadioGroup>
                                </FormControl>
                                <Grid
                                    sx={{
                                        display: 'grid',
                                        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
                                        columnGap: 2,
                                        rowGap: 2,
                                        width: 1,
                                        mt: 2
                                    }}
                                >
                                    {ga_model === 'Kmeans' ? (
                                        <Fragment>
                                            <FormControl>
                                                <InputLabel id='init-helper-label'>
                                                    Method for initialization
                                                </InputLabel>
                                                <Select
                                                    required
                                                    labelId='init-helper-label'
                                                    id='init-select'
                                                    label='Method for initialization'
                                                    inputProps={{
                                                        'data-testid': 'init-select-element'
                                                    }}
                                                    sx={{ width: 1 }}
                                                    helperText='Method for initialization'
                                                    value={init}
                                                    onChange={e => {
                                                        setInit(e.target.value);
                                                    }}
                                                >
                                                    <MenuItem value='k-means++'>k-means++</MenuItem>
                                                    <MenuItem value='random'>Random</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <FormControl>
                                                <InputLabel id='kmeansalgorithm-helper-label'>
                                                    K-means algorithm to use
                                                </InputLabel>
                                                <Select
                                                    required
                                                    labelId='kmeansalgorithm-helper-label'
                                                    id='kmeansalgorithm_select'
                                                    label='K-means algorithm to use'
                                                    inputProps={{
                                                        'data-testid':
                                                            'kmeansalgorithm-select-element'
                                                    }}
                                                    sx={{ width: 1 }}
                                                    helperText='K-means algorithm to use'
                                                    value={kalgo}
                                                    onChange={e => {
                                                        setKAlgo(e.target.value);
                                                    }}
                                                >
                                                    <MenuItem value='lloyd'>lloyd</MenuItem>
                                                    <MenuItem value='elkan'>elkan</MenuItem>
                                                    <MenuItem value='auto'>auto</MenuItem>
                                                    <MenuItem value='full'>full</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <TextField
                                                required
                                                sx={{ width: 1 }}
                                                id='n_init'
                                                label='Number Init'
                                                variant='filled'
                                                inputProps={{
                                                    'data-testid': 'test_n_init',
                                                    min: 1,
                                                    max: 100
                                                }}
                                                type='number'
                                                disabled={saving}
                                                min='0'
                                                defaultValue={10}
                                                helperText='Number of time the k-means algorithm will be run with different centroid seeds'
                                            />
                                            <TextField
                                                required
                                                sx={{ width: 1 }}
                                                id='max_iter'
                                                label='Max Iterations'
                                                variant='filled'
                                                inputProps={{
                                                    'data-testid': 'test_max_iter',
                                                    min: 50,
                                                    max: 500
                                                }}
                                                type='number'
                                                disabled={saving}
                                                min='0'
                                                defaultValue={300}
                                                helperText='Maximum number of iterations of the k-means algorithm for a single run'
                                            />
                                            <TextField
                                                required
                                                sx={{ width: 1 }}
                                                id='n_clusters'
                                                label='Number of Clusters'
                                                variant='filled'
                                                inputProps={{
                                                    'data-testid': 'test_n_clusters',
                                                    min: 2,
                                                    max: 15
                                                }}
                                                type='number'
                                                disabled={saving}
                                                min='0'
                                                defaultValue={4}
                                                helperText='The number of clusters to form'
                                            />

                                            <TextField
                                                sx={{ width: 1 }}
                                                id='random_state'
                                                label='Random State'
                                                variant='filled'
                                                inputProps={{
                                                    'data-testid': 'test_random_state',
                                                    min: 0
                                                }}
                                                type='number'
                                                disabled={saving}
                                                defaultValue={42}
                                                helperText='Determines random number generation for centroid initialization'
                                            />
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <FormControl>
                                                <InputLabel id='metric-helper-label'>
                                                    Metric:
                                                </InputLabel>
                                                <Select
                                                    required
                                                    labelId='metric-helper-label'
                                                    id='metric'
                                                    label='Metric when calculating distance:'
                                                    defaultValue=''
                                                    value={metric}
                                                    onChange={e => {
                                                        setMetric(e.target.value);
                                                    }}
                                                    inputProps={{
                                                        'data-testid': 'metric-select-element'
                                                    }}
                                                    sx={{ width: 1 }}
                                                    helperText='Method for initialization'
                                                >
                                                    <MenuItem value='euclidean'>Euclidean</MenuItem>
                                                    <MenuItem value='cityblock'>Cityblock</MenuItem>
                                                    <MenuItem value='cosine'>Cosine</MenuItem>
                                                    <MenuItem value='l1'>L1</MenuItem>
                                                    <MenuItem value='l2'>L2</MenuItem>
                                                    <MenuItem value='manhattan'>Manhattan</MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    The metric to use when calculating distance
                                                </FormHelperText>
                                            </FormControl>
                                            <FormControl>
                                                <InputLabel id='dbscanalgorithm-helper-label'>
                                                    DBScan algorithm to use:
                                                </InputLabel>
                                                <Select
                                                    required
                                                    labelId='dbscanalgorithm-helper-label'
                                                    id='dbscanalgorithm_select'
                                                    label='K-means algorithm to use'
                                                    inputProps={{
                                                        'data-testid':
                                                            'dbscanalgorithm-select-element'
                                                    }}
                                                    sx={{ width: 1 }}
                                                    helperText='DBScan algorithm to use'
                                                    value={dalgo}
                                                    onChange={e => {
                                                        setDAlgo(e.target.value);
                                                    }}
                                                >
                                                    <MenuItem value='auto'>auto</MenuItem>
                                                    <MenuItem value='ball_tree'>ball_tree</MenuItem>
                                                    <MenuItem value='kd_tree'>kd_tree</MenuItem>
                                                    <MenuItem value='brute'>brute</MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    Algorithm to be used by the NearestNeighbors
                                                    module to compute pointwise distance
                                                </FormHelperText>
                                            </FormControl>
                                            <TextField
                                                required
                                                sx={{ width: 1 }}
                                                id='eps'
                                                label='EPS'
                                                variant='filled'
                                                inputProps={{
                                                    'data-testid': 'test_eps',
                                                    min: 0,
                                                    max: 2,
                                                    step: '.1'
                                                }}
                                                type='number'
                                                disabled={saving}
                                                min='0'
                                                defaultValue={1}
                                                helperText='The maximum distance between two samples for one to be considered as in the neighborhood of the other.'
                                            />
                                            <TextField
                                                required
                                                sx={{ width: 1 }}
                                                id='min_samples'
                                                label='Minimum Samples'
                                                variant='filled'
                                                inputProps={{
                                                    'data-testid': 'test_min_samples',
                                                    min: 1,
                                                    max: 10
                                                }}
                                                type='number'
                                                disabled={saving}
                                                defaultValue={5}
                                                helperText='The number of samples (or total weight) in a neighborhood for a point to be considered as a core point.'
                                            />
                                            <TextField
                                                required
                                                sx={{ width: 1 }}
                                                id='leaf_size'
                                                label='Leaf Size'
                                                variant='filled'
                                                inputProps={{
                                                    'data-testid': 'test_leaf_size',
                                                    min: 10,
                                                    max: 100
                                                }}
                                                type='number'
                                                disabled={saving}
                                                defaultValue={30}
                                                helperText='Leaf size passed to BallTree or cKDTree.'
                                            />
                                            <TextField
                                                required
                                                sx={{ width: 1 }}
                                                id='pParam'
                                                label='P'
                                                variant='filled'
                                                inputProps={{
                                                    'data-testid': 'test_pParam',
                                                    min: 0
                                                }}
                                                type='number'
                                                disabled={saving}
                                                min='0'
                                                defaultValue={2}
                                                helperText='The power of the Minkowski metric to be used to calculate distance between points'
                                            />
                                            <TextField
                                                required
                                                sx={{ width: 1 }}
                                                id='n_jobs'
                                                label='N Jobs'
                                                variant='filled'
                                                inputProps={{
                                                    'data-testid': 'test_n_jobs',
                                                    min: 1,
                                                    max: 10
                                                }}
                                                type='number'
                                                disabled={saving}
                                                defaultValue={1}
                                                helperText='The number of parallel jobs to run.'
                                            />
                                        </Fragment>
                                    )}
                                </Grid>
                            </Box>
                        )}
                    </Box>

                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr 1fr',
                            columnGap: 2,
                            width: 3 / 4,
                            paddingRight: 1
                        }}
                    >
                        {step !== 0 && (
                            <Button
                                variant='outlined'
                                size='large'
                                sx={{ mt: 2, width: 1 }}
                                onClick={handleBack}
                            >
                                Back
                            </Button>
                        )}

                        {(step === 1 && !GA) || step === steps.length - 1 ? (
                            <Button
                                variant='contained'
                                size='large'
                                sx={{ mt: 2, width: 1 }}
                                type='submit'
                            >
                                Save Cluster
                            </Button>
                        ) : (
                            <Button
                                variant='outlined'
                                size='large'
                                sx={{ mt: 2, width: 1 }}
                                onClick={handleNext}
                            >
                                Next
                            </Button>
                        )}
                        <Button
                            variant='contained'
                            size='large'
                            sx={{ mt: 2, width: 1 }}
                            color='error'
                            onClick={e => cancelClusterCreation(e)}
                            disabled={saving}
                        >
                            Cancel
                        </Button>
                    </Box>
                </form>
            </Box>
            <Dialog
                open={open}
                onClose={() => {
                    setOpen(false);
                    openUniverse();
                }}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title' sx={{ display: 'flex', alignItems: 'center' }}>
                    <CheckCircleOutlineIcon color='success' fontSize='large' sx={{ mr: 2 }} />
                    Your cluster is being generated!
                </DialogTitle>
                <DialogContent sx={{ fontWeight: 400 }}>
                    {cluster && cluster.etl_job_summary && (
                        <DialogContentText id='alert-dialog-description' sx={{ fontWeight: '400' }}>
                            <p>
                                Your cluster is being generated with Job ID{' '}
                                <strong>{cluster.etl_job_summary.JobRunId}</strong>.
                            </p>
                            <p>
                                Once the process is done, you will receive a notification on the
                                application and an email.
                            </p>
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={openUniverse}>Open Universe Overview</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
