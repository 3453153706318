// React
import React, {Fragment, useEffect, useState} from 'react';

// Redux
import {useDispatch, useSelector} from 'react-redux';
import {buildQuery, updateCustomquery, updateUseCustom} from '../../redux/actions/query/queryActions';
import {updateUniverse, updateUniverseList} from '../../redux/actions/universe/universeActions';

// Components
import ProjectHeader from '../layout/ProjectHeader';
import Demographics from '../queryBuilder/Demographics';
import Diagnosis from '../queryBuilder/Diagnosis';
import Procedures from '../queryBuilder/Procedures';
import Prescriptions from '../queryBuilder/Prescriptions';
import Specialist from '../queryBuilder/Specialist';
import UniverseCart from '../queryBuilder/UniverseCart';

// Utilities
import APICaller from '../../utils/APICaller';

// Import MUI Tabs components plus icons required
import {
    Box,
    Breadcrumbs,
    Button,
    CircularProgress,
    Container,
    Fab,
    Link,
    Step,
    StepButton,
    Stepper,
    Typography
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBackIcon from '@mui/icons-material/NavigateBefore';
import {green} from '@mui/material/colors';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import WarningIcon from '@mui/icons-material/Warning';

// Dialog Includes
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const steps = [
    'Demographics',
    'Diagnosis',
    'Procedures',
    'Prescriptions',
    'Specialist',
    'Universe Cart'
];

export default function QueryBuilder(props) {
    const [activeStep, setActiveStep] = useState(0);
    const [onHoldStep, setOnHoldStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [updateMessage, setUpdateMessage] = useState('No Changes');
    const [changes, setChanges] = useState(false);
    const [open, setOpen] = useState(false);

    const query = useSelector(state => state.query);
    const user = useSelector(state => state.user);
    const universe = useSelector(state => state.universe.current);
    const universes = useSelector(state => state.universe.universeList);
    const dispatch = useDispatch();

    const buttonSx = {
        ...(success && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700]
            }
        })
    };

    useEffect(() => {
        //   window.dataLayer.push({
        //     event: 'pageview',
        //     page: {
        //         url: '',
        //         title: 'Query Builder Page'
        //     }
        // });

        const fetchData = async () => {
            // When loading the Query Builder, we need to check and see if there's already a query in place, that we can use to render update data.
            if (query.type === 'add') {
                dispatch(
                    buildQuery({
                        type: 'add', // We are going to add a key for type just to keep track if this is a new query or an updated one, which will help us define which endpoints to call on universe as well as some UI changes
                        demographics: {
                            age: [0],
                            gender: [0],
                            region: [0],
                            state: []
                        },
                        // The diagnosis is an array of groups, each with their own values and operators
                        diagnosis: [
                            {
                                type: 'inclusion',
                                dimension: []
                            },
                            {
                                type: 'exclusion',
                                dimension: []
                            }
                        ],
                        // The prescriptions is an array of groups, each with their own values and operators
                        prescriptions: [
                            {
                                type: 'inclusion',
                                dimension: []
                            },
                            {
                                type: 'exclusion',
                                dimension: []
                            }
                        ],
                        procedures: [
                            {
                                type: 'inclusion',
                                dimension: []
                            },
                            {
                                type: 'exclusion',
                                dimension: []
                            }
                        ],
                        specialists: [
                            {
                                type: 'inclusion',
                                dimension: []
                            },
                            {
                                type: 'exclusion',
                                dimension: []
                            }
                        ]
                    })
                );
            }
        };

        fetchData();
        // eslint-disable-next-line no-use-before-define
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Adding a constant value to keep track of the breadcrumbs of this page. For Query Builder, we are only working with the Project Overview page and the Query Builder
    const breadcrumbs = [
        <Link
            underline='hover'
            key='2'
            color='secondary'
            component="button"
            onClick={() => {props.goTo(`universe_overview/${parseInt(universe.id)}`);}}
            sx={{ fontWeight: 500, fontSize: '1rem' }}
        >
            {universe.universeName} Overview
        </Link>,
        <Typography key='3' sx={{ color: 'white' }}>
            Query Builder
        </Typography>
    ];

    const dialogHandleClose = event => {
        setOpen(false);
    };

    const handleNext = () => {
        handleActiveStepChange(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
        handleActiveStepChange(prevActiveStep => prevActiveStep - 1);
    };

    const handleStep = step => () => {
        setSuccess(false);
        handleActiveStepChange(step);
        setUpdateMessage('No Changes');
        setChanges(false);
    };

    // Because we want to update the universe for every change on the navigation, we need a separate function that will handle both updating the component state and updating the universe at the same time
    const handleActiveStepChange = (step, confirmation = false) => {
        // Whenever we are moving to a tab, we want to ensure that we are only saving the tab we were on previously, by using the previously activeStep value before updating it. We only do this when the current tab had Unsaved changes.
        // Because of the new functionality of the custom code, we don't want to save when navigating away of a tab under two specific conditions: The user is a power user and they are navigating to the Universe Cart. This needs to be validated in here. To make things easier for now, we will just not trigger a save whenever navigating to the Universe Cart.
        if (updateMessage === 'Unsaved Changes' && step !== 5) {
            saveUniverse(activeStep);
        }

        // Before we set the activeStep, we want to check where we are and if the customQuery is enabled. If we are coming from the Universe Cart and the customQuery is not an blank string, we want to display a dialog for confirmation
        if (activeStep === 5 && query.useCustom) {
            if (confirmation) {
                // Before we call the data, we want to ensure that everytime we open the component, we clear the customQuery value.
                dispatch(updateCustomquery(''));
                dispatch(updateUseCustom(false));
                setOpen(false);
            } else {
                setOnHoldStep(step);
                setOpen(true);
                return;
            }
        }

        setActiveStep(step);
    };

    // Function to retrieve the number of dimensions configure in any given step. The default value is 0.
    const getNumberDimensions = index => {
        let numberDimensions = 0;

        // If the index is 0, we are handling the demographics, which is different than the rest of the steps included in the QB. So the dimensions that we get from demographics need to be calculated via another way, whereas the rest are the same and we only need to change which array we are pointing to.
        if (index === 0) {
            if (query.demographics) {
                numberDimensions =
                    query.demographics.age.length +
                    query.demographics.gender.length +
                    query.demographics.region.length +
                    query.demographics.state.length;
            }
        } else if (index === 5) {
            // If the index is 5, this is the Universe Cart and want this to represent the sum of all the other dimensions
            if (query.demographics) {
                numberDimensions =
                    query.demographics.age.length +
                    query.demographics.gender.length +
                    query.demographics.region.length +
                    query.demographics.state.length;
                ['diagnosis', 'procedures', 'prescriptions', 'specialists'].forEach(element => {
                    query[element].forEach(group => {
                        if (group.dimension) {
                            group.dimension.forEach(dimension => {
                                numberDimensions += dimension.results.length;
                            });
                        }
                    });
                });
            }
        } else {
            let arrayToEvaluate;
            switch (index) {
                case 1: //Diagnosis
                    arrayToEvaluate = query.diagnosis;
                    break;
                case 2: //Procedures
                    arrayToEvaluate = query.procedures;
                    break;
                case 3: //Prescriptions
                    arrayToEvaluate = query.prescriptions;
                    break;
                case 4: //Specialists
                    arrayToEvaluate = query.specialists;
                    break;
                default:
                    break;
            }

            if (arrayToEvaluate) {
                let dimensionCounter = 0;
                arrayToEvaluate.forEach(group => {
                    if (group.dimension) {
                        group.dimension.forEach(dimension => {
                            dimensionCounter += dimension.results.length;
                        });
                    }
                });
                numberDimensions = dimensionCounter;
            }
        }
        return numberDimensions;
    };

    const registerChanges = () => {
        setSuccess(false);
        setUpdateMessage('Unsaved Changes');
        setChanges(true);
    };

    const saveUniverseSync = async () => {
        if (!loading) {
            setSuccess(false);
            setLoading(true);
            await saveUniverse(activeStep);
            setUpdateMessage('Changes Saved');
            setSuccess(true);
            setLoading(false);
        }
        // stop loader spin
    };

    // Utility function that retrieves the values from the query state and add them into the array to be used when generating the universe object. We can call this function by either id or code.
    const retrieveQueryValues = mode => {
        // Create an object that will store this values.
        const filters = {
            inclusions: [
                {
                    demographics: {
                        age_ranges: {
                            values: []
                        },
                        genders: {
                            values: []
                        },
                        states: {
                            values: []
                        }
                    }
                },
                {
                    diagnosis: {
                        AND: {
                            and_values: []
                        },
                        OR: {
                            or_values: []
                        }
                    }
                },
                {
                    procedures: {
                        AND: {
                            and_values: []
                        },
                        OR: {
                            or_values: []
                        }
                    }
                },
                {
                    prescriptions: {
                        AND: {
                            and_values: []
                        },
                        OR: {
                            or_values: []
                        }
                    }
                },
                {
                    visit_specialty: {
                        AND: {
                            and_values: []
                        },
                        OR: {
                            or_values: []
                        }
                    }
                }
            ],
            exclusions: [
                {
                    demographics: {
                        age_ranges: {
                            values: []
                        },
                        genders: {
                            values: []
                        },
                        states: {
                            values: []
                        }
                    }
                },
                {
                    diagnosis: {
                        AND: {
                            and_values: []
                        },
                        OR: {
                            or_values: []
                        }
                    }
                },
                {
                    procedures: {
                        AND: {
                            and_values: []
                        },
                        OR: {
                            or_values: []
                        }
                    }
                },
                {
                    prescriptions: {
                        AND: {
                            and_values: []
                        },
                        OR: {
                            or_values: []
                        }
                    }
                },
                {
                    visit_specialty: {
                        AND: {
                            and_values: []
                        },
                        OR: {
                            or_values: []
                        }
                    }
                }
            ]
        };

        // Add the demographics array. Because are now storing all the details of age, gender and states, we need to ensure that we have to go through a similar process as the rest of dimensions, processing an object instead of just an array. While these work differently from the other dimensions, all demographic elements work the same and can be handled through a string foreach
        [
            {
                id: 'age_ranges',
                name: 'age',
                data: 'listages'
            },
            {
                id: 'genders',
                name: 'gender',
                data: 'listgenders'
            },
            {
                id: 'states',
                name: 'state',
                data: 'liststates'
            }
        ].forEach(demoElement => {
            if (query.demographics[demoElement.name].findIndex(a => a === 0) === 0) {
                filters.inclusions[0].demographics[demoElement.id].values = [];
            } else {
                query.demographics[[demoElement.name]].forEach(el => {
                    el = query.optionList[demoElement.data].find(dEl => dEl.id === el);
                    filters.inclusions[0].demographics[demoElement.id].values.push(el[mode]);
                });
            }
        });

        // Add the rest of the dimensions. We do this with multiple ForEachs where we will add values for each of the dimensions, for each of the types (includion or exclusion) and for each of the dimensions that we have in our state

        [
            {
                id: 1,
                name: 'diagnosis',
                data: 'diagnosisData'
            },
            {
                id: 2,
                name: 'procedures',
                data: 'proceduresData'
            },
            {
                id: 3,
                name: 'prescriptions',
                data: 'prescriptionsData'
            },
            {
                id: 4,
                name: 'visit_specialty',
                data: 'specialistsData'
            }
        ].forEach(dimension => {
            [0, 1].forEach(operator => {
                let type = operator === 0 ? 'inclusions' : 'exclusions';
                let queryDimension = dimension.name;

                // Visit Specialty is a special dimension where there are two main differences. Not just on the name being used but also on regards on data not having a code. Instead of using code, we need to use description for this dimension only.
                if (queryDimension === 'visit_specialty') {
                    queryDimension = 'specialists';
                    mode = mode === 'code' ? 'description' : mode;
                }

                query[queryDimension][operator].dimension.forEach(stateDimension => {
                    if (stateDimension.operator === 'and') {
                        stateDimension.results.forEach(result => {
                            // Update the result value to be the full expected object if we are loading this from a save universe
                            result = result[mode]
                                ? result
                                : query[dimension.data].find(dItem => dItem.id === result);
                            filters[type][dimension.id][dimension.name].AND.and_values.push(
                                result[mode]
                            );
                        });
                    } else {
                        stateDimension.results.forEach(result => {
                            result = result[mode]
                                ? result
                                : query[dimension.data].find(dItem => dItem.id === result);
                            filters[type][dimension.id][dimension.name].OR.or_values.push(
                                result[mode]
                            );
                        });
                    }
                });
            });
        });

        return filters;
    };

    // Function that will generate a universe object. While QueryBuilder will always require the update_universe_metadata, we do need to generate the universe at least once, on the first save, so we have a criteriaJson object. We will also use the same function on UniverseCart so we can reuse this same function and pass it as props to the UniverseCart easily.

    const generateUniverse = (mode = 'fullsave') => {
        let universe_data = {
            universe_id: universe.id,
            universe_name: universe.universeName,
            universe_description: universe.universeDescription,
            user_id: user.id,
            filters: retrieveQueryValues('id')
        };

        universe_data.description_json_criteria = retrieveQueryValues('code');

        return universe_data;
    };

    // Function that will save the state data and normalize it to what the endpoint expects universe to be. We do this directly by working on a new object called universe that will include all the state data from the dimensions
    const saveUniverse = async () => {
        // Depending if we are originally saving the universe the first time or simply updating something pre-existing, we need to call different endpoints with a different structure on the data. To make things easier we are going to keep a local variable to know which of the objects to use when saving below
        let update_universe_metadata = {
            universe_id: universe.id,
            universe_name: universe.universeName,
            universe_description: universe.universeDescription,
            criteria: '',
            criteria_json: '',
            user_id: user.id
        };

        // Because we will only create the universe on the demographics tab, any further navigation done on the QB will proceed as an update.
        // Therefore, we need to retrieve the criteriaJson if we don't have it available. Because the format that criteriaJson expects is really specific,
        // we need to ensure that we get this from the universe array that we update whenever we add a change the first time.
        // Also, if the query is new, this means that we necessarily need to call the filterpatients first.
        // So we can create the universe with an empty universe object first and then we can update without issues.
        if (query.type === 'add') {
            let universe_data = generateUniverse();
            await APICaller.sendRequest('filterpatients', {
                universe_data: universe_data,
                flag: 2
            });
        }

        if (
            universe.criteriaJson &&
            universe.criteriaJson !== 'null' &&
            JSON.parse(universe.criteriaJson).filters
        ) {
            update_universe_metadata.criteria_json = JSON.parse(universe.criteriaJson);
        } else {
            update_universe_metadata.criteria_json = generateUniverse();
        }

        update_universe_metadata.criteria_json.filters = retrieveQueryValues('id');

        if (query.type === 'add') {
            query.type = 'update';
        }

        await APICaller.sendRequest('updateuniverse', {
            update_universe_metadata
        });

        // Update the Universe List with the new criteria so we can load it back if needed without calling all the data again
        const newUniverseList = [...universes];
        newUniverseList.find(u => u.id === universe.id).criteria_json =
            update_universe_metadata.criteria_json;
        newUniverseList.find(u => u.id === universe.id).criteriaJson = JSON.stringify(
            update_universe_metadata.criteria_json
        );

        await dispatch(updateUniverseList(newUniverseList));

        // Once we have updated the universes list, we can proceed to update the universe
        dispatch(
            updateUniverse({
                ...universe,
                criteria_json: update_universe_metadata.criteria_json,
                criteriaJson: JSON.stringify(update_universe_metadata.criteria_json)
            })
        );

        props.setOpen(true);
        props.setMessage({
            text: <Typography>{universe.universeName} has been saved successfully.</Typography>,
            status: 'info'
        });
        setLoading(false);
    };

    return (
        <Fragment>
            <ProjectHeader goTo={props.goTo} />
            <Container
                sx={{
                    width: '100%',
                    backgroundColor: '#0c1742',
                    pb: 2,
                    pt: 2,
                    color: 'white',
                    mb: 2
                }}
                maxWidth='false'
            >
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize='small' color='whitePalette' />}
                    aria-label='breadcrumb'
                >
                    {breadcrumbs}
                </Breadcrumbs>
                <div>
                    <Typography variant='h2' gutterBottom sx={{ mt: 2, fontSize: '2.125rem' }}>
                        {universe.universeName} Query Builder
                    </Typography>
                    <p>Select criteria from the 5 dimensions</p>
                </div>
                <Stepper activeStep={activeStep} nonLinear sx={{ mb: 1, mt: 1 }}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const testVariable = label !== 'Universe Cart' ? label : 'cart';

                        return (
                            <Step key={label} {...stepProps}>
                                <StepButton color='inherit' onClick={handleStep(index)}>
                                    <p style={{ marginBottom: '0px', color: 'white' }}>{label}</p>
                                    <small
                                        style={{ color: 'white' }}
                                        data-testid={`test_${testVariable}_dimensions`}
                                    >
                                        {getNumberDimensions(index)} Dimensions
                                    </small>
                                </StepButton>
                            </Step>
                        );
                    })}
                </Stepper>
            </Container>
            <Container sx={{ pt: 2, pb: 4 }} maxWidth='false'>
                {/* The Save Changes button should be available in all steps except the last one */}
                {activeStep !== 5 && (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            float: 'right',
                            position: 'absolute',
                            right: 2
                        }}
                    >
                        <Box sx={{ m: 1, position: 'relative', mt: 0 }}>
                            <Fab
                                aria-label='save'
                                color='primary'
                                sx={buttonSx}
                                onClick={saveUniverseSync}
                                disabled={(!success || loading) && !changes}
                            >
                                {success ? <CheckIcon /> : <SaveIcon />}
                            </Fab>
                            {loading && (
                                <CircularProgress
                                    size={68}
                                    sx={{
                                        color: green[500],
                                        position: 'absolute',
                                        top: -6,
                                        left: -6,
                                        zIndex: 1
                                    }}
                                />
                            )}
                        </Box>
                        <Box
                            sx={{
                                m: 1,
                                position: 'relative',
                                float: 'right',
                                mt: 0
                            }}
                            style={{ float: 'right' }}
                        >
                            <Button
                                variant='contained'
                                sx={buttonSx}
                                disabled={(!success || loading) && !changes}
                                onClick={saveUniverseSync}
                            >
                                {updateMessage}
                            </Button>
                            {loading && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        color: green[500],
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px'
                                    }}
                                />
                            )}
                        </Box>
                    </Box>
                )}

                <Fragment>
                    {activeStep === 0 && (
                        <Demographics registerChanges={registerChanges}></Demographics>
                    )}
                    {activeStep === 1 && <Diagnosis registerChanges={registerChanges}></Diagnosis>}
                    {activeStep === 2 && (
                        <Procedures registerChanges={registerChanges}></Procedures>
                    )}
                    {activeStep === 3 && (
                        <Prescriptions registerChanges={registerChanges}></Prescriptions>
                    )}
                    {activeStep === 4 && (
                        <Specialist registerChanges={registerChanges}></Specialist>
                    )}
                    {activeStep === 5 && (
                        <UniverseCart
                            stepFunction={handleStep}
                            goTo={props.goTo}
                            generateUniverse={generateUniverse}
                            sendNotification={props.sendNotification}
                        ></UniverseCart>
                    )}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            pt: 2,
                            mt: 4
                        }}
                    >
                        {activeStep !== 0 && (
                            <Button
                                color='inherit'
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                                variant='contained'
                                startIcon={<NavigateBackIcon />}
                            >
                                {steps[activeStep - 1]}
                            </Button>
                        )}

                        <Box sx={{ flex: '1 1 auto' }} />
                        {activeStep !== 5 && (
                            <Button
                                onClick={handleNext}
                                variant='contained'
                                endIcon={<NavigateNextIcon />}
                            >
                                {activeStep === steps.length - 1 ? 'Finish' : steps[activeStep + 1]}
                            </Button>
                        )}
                    </Box>
                </Fragment>
            </Container>

            {/* Dialog for moving away of universe cart with custom query enabled confirmation */}
            <Dialog
                open={open}
                onClose={dialogHandleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle
                    id='alert-dialog-title'
                    sx={{ alignItems: 'center', display: 'flex', columnGap: 2 }}
                >
                    <WarningIcon color='primary' sx={{ fontSize: '25px' }} />
                    {'Custom Query Warning'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        Moving away from the Universe Cart when working with Custom Queries will
                        reset your query to whatever is configured in the other dimension tabs of
                        the Query Builder. Are you sure you want to navigate outside this section?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={dialogHandleClose}>Cancel</Button>
                    <Button onClick={() => handleActiveStepChange(onHoldStep, true)} color='error'>
                        Navigate to another tab
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}
