import { CHOOSE, GETCLIENT } from './clientActionTypes';

export const chooseClient = client => ({
    type: CHOOSE,
    payload: client
});

export const getClient = client => ({
    type: GETCLIENT,
    payload: client
});
