// App.JS while previously included all the wrapper component code will now become a login entry point for the application. App.JS will now include the code previously included in login and will ensure a redirect, once the log in process is done, to the Home component, which will now hold all the information from the componets.
// React
import { useEffect } from 'react';

// Utilities
import '@popperjs/core'; // Edit here
import TagManager from 'react-gtm-module';
import * as env from './utils/EnvironmentVariablesReader';
import LLCaller from './utils/LLCaller';

console.log(`env is ${JSON.stringify(env)}`)
// Implement the Tag Manager values
const tagManagerArgs = {
    gtmId: 'GTM-K5ZZHLP',
    //the below changes GTM values based on whether dev or production
    auth: env.TEST_MODE_FLAG ? '<dev ga_auth>' : '<live ga_auth>',
    preview: env.TEST_MODE_FLAG ? '<dev ga_env>' : '<live ga_env>'
};

if (!env.TEST_MODE_FLAG) {
    TagManager.initialize(tagManagerArgs);
}

export default function App() {
    // Function that will handle calling the LionLogin endpoint
    const loginWithLL = async () => {
        let response = await LLCaller.sendRequest('login', { location: 'home' });
        window.location.replace(response.data.redirectURL);
    };

    useEffect(() => {
        loginWithLL();
    }, []);

    return <></>;
}
