// React
import { useState, useEffect } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { createProject } from '../../redux/actions/project/projectActions';
import { chooseClient } from '../../redux/actions/client/clientActions';

// Utilities
import APICaller from '../../utils/APICaller';
import TextFormatter from '../../utils/TextFormatter';

// Materialize UI Includes
import { Box } from '@mui/system';
import {
    Typography,
    TextField,
    Button,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    Alert,
    Collapse,
    IconButton
} from '@mui/material';
// import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { Delete } from '@mui/icons-material';

export default function CreateProject(props) {
    const dispatch = useDispatch();

    // Component Hooks
    const [client_options, setClientOptions] = useState([]);
    const [client_id, setClientId] = useState(0);
    const [clients, setClients] = useState([]);
    const [client, setClient] = useState('');
    const [ready, setReady] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [keywords, setKeywords] = useState([]);
    const [err, setErr] = useState(false);
    const [message, setMessage] = useState('');
    const [saving, setSaving] = useState(false);

    // Redux Hooks
    const user = useSelector(state => state.user);

    useEffect(() => {
        // window.dataLayer.push({
        //     event: 'pageview',
        //     page: {
        //         url: '',
        //         title: 'Create New Project Page'
        //     }
        // });

        const fetchData = async () => {
            await loadClients();
        };

        fetchData();
    }, []);

    // Function to add keyword to the keywords array using the input
    const addKeyword = () => {
        let currentKeywords = [...keywords];
        let newKeyword = keyword;

        // Keywords should be one single word only. Therefore we replace any blank spaces with no spaces
        newKeyword = newKeyword.replace(/ /g, '');

        // Check if the keyword is a blank space. If it is, then we need to throw an error wih a custom message.
        if (newKeyword === '') {
            setErr(true);
            setMessage('The keyword can not be blank text. Please try with a new keyword.');
            return;
        }

        // Clear text from input
        setKeyword('');

        // Before we add the keyword to the array, we want to ensure that is not actually already included. For this, we before turn the keyword into lowercase (all keywords will be rendered as lowercase only) and then check
        newKeyword = newKeyword.toLowerCase();

        if (currentKeywords.find(cK => cK === newKeyword)) {
            setErr(true);
            setMessage(
                'The keyword has already been added to this project. Please try with a new keyword.'
            );
            return;
        }

        currentKeywords.push(newKeyword);
        setKeywords(currentKeywords);
    };

    // Function to handle clicking on the keyword that will delete it from the keywords array
    const handleDelete = keyword => {
        let currentKeywords = [...keywords];
        setKeywords(currentKeywords.filter(cK => cK !== keyword));
    };

    const cancelProjectCreation = e => {
        e.preventDefault();
        props.goTo('landing');
    };

    const createProjectOnclick = async e => {
        e.preventDefault();

        // Set up component hooks to display text on updates and status
        setSaving(true);
        props.setMessage({
            text: (
                <Typography>
                    Creating the {e.target.project_name.value} project. Please wait.
                </Typography>
            ),
            status: 'info'
        });
        props.setOpen(true);
        // here we can address the problem of text clean and formatting on projects
        try {
            let data = {
                project_metadata: {
                    project_name: await TextFormatter.formatText(e.target.project_name.value),
                    project_description: await TextFormatter.formatText(
                        e.target.project_desc.value
                    ),
                    project_keywords: await TextFormatter.formatText(keywords.join(', ')),
                    client_id: client_id,
                    user_id: await user.id
                }
            };

            let project = await APICaller.sendRequest('createproject', data);

            props.setMessage({
                text: (
                    <Typography>
                        The {e.target.project_name.value} project has been created successfully.
                    </Typography>
                ),
                status: 'success'
            });
            props.setOpen(true);
            setSaving(false);

            dispatch(createProject(project));
            dispatch(chooseClient(clients.find(x => x.id === client_id)));

            props.goTo(`project_overview/${project.id}`);
        } catch (error) {
            setSaving(false);
            props.setMessage({
                text: (
                    <Typography>
                        Error creating the {e.target.universe_name.value} universe, per{' '}
                        {err.message}
                    </Typography>
                ),
                status: 'error'
            });
            props.setOpen(true);
            return;
        }
    };

    const loadClients = async () => {
        let clients = await APICaller.sendRequest('listclients', {});
        let options = [];
        for (let client of clients) {
            let client_opt = { value: client.id, label: client.name };
            options.push(client_opt);
        }
        setClientOptions(options);
        setClients(clients);
    };

    const updateClient = event => {
        setClient(clients.find(x => x.id === event.target.value));
        dispatch(chooseClient(client));
        setClientId(event.target.value);
        updateStatus();
    };

    // We want to keep track of when the form should be considered as valid. In order to do so, whenever we trigger a change, we want to evaluate if all the other required elements are ready or not, to set the ready value as true.
    const updateStatus = () => {
        setReady(
            document.getElementById('project_name').value !== '' &&
                document.getElementById('project_desc').value !== ''
        );
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                pt: 3,
                pb: 3,
                height: '95%'
            }}
        >
            <Box
                sx={{
                    width: 3 / 4,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexFlow: 'column'
                }}
            >
                <Typography variant='h1' sx={{ textAlign: 'left', mb: 2, fontSize: '2.5rem' }}>
                    Create New Project
                </Typography>
                <form onSubmit={e => createProjectOnclick(e)} style={{ width: '100%' }}>
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
                            columnGap: 2
                        }}
                    >
                        <Box>
                            <TextField
                                required
                                sx={{ width: 1 }}
                                id='project_name'
                                label='Project Name'
                                variant='filled'
                                onChange={updateStatus}
                                inputProps={{
                                    'data-testid': 'project-textfield-name'
                                }}
                                disabled={saving}
                            />
                            <TextField
                                required
                                multiline
                                rows={5}
                                sx={{ width: 1, mt: 2 }}
                                id='project_desc'
                                label='Project Description'
                                variant='filled'
                                onChange={updateStatus}
                                inputProps={{
                                    'data-testid': 'project-textfield-description'
                                }}
                                disabled={saving}
                            />
                            <FormControl sx={{ width: 1, mt: 2 }} size='large'>
                                <InputLabel id='client-helper-label'>Client</InputLabel>
                                <Select
                                    required
                                    labelId='client-helper-label'
                                    id='client-select-select'
                                    label='Client'
                                    onChange={updateClient}
                                    defaultValue=''
                                    inputProps={{
                                        'data-testid': 'client-select-element'
                                    }}
                                    disabled={saving}
                                >
                                    {client_options.map((clientOption, index) => (
                                        <MenuItem key={index} value={clientOption.value}>
                                            {clientOption.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box>
                            <Box sx={{ display: 'flex', flexFlow: 'row', columnGap: 2 }}>
                                <TextField
                                    sx={{ width: 1 }}
                                    id='newKeyword'
                                    value={keyword}
                                    label='New Keyword'
                                    variant='filled'
                                    onChange={e => setKeyword(e.target.value)}
                                    inputProps={{
                                        'data-testid': 'project-textfield-keyword'
                                    }}
                                    disabled={saving}
                                />
                                <Button
                                    variant='outlined'
                                    onClick={addKeyword}
                                    disabled={saving}
                                    sx={{ borderRadius: 0 }}
                                >
                                    Add
                                </Button>
                            </Box>
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    color: 'black',
                                    mt: 1,
                                    mb: 1,
                                    width: 3 / 4
                                }}
                            >
                                Terms relevant to a project to help with grouping or searching
                                projects.
                            </Typography>
                            <Collapse in={err}>
                                <Alert
                                    severity='error'
                                    action={
                                        <IconButton
                                            aria-label='close'
                                            color='inherit'
                                            size='small'
                                            onClick={() => {
                                                setErr(false);
                                            }}
                                        >
                                            <CloseIcon fontSize='inherit' />
                                        </IconButton>
                                    }
                                    sx={{ mb: 1, mt: 1 }}
                                >
                                    {message}
                                </Alert>
                            </Collapse>
                            <Box sx={{ mt: 2 }}>
                                {keywords.map(k => (
                                    <Box
                                        key={k}
                                        sx={{
                                            display: 'inline-flex',
                                            padding: 0,
                                            alignItems: 'center',
                                            boxSizing: 'border-box',
                                            backgroundColor: 'transparent',
                                            border: '1px solid #bdbdbd',
                                            marginRight: 1,
                                            height: '32px',
                                            borderRadius: '16px',
                                            color: 'rgba(0, 0, 0, 0.87)',
                                            width: 'auto'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                paddingLeft: '12px',
                                                paddingRight: '12px',
                                                whiteSpace: 'nowrap',
                                                fontSize: '0.8125rem'
                                            }}
                                        >
                                            {k}
                                        </Typography>
                                        <IconButton
                                            aria-label={`Delete ${k} keyword`}
                                            color='primary'
                                            size='small'
                                            onClick={() => {
                                                handleDelete(k);
                                            }}
                                        >
                                            <Delete fontSize='inherit' />
                                        </IconButton>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            columnGap: 2,
                            width: '50%',
                            paddingRight: 1
                        }}
                    >
                        <Button
                            variant='contained'
                            size='large'
                            sx={{ mt: 2, width: 1 }}
                            type='submit'
                            disabled={!ready || saving}
                        >
                            Create Project
                        </Button>
                        <Button
                            variant='contained'
                            size='large'
                            sx={{ mt: 2, width: 1 }}
                            type='submit'
                            color='error'
                            onClick={e => cancelProjectCreation(e)}
                            disabled={saving}
                        >
                            Cancel
                        </Button>
                    </Box>
                </form>
            </Box>
        </Box>
    );
}
