class TextFormatter {

    formatText = async(text) => {
        let newText = text.replace('\'', '’');
        let formattedText = newText.replace(/[^a-zA-Z0-9’, ]/g, '');
        return formattedText;
    }

}

export default new TextFormatter();