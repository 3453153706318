// Utilities
import LLCaller from '../../utils/LLCaller';

// Components Includes
import Header from '../layout/Header';
import Footer from '../layout/Footer';

// CSS Styling
import '../../assets/styles/App.scss';

// MUI Includes
import { Container, Typography, Button, Box } from '@mui/material';

export default function Login(props) {
    // Function that will handle calling the LionLogin endpoint
    const loginWithLL = async () => {
        let response = await LLCaller.sendRequest('login', { location: 'home' });
        window.location.replace(response.data.redirectURL);
    };

    return (
        <Box sx={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
            <Header></Header>
            <Container
                maxWidth='xs'
                sx={{
                    textAlign: 'center',
                    height: 'calc(100% - 152px)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexFlow: 'column'
                }}
            >
                <Typography variant='h1' sx={{ textAlign: 'center', mb: 2, fontSize: '24px' }}>
                    Access with LionLogin
                </Typography>
                <Button
                    variant='outlined'
                    size='large'
                    sx={{ mt: 2, width: 1 / 2 }}
                    onClick={loginWithLL}
                >
                    Login
                </Button>
            </Container>
            <footer>
                <Footer />
            </footer>
        </Box>
    );
}
