// MUI Includes
import { Typography, Box } from '@mui/material';

// Logo Include
import logo from '../../assets/img/logos/Primary_Insagic-Logo-Tagline.png';

export default function ContactUs() {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                pt: 7,
                pb: 3
            }}
        >
            <Box
                sx={{
                    width: 1 / 2,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexFlow: 'column'
                }}
            >
                <img
                    src={logo}
                    alt='Insagic Platform Logo'
                    style={{ width: 'auto', height: '110px', marginBottom: '32px' }}
                ></img>

                <Typography variant='h1' sx={{ fontSize: '2.5rem', fontWeight: '400' }}>
                    Contact Us
                </Typography>

                <Typography variant='body1'>
                    <p>
                        To reach the Insagic team with a request for profiles, pitch assistance, or
                        other inquiry please send us an email at{' '}
                        <a
                            href='mailto:askinsagic@publicishealth.com'
                            target='_blank'
                            rel='noreferrer'
                        >
                            askinsagic@publicishealth.com
                        </a>
                        .
                    </p>
                    <p>
                        To request access to the Insagic Reporting Tool, or to request a change to
                        your existing permissions, please fill out a ticket on our Request
                        Management board{' '}
                        <a
                            href='https://issues.publicishealth.com/projects/ARM/issues/ARM-32?filter=allissues'
                            target='_blank'
                            rel='noreferrer'
                        >
                            here
                        </a>{' '}
                        .
                    </p>
                </Typography>
            </Box>
        </Box>
    );
}
