import { AUTH, LOGOUT, LOGINFAILED, UPDATEROLE } from './userActionTypes';

export const authentication = user => ({
    type: AUTH,
    payload: user
});

export const logout = user => ({
    type: LOGOUT,
    payload: user
});

export const loginfailed = user => ({
    type: LOGINFAILED,
    payload: user
});

export const updateRole = role => ({
    type: UPDATEROLE,
    payload: role
});
