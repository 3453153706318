import { ADDNOTIFICATION, UPDATENOTIFICATION } from './notificationsActionTypes';

export const addNotification = notification => ({
    type: ADDNOTIFICATION,
    payload: notification
});

export const updateNotification = notification => ({
    type: UPDATENOTIFICATION,
    payload: notification
});
