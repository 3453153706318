// MUI Includes
import { Typography, Box } from '@mui/material';

// Logo Include
import logo from '../../assets/img/logos/Primary_Insagic-Logo-Tagline.png';

export default function AboutInsagic() {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                pt: 7,
                pb: 3
            }}
        >
            <Box
                sx={{
                    width: 1 / 2,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexFlow: 'column'
                }}
            >
                <img
                    src={logo}
                    alt='Insagic Platform Logo'
                    style={{ width: 'auto', height: '110px', marginBottom: '32px' }}
                ></img>

                <Typography variant='h1' sx={{ fontSize: '2.5rem', fontWeight: '400' }}>
                    About Insagic
                </Typography>

                <Typography variant='body1'>
                    <p>
                        Insagic is a team comprised of curious, collaborative, and entrepreneurial
                        professionals including data scientists, engineers, methodologists,
                        architects, strategists, and even technology and product specialists. It is
                        an engine that works to develop products that transform marketing engagement
                        into healthcare interventions. The Insagic Team fuels new product
                        development, client collaboration pilots, and builds data and analytics
                        infrastructures to keep its data governed, compliant and prepared for
                        activation ready insights. We believe healthcare marketing is healthcare,
                        and our mission is to unleash data to create positive healthcare outcomes
                        for people and businesses.{' '}
                    </p>
                    <p>
                        Data is at the core of everything we do and everything we build, to bring
                        forth reality-based innovation and actionable recommendations. Our ecosystem
                        consists of frameworks, processes, and technology, like our open analytics
                        environment where we use our sourced, de-identified and compliant healthcare
                        data. This ecosystem allows us to create products that deliver higher
                        fidelity segmentation, activation, and optimization, and leverage real-world
                        lifestyle data to drive better healthcare outcomes.
                    </p>
                </Typography>
            </Box>
        </Box>
    );
}
