// React
import React, { useState, useEffect, Fragment } from 'react';
import { useSearchParams } from 'react-router-dom';

// Utilities
import LLCaller from '../../utils/LLCaller';
import jwt_decode from 'jwt-decode';

// Logo Include
import logo from '../../assets/img/logos/Primary_Insagic-Logo-Tagline.png';

// Icons Include
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

// CSS Styling
import '../../assets/styles/App.scss';

import Header from '../layout/Header';

// MUI Includes
import {
    Box,
    Container,
    Button,
    Typography,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Snackbar,
    Alert
} from '@mui/material';

// Includes for MUI styling
import Slide from '@mui/material/Slide';

export default function Login(props) {
    // Component Hooks
    const [transition] = useState(Slide);
    const [message, setMessage] = useState({
        text: '',
        status: 'info'
    });
    const [open, setOpen] = useState(false);
    const [success, setSuccess] = useState(false);
    const [authState, setAuthState] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams('');
    const [sending, setSending] = useState(false);
    const [done, setDone] = useState(false);
    const [requestor, setRequestor] = useState('');
    const [requestors] = useState([
        'Saatchi & Saatchi Wellness',
        'HeartBeat',
        'Langland',
        'Digitas Health',
        'Epsilon',
        'Publicis Health',
        'Publicis Health Media',
        'Razorfish Health',
        'BBK',
        'Payer Sciences',
        'Publicis Groupe',
        'Other'
    ]);
    const [request, setRequest] = useState({
        email: '',
        message: ''
    });

    // Function that will handle calling the LionLogin endpoint
    const loginWithLL = async () => {
        let response = await LLCaller.sendRequest('login', { location: 'ask' });
        window.location.replace(response.data.redirectURL);
    };

    useEffect(() => {
        // Handle retrieving the element from the URL and then clearing the state and value
        let token = searchParams.get('token');
        let idToken = searchParams.get('idToken');

        // If we are using the user props, passed by a testing component or any other option, we want to simply use this user object and call the retrieveUser function.
        if (idToken && token) {
            // Once we have the tokens, we want to check if they are still valid. If they are not, we clear them so we have to call the LL SSO process again.
            if (
                new Date().getTime() < jwt_decode(idToken).exp ||
                new Date().getTime() < jwt_decode(token).exp
            ) {
                loginWithLL();
                return;
            }

            // 👇️ delete each query param
            searchParams.delete('token');
            searchParams.delete('idToken');

            // Update the auth state hook to true
            setAuthState(true);

            // 👇️ update state after
            setSearchParams(searchParams);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Handle close function for the alert
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    // Function to handle sending emails
    const sendEmailRequest = () => {
        window.location.href = 'mailto:askinsagic@publicishealth.com';
    };

    const updateRequestor = e => {
        setRequestor(requestors.find(x => x === e.target.value));
    };

    const sendRequest = async e => {
        e.preventDefault();
        setSending(true);

        // Before we go through the form process, save the object of the request into the State so we can restore it if it fails
        setRequest({
            email: e.target.email_address.value,
            requestor,
            message: e.target.request.value
        });

        // // Add message of message being processed
        setOpen(true);
        setMessage({
            text: 'The application is sending your request',
            status: 'info'
        });

        let response = await LLCaller.sendRequest('sendEmail', {
            email: e.target.email_address.value,
            requestor,
            message: e.target.request.value
        });

        // Once we get the response, there are two options of the response. Either we get Email sent successfully or anything else, which we know is an error message. Depending on the response value, we display another alert of either success or error
        if (response.data.message === 'Email sent successfully') {
            setOpen(true);
            setMessage({
                text: 'Your request has been sent successfully!',
                status: 'success'
            });
            setSuccess(true);
        } else {
            setOpen(true);
            // If there's an error, we want to know if there's a message on the response object to use or not
            let errorMessage = response.data.message || response;
            setMessage({
                text: `An error occurred while sending your request. Error: ${errorMessage}`,
                status: 'error'
            });
            setSuccess(false);
        }

        // Once we are here, we set Done to true, so we can update the view of the form.
        setSending(false);
        setDone(true);
    };

    // Function to handle resetting the form to its previous state once the button to retry is clicked
    const resetForm = () => {
        // Before we clear the values, we want to know the state value of the success. If the request was indeed a success, we want to clear the values of the form for a new request to be created, to avoid users creating repeated requests by accident.
        if (success) {
            setRequest({
                email: '',
                message: ''
            });
            setRequestor('');
        }

        setDone(false);
        setSuccess(true);
        setSending(false);
    };

    return (
        <Box sx={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
            <Header></Header>

            {authState ? (
                <Container
                    maxWidth='xl'
                    sx={{
                        textAlign: 'center',
                        height: 'calc(100% - 64px)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexFlow: 'column',
                        mt: 4,
                        pb: 4
                    }}
                >
                    <img
                        src={logo}
                        alt='Insagic Platform Logo'
                        style={{ width: '30%', height: 'auto', marginBottom: '32px' }}
                    ></img>

                    {!done ? (
                        <Fragment>
                            <Typography
                                variant='h1'
                                sx={{ textAlign: 'center', mb: 4, fontSize: '20px' }}
                            >
                                The new mission of healthcare marketing is rooted in patient
                                outcomes.
                            </Typography>
                            <Typography
                                variant='h2'
                                sx={{ textAlign: 'center', mb: 4, fontSize: '28px' }}
                            >
                                Are you ready for it?
                            </Typography>
                            <Typography variant='body1'>
                                We appreciate your interest and we look forward to receiving your
                                request.
                            </Typography>

                            <form onSubmit={e => sendRequest(e)} style={{ width: '100%' }}>
                                <Container maxWidth='md' sx={{ mt: 3 }}>
                                    <TextField
                                        required
                                        sx={{ width: 1, mb: 2 }}
                                        id='email_address'
                                        label='Email Address'
                                        variant='filled'
                                        type='email'
                                        inputProps={{
                                            'data-testid': 'ask-emailAddress'
                                        }}
                                        defaultValue={request.email ? request.email : ''}
                                        disabled={sending}
                                    />
                                    <FormControl sx={{ width: 1, mt: 1, mb: 2 }} size='large'>
                                        <InputLabel id='requestor-helper-label'>
                                            Requestor
                                        </InputLabel>
                                        <Select
                                            required
                                            labelId='requestor-helper-label'
                                            id='requestor-select-select'
                                            label='Requestor'
                                            onChange={updateRequestor}
                                            defaultValue=''
                                            value={requestor}
                                            inputProps={{
                                                'data-testid': 'requestor-select-element'
                                            }}
                                            disabled={sending}
                                            sx={{ textAlign: 'left' }}
                                        >
                                            {requestors.map((requestorOption, index) => (
                                                <MenuItem key={index} value={requestorOption}>
                                                    {requestorOption}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        required
                                        multiline
                                        rows={5}
                                        sx={{ width: 1, mt: 1 }}
                                        id='request'
                                        label='Please describe your request in as much detail as possible'
                                        variant='filled'
                                        inputProps={{
                                            'data-testid': 'ask-request'
                                        }}
                                        disabled={sending}
                                        defaultValue={request.message ? request.message : ''}
                                    />
                                    <Button
                                        variant='contained'
                                        size='large'
                                        sx={{ mt: 2, width: 1 / 2, float: 'left' }}
                                        type='submit'
                                        disabled={sending}
                                    >
                                        Send Request
                                    </Button>
                                </Container>
                            </form>
                        </Fragment>
                    ) : (
                        <Fragment>
                            {success ? (
                                <Container sx={{ marginTop: 4 }}>
                                    {' '}
                                    <CheckCircleOutlineIcon
                                        fontSize='large'
                                        color='primary'
                                        sx={{ fontSize: '5rem' }}
                                    />
                                    <h3>
                                        Thanks for your interest. Your inquiry has been sent to our
                                        team and we will be contacting you shortly.
                                    </h3>
                                    <Button
                                        variant='contained'
                                        size='large'
                                        sx={{ mt: 2, width: 1 / 4 }}
                                        disabled={sending}
                                        onClick={resetForm}
                                    >
                                        Send new request.
                                    </Button>
                                </Container>
                            ) : (
                                <Container sx={{ marginTop: 4 }}>
                                    {' '}
                                    <ErrorOutlineIcon
                                        fontSize='5rem'
                                        sx={{ color: 'red', fontSize: '5rem' }}
                                    />
                                    <h3>
                                        There was an issue collecting you request. Please try again
                                        shortly.
                                    </h3>
                                    <Button
                                        variant='contained'
                                        size='large'
                                        sx={{ mt: 2, width: 1 / 4 }}
                                        disabled={sending}
                                        onClick={resetForm}
                                    >
                                        Retry request.
                                    </Button>
                                </Container>
                            )}
                        </Fragment>
                    )}
                </Container>
            ) : (
                <Container
                    maxWidth='xl'
                    sx={{
                        textAlign: 'center',
                        height: 'calc(100% - 64px)',
                        width: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexFlow: 'column'
                    }}
                >
                    <img
                        src={logo}
                        alt='Insagic Platform Logo'
                        style={{ width: '50%', height: 'auto', marginBottom: '32px' }}
                    ></img>
                    <Typography variant='h1' sx={{ textAlign: 'center', mb: 4, fontSize: '20px' }}>
                        The new mission of healthcare marketing is rooted in patient outcomes.
                    </Typography>
                    <Typography variant='h2' sx={{ textAlign: 'center', mb: 2, fontSize: '28px' }}>
                        Are you ready for it?
                    </Typography>

                    <Grid
                        sx={{
                            pt: 1,
                            pb: 1,
                            mb: 3,
                            display: 'grid',
                            gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
                            columnGap: 4,
                            rowGap: 2,
                            alignItems: 'start',
                            justifyItems: 'start',
                            width: 1
                        }}
                    >
                        <Container>
                            <h3>Have a LionLogin Account?</h3>
                            <Button
                                variant='contained'
                                size='large'
                                sx={{ mt: 1, width: 3 / 4 }}
                                onClick={loginWithLL}
                            >
                                Access Service Desk
                            </Button>
                        </Container>
                        <Container>
                            <h3>Need to request acccess?</h3>
                            <Button
                                variant='contained'
                                size='large'
                                sx={{ mt: 1, width: 3 / 4 }}
                                onClick={sendEmailRequest}
                            >
                                Send request
                            </Button>
                        </Container>
                    </Grid>
                </Container>
            )}
            <Snackbar
                open={open}
                autoHideDuration={4000}
                onClose={handleClose}
                TransitionComponent={transition}
            >
                <Alert onClose={handleClose} severity={message.status} sx={{ width: '100%' }}>
                    {message.text}
                </Alert>
            </Snackbar>
        </Box>
    );
}
