// React
import { useState, useEffect } from 'react';

// Redux
import { updateRole } from '../../redux/actions/user/userActions';
import { useSelector, useDispatch } from 'react-redux';

// Including for MUI for AppBar
import {
    Avatar,
    Menu,
    MenuItem,
    Divider,
    Box,
    Toolbar,
    Typography,
    IconButton,
    Tooltip,
    Button,
    AppBar,
    ListItemIcon,
    Grid,
    Badge
} from '@mui/material';

// Icons Includes
import PeopleIcon from '@mui/icons-material/People';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import NotificationsIcon from '@mui/icons-material/Notifications';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// Dialog Includes
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Table Includes
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// Utilities
import logo from '../../assets/img/logos/White_Insagic-Logo.png';
import LLCaller from './../../utils/LLCaller';

export default function Header(props) {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const notifications = useSelector(state => state.notifications);

    const [anchorElUser, setAnchorElUser] = useState(null);
    const [anchorElNotifications, setAnchorElNotifications] = useState(null);
    const [openHelp, setOpenHelp] = useState(false);
    const [roleLabel, setRoleLabel] = useState('');
    const [rows, setRows] = useState([]);

    useEffect(() => {
        // Whenever the component loads, we want to set up the user role as a label to use in the view, as we are saving this as an ID to make it consistent to how the DB and data is provided. We only do this if the user role object already exist.
        if (user && user.role) {
            switch (user.role.id) {
                case 1:
                    setRoleLabel('Super User');
                    break;
                case 2:
                    setRoleLabel('Power User');
                    break;
                case 3:
                    setRoleLabel('Standard User');
                    break;
                case 4:
                    setRoleLabel('Guest User');
                    break;
                case 5:
                    setRoleLabel('Technical Admin User');
                    break;
                default:
                    break;
            }

            // While the role object has each of its object values as keys of the object, we want to turn them into an array for use on the table below for permissions UI
            const tableRows = [];
            ['project', 'query', 'universe', 'user'].forEach(object => {
                if (user.role[object]) {
                    tableRows.push({
                        name: object
                    });
                    ['create', 'delete', 'update', 'view'].forEach(type => {
                        tableRows.find(r => r.name === object)[type] =
                            user.role[object][type] || 'no';
                    });
                }
            });

            setRows(tableRows);
        }
    }, [user, notifications]);

    // We proceed to call the endpoint again to retrieve the roles and update the user information
    const refreshRole = async () => {
        dispatch(updateRole(await props.retrieveRoleDetails(user.id)));
    };

    // const processLogOut = async () => {
    //     let response = await LLCaller.sendRequest('logout', {});
    //     window.location.replace(response.data.redirectURL);
    // };

    const openAdmin = () => {
        props.goTo('user_admin');
    };

    // Adding setting options that should be visible from the menu. Logout is available to all, while there are options, such as User Admin, that are visible only for Super Users.
    const settings = [
        // {
        //     name: 'Logout',
        //     action: processLogOut
        // }
    ];

    if (user && user.role.user.view) {
        settings.unshift({
            name: 'User Admin',
            action: openAdmin
        });
    }

    // Because we store the role as ID to make it cleaner, we need to determine what the label of the role is to use on the view below

    const handleOpenUserMenu = event => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseSettingsMenu = () => {
        setAnchorElUser(null);
    };

    const handleOpenNotificationsMenu = event => {
        setAnchorElNotifications(event.currentTarget);
    };

    const handleCloseNotificationsMenu = () => {
        setAnchorElNotifications(null);
        if (notifications.length > 0) {
            notifications.map(n => {
                return (n.isNew = false);
            });
        }
    };

    const handleHelpClose = () => {
        setOpenHelp(false);
    };

    // When we display the information of created or completed, we want to provide a relative time to the user based on the date on the item and the current date. Because these dates are not stored in the DB but are rather local, we can simply rely on the newDate without worry about the timezone, as this will always match.
    const calculateDateText = (oldDate, newDate = null) => {
        let isPlural = false;
        let timeText;
        let comparedDate = newDate ? newDate : new Date();

        let seconds = Math.floor((comparedDate - oldDate) / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);

        hours = hours - days * 24;
        minutes = minutes - days * 24 * 60 - hours * 60;
        seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

        if (days > 0) {
            if (days > 1) isPlural = true;
            timeText = `${days} day`;
        } else if (hours > 0) {
            if (hours > 1) isPlural = true;
            timeText = `${hours} hour`;
        } else if (minutes > 0) {
            if (minutes > 1) isPlural = true;
            timeText = `${minutes} minute`;
        } else {
            if (seconds > 1) isPlural = true;
            timeText = `${seconds} second`;
        }

        return `${timeText}${isPlural ? 's' : ''}`;
    };

    const openLanding = () => {
        props.goTo('landing');
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position='relative' sx={{ height: '65px', backgroundColor: '#0c1742', zIndex: 'appBar' }}>
                <Toolbar>
                    <div style={{ flexGrow: 1, mb: 0 }}>
                        <Button
                            variant='text'
                            sx={{ flexGrow: 1, mb: 0, color: 'white', pl: 0, fontSize: '1rem' }}
                            onClick={openLanding}
                            aria-label='Back to Home'
                        >
                            <img
                                src={logo}
                                alt='Insagic Platform Logo'
                                style={{ width: 'auto', height: '20px' }}
                            ></img>
                        </Button>
                    </div>
                    {user && user.authenticated && (
                        <Box sx={{ flexGrow: 0 }}>
                            {/* <span style={{ fontSize: '14px', textTransform: 'capitalize' }}>
                                {roleLabel}
                            </span> */}
                            <Tooltip title='Open Notifications'>
                                <IconButton
                                    onClick={handleOpenNotificationsMenu}
                                    size='large'
                                    aria-label={`show ${
                                        notifications.find(n => n.isNew)
                                            ? notifications.filter(n => n.isNew).length
                                            : 0
                                    } new notifications`}
                                    color='inherit'
                                >
                                    <Badge
                                        badgeContent={
                                            notifications.find(n => n.isNew)
                                                ? notifications.filter(n => n.isNew).length
                                                : 0
                                        }
                                        color='error'
                                    >
                                        <NotificationsIcon />
                                    </Badge>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title='Open settings'>
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, ml: 1 }}>
                                    <Avatar
                                        alt={user.firstName}
                                        src='/static/images/avatar/2.jpg'
                                        style={{ color: '#0c1742' }}
                                    >
                                        {user.firstName.substring(0, 1)}
                                        {user.lastName.substring(0, 1)}
                                    </Avatar>
                                </IconButton>
                            </Tooltip>

                            {/* Menu for Open Settings */}
                            <Menu
                                sx={{ width: 1 }}
                                id='menu-appbar'
                                anchorEl={anchorElUser}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseSettingsMenu}
                                onClick={handleCloseSettingsMenu}
                                MenuListProps={{
                                    'aria-labelledby': 'Open Settings button'
                                }}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1
                                        },
                                        '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0
                                        }
                                    }
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        columnGap: 1,
                                        minWidth: '200px',
                                        pl: 2
                                    }}
                                >
                                    <Avatar
                                        alt={user.firstName}
                                        src='/static/images/avatar/2.jpg'
                                        sx={{ mb: 1, ml: 2, width: 56, height: 56 }}
                                    >
                                        {user.firstName.substring(0, 1)}
                                        {user.lastName.substring(0, 1)}
                                    </Avatar>
                                    <Box>
                                        <Typography variant='body' sx={{ mt: 1 }}>
                                            <strong>
                                                {user.firstName} {user.lastName}
                                            </strong>
                                        </Typography>
                                        <p
                                            style={{
                                                fontSize: '14px',
                                                textTransform: 'capitalize',
                                                marginTop: '8px'
                                            }}
                                        >
                                            {roleLabel}
                                        </p>
                                    </Box>
                                </Grid>
                                <Divider sx={{ mb: 1 }} />
                                <MenuItem
                                    onClick={() => {
                                        setOpenHelp(true);
                                    }}
                                >
                                    <ListItemIcon>
                                        <AccountBoxIcon fontSize='small' />
                                    </ListItemIcon>
                                    My Role
                                </MenuItem>
                                {user && user.role.user.view && (
                                    <MenuItem onClick={openAdmin}>
                                        <ListItemIcon>
                                            <PeopleIcon fontSize='small' />
                                        </ListItemIcon>
                                        User Admin
                                    </MenuItem>
                                )}
                                <MenuItem
                                    onClick={async () => {
                                        let response = await LLCaller.sendRequest('logout', {});
                                        window.location.replace(response.data.redirectURL);
                                    }}
                                >
                                    <ListItemIcon>
                                        <LogoutIcon fontSize='small' />
                                    </ListItemIcon>
                                    Logout
                                </MenuItem>
                            </Menu>

                            {/* Menu for Notifications */}
                            <Menu
                                sx={{ width: 1 }}
                                id='menu-appbar'
                                anchorEl={anchorElNotifications}
                                open={Boolean(anchorElNotifications)}
                                onClose={handleCloseNotificationsMenu}
                                onClick={handleCloseNotificationsMenu}
                                MenuListProps={{
                                    'aria-labelledby': 'Open Settings button'
                                }}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1
                                        },
                                        '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0
                                        }
                                    }
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                {notifications.length > 0 ? (
                                    notifications.map((notification, i) => (
                                        <MenuItem key={i} sx={{ p: 1 }}>
                                            <Box
                                                textAlign='left'
                                                sx={{ display: 'flex', alignItems: 'center' }}
                                            >
                                                {notification.completed !== 'NA' ? (
                                                    <span style={{ marginLeft: '8px' }}>
                                                        <span
                                                            style={{ textTransform: 'capitalize' }}
                                                        >
                                                            {notification.type}
                                                        </span>{' '}
                                                        for <strong>{notification.name}</strong>{' '}
                                                        completed{' '}
                                                        <strong>
                                                            {calculateDateText(
                                                                notification.completed
                                                            )}
                                                        </strong>{' '}
                                                        ago after{' '}
                                                        <strong>
                                                            {calculateDateText(
                                                                notification.started,
                                                                notification.completed
                                                            )}
                                                        </strong>
                                                        .
                                                    </span>
                                                ) : (
                                                    <span style={{ marginLeft: '8px' }}>
                                                        <span
                                                            style={{ textTransform: 'capitalize' }}
                                                        >
                                                            {notification.type}
                                                        </span>{' '}
                                                        for <strong>{notification.name}</strong>{' '}
                                                        started{' '}
                                                        <strong>
                                                            {calculateDateText(
                                                                notification.started
                                                            )}
                                                        </strong>{' '}
                                                        ago.
                                                    </span>
                                                )}
                                                {notification.isNew && (
                                                    <FiberManualRecordIcon
                                                        fontSize='10px'
                                                        color='primary'
                                                        sx={{ ml: 1 }}
                                                    />
                                                )}
                                            </Box>
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem>There are no available notifications.</MenuItem>
                                )}
                            </Menu>
                        </Box>
                    )}
                </Toolbar>
            </AppBar>
            <Dialog
                open={openHelp}
                onClose={handleHelpClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>{'Role Information'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        <p>
                            You current have the
                            <span style={{ textTransform: 'uppercase' }}>
                                <strong> {roleLabel}</strong>
                            </span>{' '}
                            role assigned to your user.
                        </p>
                        <p>Your role currently has the following permissions enabled:</p>
                        <Table
                            sx={{ w: 1, textTransform: 'capitalize' }}
                            aria-label='caption table'
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Type</TableCell>
                                    <TableCell align='right'>Can View</TableCell>
                                    <TableCell align='right'>Can Create</TableCell>
                                    <TableCell align='right'>Can Update</TableCell>
                                    <TableCell align='right'>Can Delete</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map(row => (
                                    <TableRow key={row.name}>
                                        <TableCell component='th' scope='row'>
                                            {row.name}
                                        </TableCell>
                                        <TableCell align='right'>{row.view}</TableCell>
                                        <TableCell align='right'>{row.create}</TableCell>
                                        <TableCell align='right'>{row.update}</TableCell>
                                        <TableCell align='right'>{row.delete}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <p>
                            If you wish to have your role updated, please open a ticket{' '}
                            <a
                                href='https://issues.publicishealth.com/projects/ARM/issues/ARM-28?filter=allissues'
                                target='_blank'
                                rel='noreferrer'
                            >
                                here
                            </a>
                            .
                        </p>
                        <p>
                            If your role has been updated recently, and you don't see your updated
                            role yet, please click the button below to refresh your information.
                        </p>
                        <Button onClick={refreshRole} variant='contained'>
                            Refresh Role
                        </Button>{' '}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleHelpClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
