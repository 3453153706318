// Redux Includes
import { useSelector } from 'react-redux';

// MUI Includes
import { Box, Grid, Container, Typography, Tab, Skeleton, Button } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';

// MUI Icons includes
import CircleIcon from '@mui/icons-material/Circle';

export default function ProjectOverViewHeader(props) {
    // Redux Hooks
    const client = useSelector(state => state.client);
    const project = useSelector(state => state.project);
    const universe = useSelector(state => state.universe);

    const handleChange = (event, newValue) => {
        // Once we detect a change on the tab navigation, we want to redirect to the right component, either the Project Overview or the Universe Summary, depending on the current step. We always want to open the component with the current object being evaluated. So we first check which value is the new navigation and then redirect to the component passing the ID as param to be used as prop id.
        switch (newValue) {
            case '1':
                props.goTo(`project_overview/${project.current.id}`);
                break;
            case '2':
                props.goTo(`universe_overview/${universe.current.id}`);
                break;
            default:
                break;
        }
    };

    return (
        <Grid
            sx={{
                backgroundColor: '#efefef',
                pt: 1,
                pb: 1,
                display: 'grid',
                gridTemplateColumns: '1fr 2fr',
                columnGap: 2,
                alignItems: 'center'
            }}
        >
            <Container>
                {/* <Button
                    color='inherit'
                    onClick={handleBack}
                    size='large'
                    sx={{ mr: 1, mt: '', mb: '', ml: '', pl: 0 }}
                    variant='filled'
                    startIcon={<NavigateBackIcon />}
                >
                    Go Back
                </Button> */}
                <Typography variant='h5'>
                    {!project.current.projectName ? (
                        <Skeleton sx={{ backgroundColor: 'white', borderRadius: '20px' }} />
                    ) : (
                        project.current.projectName
                    )}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>
                        {!client.name ? (
                            <Skeleton sx={{ backgroundColor: 'white', borderRadius: '20px' }} />
                        ) : (
                            <span>{client.name}</span>
                        )}
                    </Typography>
                    {/* {client.name && (
                        <Button
                            sx={{
                                float: 'left',
                                textDecoration: 'underline',
                                borderRadius: 0,
                                textUnderlineOffset: '5px',
                                ml: 0,
                                pl: 0
                            }}
                            variant='text'
                        >
                            Change
                        </Button>
                    )} */}
                </Box>
            </Container>
            <Container sx={{ width: 1, float: 'right' }} className='project-tabs'>
                <TabContext value={project.step}>
                    <Box sx={{ borderColor: 'divider' }}>
                        <TabList
                            onChange={handleChange}
                            aria-label='Project Tab Navigation'
                            variant='fullWidth'
                        >
                            <Tab
                                label='Project Overview'
                                value='1'
                                icon={<CircleIcon />}
                                iconPosition='bottom'
                                sx={{ fontSize: '1rem' }}
                            />
                            <Tab
                                label='Universe Overview'
                                value='2'
                                icon={<CircleIcon />}
                                iconPosition='bottom'
                                disabled={project.step < 2}
                                sx={{ fontSize: '1rem' }}
                            />
                            <Tab
                                label='Cluster Comparison'
                                value='3'
                                icon={<CircleIcon />}
                                iconPosition='bottom'
                                disabled={project.step < 3}
                                sx={{ fontSize: '1rem' }}
                            />
                        </TabList>
                    </Box>
                </TabContext>
            </Container>
        </Grid>
    );
}
