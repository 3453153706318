// React
import { Fragment } from 'react';

// MUI Includes
import { Button } from '@mui/material';

// Logo Include
import logo from '../../assets/img/logos/White_Insagic-Logo-Tagline.png';

export default function Footer(props) {
    // Function to handle opening the various ancillary pages
    const openAncillaryPage = (e, page) => {
        e.preventDefault();
        props.goTo(page);
    };

    return (
        <Fragment>
            <div className='column'>
                <div>
                    <img
                        src={logo}
                        alt='Insagic Platform Logo'
                        style={{ width: 'auto', height: '40px' }}
                    ></img>
                </div>
                <div>
                    <span className='footer-text'>
                        &copy; {`${new Date().getFullYear()} Publicis Groupe`}
                    </span>
                </div>
            </div>
            <div className='column footer-links'>
                <Button
                    onClick={e => {
                        openAncillaryPage(e, 'aboutinsagic');
                    }}
                    variant='text'
                    sx={{ p: 0, m: 0, fontSize: '1rem', color: 'white' }}
                >
                    About Insagic
                </Button>
                <Button
                    onClick={e => {
                        openAncillaryPage(e, 'segmentation');
                    }}
                    variant='text'
                    sx={{ p: 0, m: 0, fontSize: '1rem', color: 'white' }}
                >
                    Profiling
                </Button>
                <Button
                    onClick={e => {
                        openAncillaryPage(e, 'contactus');
                    }}
                    variant='text'
                    sx={{ p: 0, m: 0, fontSize: '1rem', color: 'white' }}
                >
                    Contact Us
                </Button>
            </div>
            {/* <div className='column footer-links'>
                <Button
                    onClick={e => {
                        // openAncillaryPage(e, 'contactus');
                    }}
                    variant='text'
                    sx={{ p: 0, m: 0, fontSize: '1rem' }}
                >
                    Privacy Statement
                </Button>
                <Button
                    onClick={e => {
                        // openAncillaryPage(e, 'contactus');
                    }}
                    variant='text'
                    sx={{ p: 0, m: 0, fontSize: '1rem' }}
                >
                    Terms of Use
                </Button>
                <Button
                    onClick={e => {
                        // openAncillaryPage(e, 'contactus');
                    }}
                    variant='text'
                    sx={{ p: 0, m: 0, fontSize: '1rem' }}
                >
                    Data Collection
                </Button>
            </div> */}
        </Fragment>
    );
}
