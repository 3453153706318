// React
import { useState, useEffect, Fragment } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { createUniverse, updateUniverseList } from '../../redux/actions/universe/universeActions';
import { updateProject } from '../../redux/actions/project/projectActions';
import { getClient } from '../../redux/actions/client/clientActions';
import { getProject, updateProjectStep } from '../../redux/actions/project/projectActions';
import {
    updateClusterList,
    clearModelSelection,
    clearColumns,
    clearSeries
} from '../../redux/actions/cluster/clusterActions';

// Utilities
import APICaller from '../../utils/APICaller';

// Components
import ProjectHeader from '../layout/ProjectHeader';

// Materialize Includes
import { Button, Grid, Container, Box, Typography, Skeleton, TextField, IconButton } from '@mui/material';

// Import Icons
import AddIcon from '@mui/icons-material/Add';

// Data Grid Includes
import { alpha, styled } from '@mui/material/styles';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import TextFormatter from '../../utils/TextFormatter';
const ODD_OPACITY = 0.2;

// Creating custom data Grid component that will render a styling similar to the wireframe, with stripped rows.
const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: theme.palette.grey[200],
        '&:hover, &.Mui-hovered': {
            backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
            '@media (hover: none)': {
                backgroundColor: 'transparent'
            }
        }
    }
}));

export default function ProjectOverView(props) {
    // Component Hooks
    const [loading, setLoading] = useState(false);
    const [universesLoading, setUniversesLoading] = useState( false);
    const [updating, setUpdating] = useState(false);
    const [editProject, setEditProject] = useState(false);
    const [create, setCreate] = useState(false);
    const [columns, setColumns] = useState([]);
    const [projectEditable, setProjectEditable] = useState(false);

    // Redux Hooks
    const dispatch = useDispatch();
    const universes = useSelector(state => state.universe.universeList);
    const project = useSelector(state => state.project.current);
    const optionList = useSelector(state => state.query.optionList);
    const user = useSelector(state => state.user);

    useEffect(() => {
        let mounted = true;

        // Set up the data required to retrieve project using the props ID value passed when calling the component
        let data = {
            project_id: project && project.id ? project.id : props.id
        };

        // Clear all the redux values that we might have stored from a previous cluster comparison, to avoid issues
        dispatch(clearColumns());
        dispatch(clearSeries());
        dispatch(clearModelSelection());

        const fetchUniverses = async () => {
            // Retrieve the list of universes
            setUniversesLoading(true);
            dispatch(updateUniverseList(await APICaller.sendRequest('listprojectuniverses', data)));
            setUniversesLoading(false);
        };

        const fetchData = async () => {
            setLoading(true);

            if (!universes || universes.length === 0) {
                fetchUniverses();
            }
            // Retrieve both the project and client info and update the redux state, so we can retrieve this name on other components, such as the header. We only do this whenever there is no project set up as current.
            let project = await APICaller.sendRequest('getprojectbyid', data);
            let client_data = {
                client_id: project.clientId
            };
            let client = await APICaller.sendRequest('getclientbyid', client_data);

            // Dispatch of the updates on the objects
            dispatch(getClient(client));
            dispatch(getProject(project));

            setLoading(false);
        };

        // Function that indicates if the user is allowed to edit a project. This will take into consideration two
        // options. Either the user has the ability to edit all or the user is the owner of the project.
        if (!user.role.project.update) {
            setEditProject(false);
        } else {
            if (user.role.project.update === 'all') {
                setEditProject(true);
            } else {
                setEditProject(project.creationUserName === user.id);
            }
        }

        // Function that sets a value of true/false to indicate if user can create a new project
        if (!user.role.universe.create) {
            setCreate(false);
        } else {
            if (user.role.universe.create === 'all') {
                setCreate(true);
            } else {
                setCreate(project.creationUserName === user.id);
            }
        }

        //Create the columns required for the Universes list below.
        setColumns([
            {
                field: 'universeName',
                headerName: 'Universe',
                hideable: false,
                sortable: true,
                flex: 1,
                minWidth: 200,
                renderCell: universe => (
                    <Button
                        sx={{
                            pl: 0,
                            textAlign: 'left',
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            fontSize: '1rem'
                        }}
                        onClick={e => openUniverse(e)}
                        value={universe.row.id}
                    >
                        {universe.row.universeName}
                    </Button>
                )
            },
            {
                field: 'universePatientCount',
                headerName: 'Universe Size (US Adults)',
                flex: 1,
                renderCell: universe => {
                    if (universe.row.universePatientCount) {
                        return universe.row.universePatientCount.toLocaleString();
                    } else {
                        return 'TBD';
                    }
                }
            },
            {
                field: 'universePatientProportion',
                headerName: '% of All Patients',
                flex: 1,
                renderCell: universe => {
                    if (universe.row.universePatientProportion) {
                        return `${parseFloat(universe.row.universePatientProportion * 100).toFixed(
                            2
                        )}%`;
                    } else {
                        return 'TBD';
                    }
                }
            }
        ]);

        // Update the step on the project header to step 1
        dispatch(updateProjectStep('1'));

        // We only fetchData is there is no current project in the state
        if (!project.projectName && mounted) {
            fetchData();
        } else {
            if (!universes || universes.length === 0) {
                fetchUniverses();
            }
        }

        // Clear the cluster list and cluster group list to avoid universes showing a previous list
        // dispatch(updateClusterGroupList([]));
        dispatch(updateClusterList([]));

        return () => (mounted = false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [optionList, user, project]);

    const createUniverseOnclick = e => {
        dispatch(createUniverse({}));
        e.preventDefault();
        props.goTo('universe');
    };

    // Function to handle opening an universe overview page, where we only pass the value of the event so we can pass this as props to the overview component.
    const openUniverse = event => {
        props.goTo(`universe_overview/${parseInt(event.target.value)}`);
    };

    const updateProjectInfo = async e => {
        e.preventDefault();
        setUpdating(true);
        setProjectEditable(false);

        dispatch(
            updateProject({
                ...project,
                projectName: await TextFormatter.formatText(e.target.projectName.value),
                projectDescription: await TextFormatter.formatText(
                    e.target.projectDescription.value
                )
            })
        );

        let update_project_metadata = {
            project_id: project.id,
            project_name: await TextFormatter.formatText(e.target.projectName.value),
            project_description: await TextFormatter.formatText(
                e.target.projectDescription.value
            ),
            project_keywords: project.projectKeywords,
            client_id: project.clientId,
            user_id: user.id
        };
        try {
            await APICaller.sendRequest('updateproject', { update_project_metadata });
        } catch (error) {
            console.error(error);
        }
        setUpdating(false);
    };

    return (
        <Fragment>
            <ProjectHeader goTo={props.goTo} />
            <Box>
                <Grid
                    sx={{
                        backgroundColor: '#0c1742',
                        pt: 1,
                        pb: 1,
                        display: 'grid',
                        gridTemplateColumns: '2fr 1fr',
                        columnGap: 2,
                        alignItems: 'center',
                        color: 'white'
                    }}
                >
                    <Container sx={{ pt: 2, pb: 2 }} maxWidth='false'>
                        {projectEditable ? (
                            <form
                                onSubmit={e => updateProjectInfo(e)}
                                style={{ width: '100%' }}
                            >
                                <TextField
                                    sx={{ mb: 2, mt: 2, width: 3 / 4 }}
                                    required
                                    margin='dense'
                                    id='projectName'
                                    label='Project Name'
                                    type='text'
                                    defaultValue={ project.projectName ? project.projectName : '' }
                                    color='whitePalette'
                                    focused
                                />
                                <TextField
                                    required
                                    multiline
                                    rows={5}
                                    sx={{ width: 1, mt: 2, mb: 2 }}
                                    id='projectDescription'
                                    label='Project Description'
                                    color='whitePalette'
                                    focused
                                    inputProps={{ 'data-testid': 'project-textfield-description' }}
                                    defaultValue={ project.projectDescription ? project.projectDescription : '' }
                                />
                                <Button type='submit' color='secondary'>
                                    Update
                                </Button>
                                <Button
                                    onClick={e => {
                                        e.preventDefault();
                                        setProjectEditable(false);
                                    }}
                                    color='error'
                                >
                                    Cancel
                                </Button>
                            </form>
                        ) : (
                            <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 2 }}>
                                {editProject && !updating && !loading && (
                                    <IconButton
                                        onClick={e => {
                                            e.preventDefault();
                                            setProjectEditable(true);
                                        }}
                                        title='Edit project details'
                                        aria-label='Edit project details'
                                        size='large'
                                        sx={{
                                            width: '20px',
                                            height: '20px',
                                            color: 'white'
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                )}
                                <Box sx={{ width: 1 }}>
                                    <Typography
                                        variant='h1'
                                        sx={{
                                            fontSize: '1.5rem',
                                            textAlign: 'left',
                                            pt: 2
                                        }}
                                    >
                                        {updating || loading ? (
                                            <Skeleton
                                                sx={{
                                                    borderRadius: '20px',
                                                    textAlign: 'center',
                                                    backgroundColor: 'white'
                                                }}
                                            />
                                        ) : (
                                            project.projectName
                                        )}
                                    </Typography>
                                    <Typography variant='body1' sx={{ mt: 2, mb: 2 }}>
                                        {updating || loading ? (
                                            <Skeleton
                                                sx={{
                                                    backgroundColor: 'white',
                                                    borderRadius: '20px'
                                                }}
                                            />
                                        ) : (
                                            project.projectDescription
                                        )}
                                    </Typography>
                                </Box>
                            </Box>
                        )}
                    </Container>
                    {!projectEditable && (
                        <Container>
                            {create && (
                                <Button
                                    size='large'
                                    variant='contained'
                                    endIcon={<AddIcon />}
                                    onClick={e => createUniverseOnclick(e)}
                                    disabled={!optionList}
                                    sx={{ float: 'right' }}
                                    color='secondary'
                                >
                                    Add New Universe
                                </Button>
                            )}
                        </Container>
                    )}
                </Grid>
                <Container sx={{ mt: 4 }} maxWidth='false'>
                    <Box style={{}} sx={{ mt: 4 }}>
                        <StripedDataGrid
                            getRowClassName={params =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                            }
                            getRowId={row => `universe_${row.id}`}
                            rows={universes}
                            isRowSelectable={() => false}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[10]}
                            autoHeight
                            autoPageSize={true}
                            loading={universesLoading}
                            disableColumnFilter={true}
                        />
                    </Box>
                </Container>
            </Box>
        </Fragment>
    );
}
