import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import user from './reducers/user';
import users from './reducers/users';
import project from './reducers/project';
import client from './reducers/client';
import universe from './reducers/universe';
import query from './reducers/query';
import cluster from './reducers/cluster';
import notifications from './reducers/notifications';

// Create the root reducer separately so we can extract the RootState type
const rootReducer = combineReducers({
    user,
    users,
    project,
    client,
    universe,
    query,
    cluster,
    notifications
});

export const setupStore = preloadedState => {
    return configureStore({
        reducer: rootReducer,
        preloadedState,
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware({
                immutableCheck: false,
                serializableCheck: false
            })
    });
};
