// React
import { Fragment, useState, useEffect, useRef } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
    updateDemographics,
    updateDiagnosis,
    updatePrescriptions,
    updateProcedures,
    updateSpecialists,
    updateCustomquery,
    updateUseCustom
} from '../../redux/actions/query/queryActions';
import {
    addNotification,
    updateNotification
} from '../../redux/actions/notification/notificationsActions';

// Utilities
import APICaller from '../../utils/APICaller';

// Materialize Includes for Actions
import { Box } from '@mui/system';
import {
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Skeleton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorIcon from '@mui/icons-material/Error';

// Includes for the Hightlight code
import Editor from '@monaco-editor/react';

export function DimensionDetails(props) {
    const query = useSelector(state => state.query);

    let dimensionData;
    let codingValue = 'code';

    switch (props.dimension) {
        case 'diagnosis':
            dimensionData = query.diagnosisData;
            break;
        case 'procedures':
            dimensionData = query.proceduresData;
            break;
        case 'prescriptions':
            dimensionData = query.prescriptionsData;
            codingValue = 'ndc';
            break;
        case 'specialists':
            dimensionData = query.specialistsData;
            break;
        default:
            break;
    }

    return (
        <Fragment>
            {query[props.dimension][0].dimension.length > 0 && (
                <Fragment>
                    <Typography variant='h4' sx={{ fontWeight: 200, fontSize: '1.5rem' }}>
                        Inclusions
                    </Typography>
                    <div style={{ fontSize: '0.9rem' }}>
                        <Box
                            component='div'
                            sx={{
                                p: 2,
                                borderBottom: '1px dashed grey',
                                marginTop: 1,
                                position: 'relative',
                                paddingTop: 2,
                                paddingBottom: 2
                            }}
                        >
                            <strong>
                                <span
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        marginBottom: '16px'
                                    }}
                                >
                                    And
                                </span>
                            </strong>
                            <br></br>
                            {query[props.dimension][0].dimension.map((dimension, index) => (
                                // We are working on inclusions of the And first, so we filter down to the values that have an operator of and
                                <Fragment key={`${props.dimension}_inclusion_and_${index}`}>
                                    {dimension.operator === 'and' && (
                                        <Fragment>
                                            {dimension.results.map((result, jindex) => {
                                                if (!result.description) {
                                                    if (dimensionData) {
                                                        result = dimensionData.find(
                                                            r => r.id === result
                                                        );
                                                        return (
                                                            <p
                                                                key={`inclusion_${props.dimension}_${index}${jindex}_p`}
                                                            >
                                                                {result[codingValue] && (
                                                                    <strong>
                                                                        {result[codingValue]
                                                                            ? result[codingValue] +
                                                                              ' | '
                                                                            : result}
                                                                    </strong>
                                                                )}
                                                                <span
                                                                    style={{
                                                                        textTransform: 'capitalize'
                                                                    }}
                                                                >
                                                                    {result.description
                                                                        ? result.description.toLowerCase()
                                                                        : ''}
                                                                </span>
                                                            </p>
                                                        );
                                                    } else {
                                                        return (
                                                            <Skeleton
                                                                key={`inclusion_${props.dimension}_${index}${jindex}_sk`}
                                                                variant='text'
                                                                animation='pulse'
                                                                sx={{ width: 1, height: '20px' }}
                                                            ></Skeleton>
                                                        );
                                                    }
                                                } else {
                                                    return (
                                                        <p
                                                            key={`inclusion_${props.dimension}_${index}${jindex}_p2`}
                                                        >
                                                            {result[codingValue] && (
                                                                <strong>
                                                                    {result[codingValue]
                                                                        ? result[codingValue] +
                                                                          ' | '
                                                                        : result}
                                                                </strong>
                                                            )}
                                                            <span
                                                                style={{
                                                                    textTransform: 'capitalize'
                                                                }}
                                                            >
                                                                {result.description
                                                                    ? result.description.toLowerCase()
                                                                    : ''}
                                                            </span>
                                                        </p>
                                                    );
                                                }
                                            })}
                                        </Fragment>
                                    )}
                                </Fragment>
                            ))}
                        </Box>
                        <Box
                            component='div'
                            sx={{
                                p: 2,
                                marginTop: 1,
                                position: 'relative',
                                paddingTop: 2,
                                paddingBottom: 2
                            }}
                        >
                            <strong>
                                <span
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        marginBottom: '16px'
                                    }}
                                >
                                    Or
                                </span>
                            </strong>
                            <br></br>
                            {query[props.dimension][0].dimension.map((dimension, index) => (
                                // We are working on inclusions of the And first, so we filter down to the values that have an operator of and
                                <Fragment key={`${props.dimension}_inclusion_or_${index}`}>
                                    {dimension.operator === 'or' && (
                                        <Fragment>
                                            {dimension.results.map((result, jindex) => {
                                                if (!result.description) {
                                                    if (dimensionData) {
                                                        result = dimensionData.find(
                                                            r => r.id === result
                                                        );
                                                        return (
                                                            <p
                                                                key={`inclusion_${props.dimension}_${index}${jindex}_p`}
                                                            >
                                                                {result[codingValue] && (
                                                                    <strong>
                                                                        {result[codingValue]
                                                                            ? result[codingValue] +
                                                                              ' | '
                                                                            : result}
                                                                    </strong>
                                                                )}
                                                                <span
                                                                    style={{
                                                                        textTransform: 'capitalize'
                                                                    }}
                                                                >
                                                                    {result.description
                                                                        ? result.description.toLowerCase()
                                                                        : ''}
                                                                </span>
                                                            </p>
                                                        );
                                                    } else {
                                                        return (
                                                            <Skeleton
                                                                key={`inclusion_${props.dimension}_${index}${jindex}_sk`}
                                                                variant='text'
                                                                animation='pulse'
                                                                sx={{ width: 1, height: '20px' }}
                                                            ></Skeleton>
                                                        );
                                                    }
                                                } else {
                                                    return (
                                                        <p
                                                            key={`inclusion_${props.dimension}_${index}${jindex}_p2`}
                                                        >
                                                            {result[codingValue] && (
                                                                <strong>
                                                                    {result[codingValue]
                                                                        ? result[codingValue] +
                                                                          ' | '
                                                                        : result}
                                                                </strong>
                                                            )}
                                                            <span
                                                                style={{
                                                                    textTransform: 'capitalize'
                                                                }}
                                                            >
                                                                {result.description
                                                                    ? result.description.toLowerCase()
                                                                    : ''}
                                                            </span>
                                                        </p>
                                                    );
                                                }
                                            })}
                                        </Fragment>
                                    )}
                                </Fragment>
                            ))}
                        </Box>
                    </div>
                </Fragment>
            )}

            {query[props.dimension][1].dimension.length > 0 && (
                <Fragment>
                    <Typography
                        variant='h4'
                        sx={{ fontWeight: 200, marginTop: 2, fontSize: '1.5rem' }}
                    >
                        Exclusions
                    </Typography>
                    <div style={{ fontSize: '0.9rem' }}>
                        <div style={{ fontSize: '0.9rem' }}>
                            <Box
                                component='div'
                                sx={{
                                    p: 2,
                                    borderBottom: '1px dashed grey',
                                    marginTop: 1,
                                    position: 'relative',
                                    paddingTop: 2,
                                    paddingBottom: 2
                                }}
                            >
                                <strong>
                                    <span
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            marginBottom: '16px'
                                        }}
                                    >
                                        And
                                    </span>
                                </strong>
                                <br></br>
                                {query[props.dimension][1].dimension.map((dimension, index) => (
                                    // We are working on inclusions of the And first, so we filter down to the values that have an operator of and
                                    <Fragment key={`${props.dimension}_exclusion_and_${index}`}>
                                        {dimension.operator === 'and' && (
                                            <Fragment>
                                                {dimension.results.map((result, jindex) => {
                                                    if (!result.description) {
                                                        if (dimensionData) {
                                                            result = dimensionData.find(
                                                                r => r.id === result
                                                            );
                                                            return (
                                                                <p
                                                                    key={`exclusion_${props.dimension}_${index}${jindex}_p`}
                                                                >
                                                                    {result[codingValue] && (
                                                                        <strong>
                                                                            {result[codingValue]
                                                                                ? result[
                                                                                      codingValue
                                                                                  ] + ' | '
                                                                                : result}
                                                                        </strong>
                                                                    )}
                                                                    <span
                                                                        style={{
                                                                            textTransform:
                                                                                'capitalize'
                                                                        }}
                                                                    >
                                                                        {result.description
                                                                            ? result.description.toLowerCase()
                                                                            : ''}
                                                                    </span>
                                                                </p>
                                                            );
                                                        } else {
                                                            return (
                                                                <Skeleton
                                                                    key={`exclusion_${props.dimension}_${index}${jindex}_p`}
                                                                    variant='text'
                                                                    animation='pulse'
                                                                    sx={{
                                                                        width: 1,
                                                                        height: '20px'
                                                                    }}
                                                                ></Skeleton>
                                                            );
                                                        }
                                                    } else {
                                                        return (
                                                            <p
                                                                key={`exclusion_${props.dimension}_${index}${jindex}_p`}
                                                            >
                                                                {result[codingValue] && (
                                                                    <strong>
                                                                        {result[codingValue]
                                                                            ? result[codingValue] +
                                                                              ' | '
                                                                            : result}
                                                                    </strong>
                                                                )}
                                                                <span
                                                                    style={{
                                                                        textTransform: 'capitalize'
                                                                    }}
                                                                >
                                                                    {result.description
                                                                        ? result.description.toLowerCase()
                                                                        : ''}
                                                                </span>
                                                            </p>
                                                        );
                                                    }
                                                })}
                                            </Fragment>
                                        )}
                                    </Fragment>
                                ))}
                            </Box>
                            <Box
                                component='div'
                                sx={{
                                    p: 2,
                                    marginTop: 1,
                                    position: 'relative',
                                    paddingTop: 2,
                                    paddingBottom: 2
                                }}
                            >
                                <strong>
                                    <span
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            marginBottom: '16px'
                                        }}
                                    >
                                        Or
                                    </span>
                                </strong>
                                <br></br>
                                {query[props.dimension][1].dimension.map((dimension, index) => (
                                    // We are working on inclusions of the And first, so we filter down to the values that have an operator of and
                                    <Fragment key={`${props.dimension}_exclusion_or_${index}`}>
                                        {dimension.operator === 'or' && (
                                            <Fragment>
                                                {dimension.results.map((result, jindex) => {
                                                    if (!result.description) {
                                                        if (dimensionData) {
                                                            result = dimensionData.find(
                                                                r => r.id === result
                                                            );
                                                            return (
                                                                <p
                                                                    key={`exclusion_${props.dimension}_${index}${jindex}_p`}
                                                                >
                                                                    {result[codingValue] && (
                                                                        <strong>
                                                                            {result[codingValue]
                                                                                ? result[
                                                                                      codingValue
                                                                                  ] + ' | '
                                                                                : result}
                                                                        </strong>
                                                                    )}
                                                                    <span
                                                                        style={{
                                                                            textTransform:
                                                                                'capitalize'
                                                                        }}
                                                                    >
                                                                        {result.description
                                                                            ? result.description.toLowerCase()
                                                                            : ''}
                                                                    </span>
                                                                </p>
                                                            );
                                                        } else {
                                                            return (
                                                                <Skeleton
                                                                    key={`exclusion_${props.dimension}_${index}${jindex}_p`}
                                                                    variant='text'
                                                                    animation='pulse'
                                                                    sx={{
                                                                        width: 1,
                                                                        height: '20px'
                                                                    }}
                                                                ></Skeleton>
                                                            );
                                                        }
                                                    } else {
                                                        return (
                                                            <p
                                                                key={`exclusion_${props.dimension}_${index}${jindex}_p`}
                                                            >
                                                                {result[codingValue] && (
                                                                    <strong>
                                                                        {result[codingValue]
                                                                            ? result[codingValue] +
                                                                              ' | '
                                                                            : result}
                                                                    </strong>
                                                                )}
                                                                <span
                                                                    style={{
                                                                        textTransform: 'capitalize'
                                                                    }}
                                                                >
                                                                    {result.description
                                                                        ? result.description.toLowerCase()
                                                                        : ''}
                                                                </span>
                                                            </p>
                                                        );
                                                    }
                                                })}
                                            </Fragment>
                                        )}
                                    </Fragment>
                                ))}
                            </Box>
                        </div>
                    </div>
                </Fragment>
            )}
            {query[props.dimension][0].dimension.length === 0 &&
                query[props.dimension][1].dimension.length === 0 && (
                    <p>There are no dimensions added for {props.dimension}.</p>
                )}
        </Fragment>
    );
}

export default function UniverseCart(props) {
    const dispatch = useDispatch();

    const project = useSelector(state => state.project.current);
    const universe = useSelector(state => state.universe.current);
    const query = useSelector(state => state.query);
    const user = useSelector(state => state.user);
    const notifications = useSelector(state => state.notifications);

    // Component Hooks
    const [expanded, setExpanded] = useState([true, true, true, true, true]);
    const [currentDimension, setCurrentDimension] = useState('');
    const [open, setOpen] = useState(false);
    const [confirmation, setConfirmation] = useState(false);

    // State related to the Custom Query
    const [loading, setLoading] = useState(false);
    const [saveSuccess, setSaveSuccess] = useState();
    const [readOnly, setReadOnly] = useState(true);
    const [reset, setReset] = useState(false);

    // Editor for Monaco variable
    const editorRef = useRef(null);

    useEffect(() => {
        const fetchCustomSQL = async () => {
            setLoading(true);
            dispatch(updateUseCustom(false));
            // Function that retrieves the SQL. This is done by calling the same filterpatients but with a different universe. This universe will include an additional param, so we need to pass a parameter to generateUniverse to indicate this requirement.
            let universe_data = props.generateUniverse('retrieve');
            try {
                // First call the filterpatients so we can generate a SQL based on our current selection
                await APICaller.sendRequest('filterpatients', {
                    universe_data: universe_data,
                    flag: 2
                });
            } catch (error) {
                dispatch(updateCustomquery('Error retrieving Query SQL.'));
            }

            // Create the universeInfo variable with an array and an object on the first position. We need this to validate on the while when we will get the sql.
            let universeInfo = [{ cohortSqlReformat: '' }];

            universeInfo = await APICaller.sendRequest('getuniversebyid', {
                universe_id: universe_data.universe_id
            });

            dispatch(updateCustomquery(universeInfo[0].cohortSqlReformat));
            setLoading(false);
        };

        if (user.role.id === 1 || user.role.id === 2) fetchCustomSQL();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reset]);

    // When updating the custom, we don't rely on an event, but rather on the value that we have been updating automatically whenever we make a change to the textarea
    const updateCustom = () => {
        dispatch(updateUseCustom(true));
        dispatch(updateCustomquery(editorRef.current.getValue()));
        changeReadStatus(true);
    };

    const handleEditorDidMount = editor => {
        editorRef.current = editor;
        editorRef.current.updateOptions({ readOnly: true });
    };

    const changeReadStatus = readOnly => {
        editorRef.current.updateOptions({ readOnly: readOnly });
        setReadOnly(readOnly);
    };

    const resetCode = () => {
        // We want to call both states because we need to trigger a change. We could call the reset on editing, but we wouldn't want to trigger a re-rendering then.
        reset ? setReset(false) : setReset(true);
        changeReadStatus(true);
    };

    const handleChange = panel => (event, isExpanded) => {
        expanded[panel] = isExpanded;
        setExpanded([...expanded]);
    };

    const handleClickOpen = (event, dimension) => {
        event.stopPropagation();
        setCurrentDimension(dimension);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirmationClose = () => {
        setConfirmation(false);
    };

    const openProjectDetails = () => {
        // Clear Values from State
        clearDimension('demographics');
        clearDimension('diagnosis');
        clearDimension('procedures');
        clearDimension('prescriptions');
        clearDimension('specialists');

        props.goTo(`universe_overview/${parseInt(universe.id)}`);
    };

    // Function to clear the dimensions. Clearing a dimension does not means removing the element from the array, as we would cause issues to the other components. Clearing means simply clearing the array associated to a dimension, which is done differently between demographcis and the rest.
    const clearDimension = (dimension = null) => {
        // Because a lot of the dimensions follow the same design on includion and exclusion, we can safely define a const variable of a clear array and simply apply it and dispatch it to the right action
        const newArray = [
            {
                type: 'inclusion',
                dimension: []
            },
            {
                type: 'exclusion',
                dimension: []
            }
        ];

        // We might want to clear the dimension, like when we leave the UniverseCart component to another location on the app
        const dimensionToUse = typeof dimension === 'string' ? dimension : currentDimension;

        switch (dimensionToUse) {
            case 'demographics':
                dispatch(
                    updateDemographics({
                        ...query.demographics,
                        age: [],
                        gender: [],
                        region: [],
                        state: []
                    })
                );
                break;
            case 'diagnosis':
                dispatch(updateDiagnosis(newArray));
                break;
            case 'procedures':
                dispatch(updateProcedures(newArray));
                break;
            case 'prescriptions':
                dispatch(updatePrescriptions(newArray));
                break;
            case 'specialists':
                dispatch(updateSpecialists(newArray));
                break;
            default:
                break;
        }

        setOpen(false);
    };

    // Funtion to handle specific things that will happen inside component when saving a universe
    const saveUniverse = async customQuery => {
        let universe_data = props.generateUniverse();

        if (!customQuery) {
            APICaller.sendRequest('startfilterpatientsjob', { universe_data, flag: 1 });
            // Set the component state to know if the response was successful or not. This is based on the number of the response. 0 is considered as an error.
            // (universeResponse || universeResponse > 0) ? setSaveSuccess(true) : setSaveSuccess(false);
            // setRecordCount(universeResponse);
        } else {
            let custom_universe_sql = {
                universe_sql: editorRef.current.getValue(),
                universe_id: universe.id,
                user_id: user.id
            };
            APICaller.sendRequest('executecustomsql', { custom_universe_sql });
        }

        setSaveSuccess(true);
        setConfirmation(true);

        // As part of the process of saving an universe, we want to dispatch to add a new notification to the state, so this will appear in the header. It is required to validate first if the universeID already exist in the array. If it does, then we want to override and update the current notification. The reason behind this logic is that universes are allowed of just one query at the same time, so overriding the previous one means that the one that will matter is the newest one.
        if (notifications.find(n => n.id === universe_data.universe_id && n.type === 'query')) {
            dispatch(
                updateNotification({
                    id: universe_data.universe_id,
                    started: new Date(),
                    isNew: true,
                    completed: 'NA',
                    type: 'query'
                })
            );
        } else {
            dispatch(
                addNotification({
                    id: universe_data.universe_id,
                    name: universe.universeName,
                    project: project.projectName,
                    started: new Date(),
                    isNew: true,
                    completed: 'NA',
                    type: 'query'
                })
            );
        }

        props.sendNotification(
            universe_data.universe_id,
            universe_data.universeName,
            project.projectName,
            {
                id: universe_data.universe_id,
                project: project.projectName,
                started: new Date(),
                isNew: true
            },
            'query'
        );
    };

    return (
        <Fragment>
            <Dialog
                open={confirmation}
                onClose={handleConfirmationClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                {saveSuccess ? (
                    <Fragment>
                        <DialogTitle id='alert-dialog-title'>
                            <CheckCircleOutlineIcon
                                color='success'
                                fontSize='large'
                                sx={{ mr: 2 }}
                            />
                            Your Universe is being generated!
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id='alert-dialog-description'>
                                Your CSV is currently being generated in our systems. Once the CSV
                                is ready, a popup will appear letting you know you can proceed with
                                your download.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleConfirmationClose}>Close</Button>
                            <Button onClick={openProjectDetails}>Open Universe Overview</Button>
                        </DialogActions>
                    </Fragment>
                ) : (
                    <Fragment>
                        <DialogTitle id='alert-dialog-title'>
                            <ErrorIcon color='error' fontSize='large' sx={{ mr: 2 }} />
                            {query.type === 'new'
                                ? 'Your Universe was not generated'
                                : 'Your Universe was not updated'}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id='alert-dialog-description'>
                                Your current universe returns 0 records. Please edit your dimension
                                configuration and try again.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleConfirmationClose}>Close</Button>
                        </DialogActions>
                    </Fragment>
                )}
            </Dialog>

            {(user.role.id === 1 || user.role.id === 2) && (
                <Accordion expanded={true}>
                    <AccordionSummary
                        // expandIcon={<ExpandMoreIcon />}
                        // aria-controls='panel5a-content'
                        sx={{ justifyContent: 'space-between' }}
                        id='panel5a-header'
                    >
                        <div style={{ display: 'flex', flexFlow: 'column' }}>
                            <Typography variant='h3' sx={{ fontWeight: 400, fontSize: '2rem' }}>
                                Edit SQL Query
                            </Typography>
                        </div>
                        <div style={{ display: 'flex', columnGap: '8px' }}>
                            {readOnly ? (
                                <Fragment>
                                    <Button
                                        variant='text'
                                        size='xs'
                                        style={{ textTransform: 'capitalize' }}
                                        onClick={() => {
                                            changeReadStatus(false);
                                        }}
                                    >
                                        Edit
                                    </Button>
                                    {query.useCustom && (
                                        <Button
                                            variant='text'
                                            size='xs'
                                            style={{ textTransform: 'capitalize' }}
                                            color='error'
                                            onClick={resetCode}
                                        >
                                            Reset Code
                                        </Button>
                                    )}
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <Button
                                        variant='text'
                                        size='xs'
                                        style={{ textTransform: 'capitalize' }}
                                        onClick={updateCustom}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        variant='text'
                                        size='xs'
                                        style={{ textTransform: 'capitalize' }}
                                        color='error'
                                        onClick={resetCode}
                                    >
                                        Reset Code
                                    </Button>
                                </Fragment>
                            )}
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Fragment>
                            {loading ? (
                                <Skeleton
                                    animation='wave'
                                    sx={{ width: 1, height: '300px', mt: '-70px', mb: '-50px' }}
                                ></Skeleton>
                            ) : (
                                <Fragment>
                                    <Editor
                                        style={{
                                            width: '100%',
                                            resize: 'vertical',
                                            fontFamily: 'Inconsolata'
                                        }}
                                        theme='vs-dark'
                                        options={{
                                            minimap: {
                                                enabled: false
                                            },
                                            layoutInfo: {
                                                height: '400px'
                                            },
                                            automaticLayout: true,
                                            fontSize: '14px',
                                            wordWrap: 'on'
                                        }}
                                        onMount={handleEditorDidMount}
                                        height='100%'
                                        defaultLanguage='sql'
                                        sx={{ width: 1, mb: 2 }}
                                        id='custom_query'
                                        defaultValue={query.customQuery}
                                    ></Editor>
                                </Fragment>
                            )}
                        </Fragment>
                    </AccordionDetails>
                </Accordion>
            )}

            {!query.useCustom && (
                <div>
                    <Accordion expanded={expanded[0]} onChange={handleChange(0)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='panel1a-content'
                            sx={{ justifyContent: 'space-between' }}
                            id='panel1a-header'
                        >
                            <div style={{ display: 'flex', flexFlow: 'column' }}>
                                <Typography variant='h3' sx={{ fontWeight: 400, fontSize: '2rem' }}>
                                    Demographics
                                </Typography>
                            </div>
                            <div style={{ display: 'flex', columnGap: '8px' }}>
                                <Button
                                    variant='text'
                                    size='xs'
                                    style={{ textTransform: 'capitalize' }}
                                    onClick={props.stepFunction(0)}
                                >
                                    Edit
                                </Button>
                                <Button
                                    disabled={
                                        query.demographics.age.length === 0 &&
                                        query.demographics.gender.length === 0 &&
                                        query.demographics.region.length === 0 &&
                                        query.demographics.state.length === 0
                                    }
                                    onClick={e => {
                                        handleClickOpen(e, 'demographics');
                                    }}
                                    variant='text'
                                    size='xs'
                                    style={{ textTransform: 'capitalize' }}
                                    color='error'
                                >
                                    Clear Dimensions
                                </Button>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={{ fontSize: '0.9rem' }}>
                                {query.demographics.age.length > 0 && (
                                    <Box>
                                        <strong>Age Ranges:</strong>
                                        {query.optionList && query.optionList.listages ? (
                                            <Fragment>
                                                {query.demographics.age.map((ageGroup, index) => (
                                                    <span key={index}>
                                                        {' ' +
                                                            query.optionList.listages.find(
                                                                o => o.id === ageGroup
                                                            ).description}
                                                        {query.demographics.age[index + 1] && ','}
                                                    </span>
                                                ))}
                                            </Fragment>
                                        ) : (
                                            <Skeleton
                                                variant='text'
                                                animation='pulse'
                                                sx={{ width: 1, height: '20px' }}
                                            ></Skeleton>
                                        )}
                                    </Box>
                                )}
                                {query.demographics.gender.length > 0 && (
                                    <Box>
                                        <strong>Population:</strong>
                                        {query.optionList && query.optionList.listgenders ? (
                                            <Fragment>
                                                {query.demographics.gender.map(
                                                    (genderGroup, index) => (
                                                        <span key={index}>
                                                            {' ' +
                                                                query.optionList.listgenders.find(
                                                                    o => o.id === genderGroup
                                                                ).description}
                                                            {query.demographics.gender[index + 1] &&
                                                                ','}
                                                        </span>
                                                    )
                                                )}
                                            </Fragment>
                                        ) : (
                                            <Skeleton
                                                variant='text'
                                                animation='pulse'
                                                sx={{ width: 1, height: '20px' }}
                                            ></Skeleton>
                                        )}
                                    </Box>
                                )}
                                {(query.demographics.region.length > 0 ||
                                    query.demographics.state.length > 0) && (
                                    <Box>
                                        <strong>Location:</strong>
                                        {query.optionList && query.optionList.listgenders ? (
                                            <Fragment>
                                                {query.demographics.region[0] === 0 && (
                                                    <span> All</span>
                                                )}
                                                {query.demographics.region !== 0 &&
                                                    query.demographics.state.map(
                                                        (stateGroup, index) => (
                                                            <span key={index}>
                                                                {' ' +
                                                                    query.optionList.liststates.find(
                                                                        o => o.id === stateGroup
                                                                    ).description}
                                                                {query.demographics.state[
                                                                    index + 1
                                                                ] && ','}
                                                            </span>
                                                        )
                                                    )}
                                            </Fragment>
                                        ) : (
                                            <Skeleton
                                                variant='text'
                                                animation='pulse'
                                                sx={{ width: 1, height: '20px' }}
                                            ></Skeleton>
                                        )}
                                    </Box>
                                )}
                                {query.demographics.age.length === 0 &&
                                    query.demographics.gender.length === 0 &&
                                    query.demographics.region[0] !== 0 &&
                                    query.demographics.state.length === 0 && (
                                        <p>There are no dimensions added for demographics.</p>
                                    )}
                            </div>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded[1]} onChange={handleChange(1)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='panel2a-content'
                            sx={{ justifyContent: 'space-between' }}
                            id='panel2a-header'
                        >
                            <div style={{ display: 'flex', flexFlow: 'column' }}>
                                <Typography variant='h3' sx={{ fontWeight: 400, fontSize: '2rem' }}>
                                    Diagnosis
                                </Typography>
                            </div>
                            <div style={{ display: 'flex', columnGap: '8px' }}>
                                <Button
                                    variant='text'
                                    size='xs'
                                    style={{ textTransform: 'capitalize' }}
                                    onClick={props.stepFunction(1)}
                                >
                                    Edit
                                </Button>
                                <Button
                                    variant='text'
                                    size='xs'
                                    style={{ textTransform: 'capitalize' }}
                                    color='error'
                                    onClick={e => {
                                        handleClickOpen(e, 'diagnosis');
                                    }}
                                    disabled={
                                        query.diagnosis[0].dimension.length === 0 &&
                                        query.diagnosis[1].dimension.length === 0
                                    }
                                >
                                    Clear Dimensions
                                </Button>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <DimensionDetails dimension='diagnosis'></DimensionDetails>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded[2]} onChange={handleChange(2)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='panel3a-content'
                            sx={{ justifyContent: 'space-between' }}
                            id='panel3a-header'
                        >
                            <div style={{ display: 'flex', flexFlow: 'column' }}>
                                <Typography variant='h3' sx={{ fontWeight: 400, fontSize: '2rem' }}>
                                    Procedures
                                </Typography>
                            </div>
                            <div style={{ display: 'flex', columnGap: '8px' }}>
                                <Button
                                    variant='text'
                                    size='xs'
                                    style={{ textTransform: 'capitalize' }}
                                    onClick={props.stepFunction(2)}
                                >
                                    Edit
                                </Button>
                                <Button
                                    variant='text'
                                    size='xs'
                                    style={{ textTransform: 'capitalize' }}
                                    color='error'
                                    onClick={e => {
                                        handleClickOpen(e, 'procedures');
                                    }}
                                    disabled={
                                        query.procedures[0].dimension.length === 0 &&
                                        query.procedures[1].dimension.length === 0
                                    }
                                >
                                    Clear Dimensions
                                </Button>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <DimensionDetails dimension='procedures'></DimensionDetails>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded[3]} onChange={handleChange(3)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='panel4a-content'
                            sx={{ justifyContent: 'space-between' }}
                            id='panel4a-header'
                        >
                            <div style={{ display: 'flex', flexFlow: 'column' }}>
                                <Typography variant='h3' sx={{ fontWeight: 400, fontSize: '2rem' }}>
                                    Prescriptions
                                </Typography>
                            </div>
                            <div style={{ display: 'flex', columnGap: '8px' }}>
                                <Button
                                    variant='text'
                                    size='xs'
                                    style={{ textTransform: 'capitalize' }}
                                    onClick={props.stepFunction(3)}
                                >
                                    Edit
                                </Button>
                                <Button
                                    variant='text'
                                    size='xs'
                                    style={{ textTransform: 'capitalize' }}
                                    color='error'
                                    onClick={e => {
                                        handleClickOpen(e, 'prescriptions');
                                    }}
                                    disabled={
                                        query.prescriptions[0].dimension.length === 0 &&
                                        query.prescriptions[1].dimension.length === 0
                                    }
                                >
                                    Clear Dimensions
                                </Button>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <DimensionDetails dimension='prescriptions'></DimensionDetails>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded[4]} onChange={handleChange(4)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='panel5a-content'
                            sx={{ justifyContent: 'space-between' }}
                            id='panel5a-header'
                        >
                            <div style={{ display: 'flex', flexFlow: 'column' }}>
                                <Typography variant='h3' sx={{ fontWeight: 400, fontSize: '2rem' }}>
                                    Specialists
                                </Typography>
                            </div>
                            <div style={{ display: 'flex', columnGap: '8px' }}>
                                <Button
                                    variant='text'
                                    size='xs'
                                    style={{ textTransform: 'capitalize' }}
                                    onClick={props.stepFunction(4)}
                                >
                                    Edit
                                </Button>
                                <Button
                                    variant='text'
                                    size='xs'
                                    style={{ textTransform: 'capitalize' }}
                                    color='error'
                                    onClick={e => {
                                        handleClickOpen(e, 'specialists');
                                    }}
                                    disabled={
                                        query.specialists[0].dimension.length === 0 &&
                                        query.specialists[1].dimension.length === 0
                                    }
                                >
                                    Clear Dimensions
                                </Button>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <DimensionDetails dimension='specialists'></DimensionDetails>
                        </AccordionDetails>
                    </Accordion>
                </div>
            )}

            {query.useCustom ? (
                <Button
                    variant='contained'
                    sx={{ mb: 4, mt: 4 }}
                    onClick={() => saveUniverse(true)}
                    disabled={loading}
                >
                    Execute Custom Query
                </Button>
            ) : (
                <Button
                    variant='contained'
                    sx={{ mb: 4, mt: 4 }}
                    onClick={() => saveUniverse(false)}
                    disabled={loading}
                >
                    Generate Universe Profile
                </Button>
            )}

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>
                    {'Clear the dimensions associated to ' + currentDimension + '?'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        Clearing the dimensions will eliminate your previous selections on the
                        dimension.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={clearDimension} color='error'>
                        Clear
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}
