import {
    BUILDQUERY,
    BUILDOPTIONLIST,
    UPDATEDEMOGRAPHICS,
    BUILDDIAGNOSISDATA,
    UPDATEDIAGNOSIS,
    BUILDPROCEDURESDATA,
    UPDATEPROCEDURES,
    BUILDPRESCRIPTIONSDATA,
    UPDATEPRESCRIPTIONS,
    BUILDSPECIALISTSDATA,
    UPDATESPECIALISTS,
    UPDATEQUERY,
    UPDATEOPTIONLIST,
    UPDATECUSTOMQUERY,
    UPDATEUSECUSTOM
} from '../actions/query/queryActionTypes';

// We want to initialize the query state with null so we can do a comparison when we run the queryBuilder the first time
const initialState = {};

export default function query(state = initialState, action) {
    switch (action.type) {
        case BUILDQUERY: {
            return {
                ...state,
                type: action.payload.type,
                demographics: action.payload.demographics,
                diagnosis: action.payload.diagnosis,
                procedures: action.payload.procedures,
                prescriptions: action.payload.prescriptions,
                specialists: action.payload.specialists,
                customQuery: '',
                useCustom: false
            };
        }
        case BUILDOPTIONLIST: {
            return {
                ...state,
                optionList: action.payload
            };
        }
        case UPDATECUSTOMQUERY: {
            return {
                ...state,
                customQuery: action.payload
            };
        }
        case UPDATEUSECUSTOM: {
            return {
                ...state,
                useCustom: action.payload
            };
        }
        case UPDATEQUERY: {
            return {
                ...state,
                type: action.payload.type,
                demographics: action.payload.demographics,
                diagnosis: action.payload.diagnosis,
                procedures: action.payload.procedures,
                prescriptions: action.payload.prescriptions,
                specialists: action.payload.specialists
            };
        }
        case UPDATEOPTIONLIST: {
            return {
                ...state,
                optionList: action.payload
            };
        }
        case UPDATEDEMOGRAPHICS: {
            return {
                ...state,
                demographics: action.payload.demographics
            };
        }
        case BUILDDIAGNOSISDATA: {
            return {
                ...state,
                diagnosisData: action.payload
            };
        }
        case UPDATEDIAGNOSIS: {
            return {
                ...state,
                diagnosis: action.payload.diagnosis
            };
        }
        case BUILDPROCEDURESDATA: {
            return {
                ...state,
                proceduresData: action.payload
            };
        }
        case UPDATEPROCEDURES: {
            return {
                ...state,
                procedures: action.payload.procedures
            };
        }
        case BUILDPRESCRIPTIONSDATA: {
            return {
                ...state,
                prescriptionsData: action.payload
            };
        }
        case UPDATEPRESCRIPTIONS: {
            return {
                ...state,
                prescriptions: action.payload.prescriptions
            };
        }
        case BUILDSPECIALISTSDATA: {
            return {
                ...state,
                specialistsData: action.payload
            };
        }
        case UPDATESPECIALISTS: {
            return {
                ...state,
                specialists: action.payload.specialists
            };
        }
        default:
            return state;
    }
}
