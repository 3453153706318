import { CREATE, UPDATE, GET, UPDATESTEP } from './projectActionTypes';

export const createProject = project => ({
    type: CREATE,
    payload: project
});

export const updateProject = project => ({
    type: UPDATE,
    payload: project
});

export const getProject = project => ({
    type: GET,
    payload: project
});

export const updateProjectStep = step => ({
    type: UPDATESTEP,
    payload: step
});
