export const SETCURRENTCLUSTER = 'SETCURRENTCLUSTER';
export const SETCLUSTERDATA = 'SETCLUSTERDATA';
export const SETMODEL = 'SETMODEL';
export const SETCLUSTERSELECTION = 'SETCLUSTERSELECTION';
export const UPDATECLUSTERSELECTION = 'UPDATECLUSTERSELECTION';
export const UPDATEKMEANSSELECTION = 'UPDATEKMEANSSELECTION';
export const UPDATEDBSCANSELECTION = 'UPDATEDBSCANSELECTION';
export const CLEARMODELSELECTION = 'CLEARMODELSELECTION';
export const UPDATECLUSTER = 'UPDATECLUSTER';
export const UPDATECLUSTERLIST = 'UPDATECLUSTERLIST';
export const UPDATECLUSTERGROUPLIST = 'UPDATECLUSTERGROUPLIST';
export const UPDATEREGULARCOLUMNS = 'UPDATEREGULARCOLUMNS';
export const UPDATECALCTYPECOLUMNS = 'UPDATECALCTYPECOLUMNS';
export const CLEARCOLUMNS = 'CLEARCOLUMNS';
export const UPDATEOPTIONS = 'UPDATEOPTIONS';
export const UPDATEOPTIONSLABEL = 'UPDATEOPTIONSLABEL';
export const CLEARSERIES = 'CLEARSERIES';
