import {
    ADDNOTIFICATION,
    UPDATENOTIFICATION
} from '../actions/notification/notificationsActionTypes';

const initialState = [];

export default function notification(state = initialState, action) {
    switch (action.type) {
        case ADDNOTIFICATION: {
            const newNotifications = [...state];
            newNotifications.unshift(action.payload);
            return newNotifications;
        }
        case UPDATENOTIFICATION: {
            return state.map(n => {
                if (n.id === action.payload.id && n.type === action.payload.type) {
                    return {
                        ...n,
                        isNew: action.payload.isNew,
                        completed:
                            action.payload.completed === 'NA' ? 'NA' : action.payload.completed,
                        started: action.payload.started ? action.payload.started : n.started
                    };
                }
                return n;
            });
        }
        default:
            return state;
    }
}
