// TODO: Find a way to store the values of each of the models in the component state separately, so it's possible to jump from one option to the other preserving the previously selected clusters

// React
import React, { useEffect, Fragment, useState } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { updateProjectStep } from '../../redux/actions/project/projectActions';
import {
    setClusterSelection,
    updateClusterSelection,
    updateKmeansSelection,
    updateDbscanSelection,
    updateRegularColumns,
    updateCalcTypeColumns,
    clearColumns,
    updateOptions,
    updateOptionsLabel,
    clearSeries
} from '../../redux/actions/cluster/clusterActions';
// Utilities

// Components
import ProjectHeader from '../layout/ProjectHeader';

// Utilities
import APICaller from '../../utils/APICaller';

// Materialize Includes
import {
    Container,
    Box,
    Typography,
    Grid,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Chip,
    Select,
    Tabs,
    Tab,
    FormControlLabel,
    RadioGroup,
    FormLabel,
    Radio,
    IconButton,
    Button
} from '@mui/material';
import {
    DataGrid,
    GridLinkOperator,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarExport,
    gridClasses
} from '@mui/x-data-grid';
import { tabsClasses } from '@mui/material/Tabs';

// Icons Includes
import TableChartIcon from '@mui/icons-material/TableChart';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { grey } from '@mui/material/colors';

// HighCharts Includes
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { alpha, styled } from '@mui/material/styles';
const ODD_OPACITY = 0.2;

// Creating custom data Grid component that will render a styling similar to the wireframe, with stripped rows.
const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: theme.palette.grey[200],
        '&:hover, &.Mui-hovered': {
            backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
            '@media (hover: none)': {
                backgroundColor: 'transparent'
            }
        },
        '&.Mui-selected': {
            backgroundColor: alpha(
                theme.palette.primary.main,
                ODD_OPACITY + theme.palette.action.selectedOpacity
            ),
            '&:hover, &.Mui-hovered': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    ODD_OPACITY +
                        theme.palette.action.selectedOpacity +
                        theme.palette.action.hoverOpacity
                ),
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        ODD_OPACITY + theme.palette.action.selectedOpacity
                    )
                }
            }
        }
    }
}));

const CustomToolbar = () => {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton />
            <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
        </GridToolbarContainer>
    );
};

const customFilterPanel = {
    filterPanel: {
        // Force usage of "And" operator
        linkOperators: [GridLinkOperator.And],
        // Display columns by ascending alphabetical order
        columnsSort: 'asc',
        filterFormProps: {
            // Customize inputs by passing props
            linkOperatorInputProps: {
                variant: 'outlined',
                size: 'small'
            },
            columnInputProps: {
                variant: 'outlined',
                size: 'small',
                sx: { mt: 'auto' }
            },
            operatorInputProps: {
                variant: 'outlined',
                size: 'small',
                sx: { mt: 'auto' }
            },
            valueInputProps: {
                InputComponentProps: {
                    variant: 'outlined',
                    size: 'small'
                }
            },
            deleteIconProps: {
                sx: {
                    '& .MuiSvgIcon-root': { color: '#d32f2f' }
                }
            }
        },
        sx: {
            // Customize inputs using css selectors
            '& .MuiDataGrid-filterForm': { p: 2 },
            '& .MuiDataGrid-filterForm:nth-child(even)': {
                backgroundColor: theme => (theme.palette.mode === 'dark' ? '#444' : '#f5f5f5')
            },
            '& .MuiDataGrid-filterFormLinkOperatorInput': {
                mr: 2
            },
            '& .MuiDataGrid-filterFormColumnInput': {
                mr: 2,
                width: 150
            },
            '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
            '& .MuiDataGrid-filterFormValueInput': {
                width: 200
            }
        }
    }
};

export default function UniverseOverview(props) {
    // Redux Hooks
    const dispatch = useDispatch();
    const universe = useSelector(state => state.universe.current);
    const clusters = useSelector(state => state.cluster.clusterList);
    const clusterGroups = useSelector(state => state.cluster.clusterGroupList);
    const clusterSelection = useSelector(state => state.cluster.clusterSelection);
    const kmeansSelection = useSelector(state => state.cluster.kmeansSelection);
    const dbscanSelection = useSelector(state => state.cluster.dbscanSelection);
    const model = useSelector(state => state.cluster.model);
    const regularColumns = useSelector(state => state.cluster.regularColumns);
    const calcTypeColumns = useSelector(state => state.cluster.calcTypeColumns);
    const options = useSelector(state => state.cluster.options);

    // Component Hooks
    const [navValue, setNavValue] = useState(0);
    const [loading, setLoading] = useState(true);
    const [calculationType, setCalculationType] = useState('table_element_index_value');
    const [ready, setReady] = useState(false);

    // We have to use a component hook for the model, as we want to change this without having to dispatch. So what we do is that the component hook will be equal to the value of the model
    const [currentModel, setCurrentModel] = useState(model);

    // Array to determine the view of each of the tables on the page
    const [isTableArray, setIsTableArray] = useState([
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true
    ]);

    // Array to determine if the chart when rendered should used mean or median. By default all charts will always use Mean for starters. This array is only for the tables with calcType columns.
    const [calcTypeChartArray, setCalcTypeChartArray] = useState([
        'mean',
        'mean',
        'mean',
        'mean',
        'mean',
        'mean'
    ]);

    // Data Table Hooks
    /*
    ----------------------------------------------------------------------------
    DEMOGRAPHICS TABLES & CHARTS
    ----------------------------------------------------------------------------
    */
    const [costData, setCostData] = useState([]);
    const [ageData, setAgeData] = useState([]);
    const [ageRangeData, setAgeRangeData] = useState([]);
    const [genderData, setGenderData] = useState([]);
    const [payerData, setPayerData] = useState([]);
    const [regionData, setRegionData] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [prevalenceData, setPrevalenceData] = useState([]);

    /*
    ----------------------------------------------------------------------------
    DIAGNOSIS TABLES
    ----------------------------------------------------------------------------
    */
    const [acuteChronic, setAcuteChronic] = useState([]);
    const [uniqueAcuteChronic, setUniqueAcuteChronic] = useState([]);
    const [diagnosesLowLevel, setDiagnosesLowLevel] = useState([]);
    const [diagnosesHighLevel, setDiagnosesHighLevel] = useState([]);

    /*
    ----------------------------------------------------------------------------
    HOSPITALIZATION TABLES
    ----------------------------------------------------------------------------
    */
    const [hospitalizations, setHospitalizations] = useState([]);

    /*
    ----------------------------------------------------------------------------
    PRESCRIPTIONS TABLES
    ----------------------------------------------------------------------------
    */
    const [prescriptions, setPrescriptions] = useState([]);

    /*
    ----------------------------------------------------------------------------
    PROCEDURES TABLES
    ----------------------------------------------------------------------------
    */
    const [procedures, setProcedures] = useState([]);

    /*
    ----------------------------------------------------------------------------
    SPECIALTIES TABLES
    ----------------------------------------------------------------------------
    */
    const [totalSpecialtiesVisits, setTotalSpecialtiesVisits] = useState([]);
    const [uniqueSpecialtiesVisits, setUniqueSpecialtiesVisits] = useState([]);

    /*
    ----------------------------------------------------------------------------
    SUBSTANCE ABUSE TABLES
    ----------------------------------------------------------------------------
    */
    const [substanceAbuse, setSubstanceAbuse] = useState([]);

    /*
    ----------------------------------------------------------------------------
    VACCINATIONS TABLES
    ----------------------------------------------------------------------------
    */
    const [vaccinations, setVaccinations] = useState([]);

    /*
    ----------------------------------------------------------------------------
    VITALS TABLES
    ----------------------------------------------------------------------------
    */
    const [BMI, setBMI] = useState([]);
    const [DiastolicBP, setDiastolicBP] = useState([]);
    const [SystolicBP, setSystolicBP] = useState([]);
    const [labs, setLabs] = useState([]);

    // Styling for the Menu on Select
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
            }
        }
    };

    const handleClusterChange = (clusterArray, clusterGroupId) => {
        // Create a new param to hold a sorted value that will be from showing in the chips in the right order
        const sortedValue = clusterArray.sort((a, b) => (a > b ? 1 : -1));

        // Ensure that we are only changing one of the elements of the
        dispatch(updateClusterSelection({ clusterArray, clusterGroupId }));

        // Whenever we remove an element from the multiSelect, we don't get information of which element was removed, but rather getting the updated array value. This means that we need to first update the columns used without all the elements that match the same groupId, and then extract this array separately, so we can work with this. We later will add the groupColumns into the updatedColumns, which will include the most recent columns to use.
        let clusterGroupColumns = [];

        // Because everytime we do a selection of a cluster group we start with a fresh array, and we count on receiving the values that are selected, we can simply add the found values.
        sortedValue.forEach(labelId => {
            const clusterName = clusters
                .filter(c => c.model === currentModel)
                .find(c => c.label === labelId).name
                ? clusters.filter(c => c.model === currentModel).find(c => c.label === labelId).name
                : `Cluster ${
                      clusters.filter(c => c.model === currentModel).find(c => c.label === labelId)
                          .label
                  }`;

            clusterGroupColumns.push({
                field: `cluster_${labelId}`,
                headerName: clusterName,
                sortable: true,
                flex: 1,
                minWidth: 200,
                clusterGroup: clusterGroupId || 0,
                id: labelId,
                renderCell: rowData => {
                    // if (universe.row.universePatientCount) {
                    //     return universe.row.universePatientCount.toLocaleString();
                    // } else {
                    //     return 'TBD';
                    // }
                    return rowData.row[`cluster_${labelId}`];
                }
            });

            // After setting up the new columns, we need to add the clusters as as a param in the data tables rows.
            addClusterToData(labelId);
        });

        // When we are going to update the series, which are used for the graph views, we want to simply update the series pertaining the specific group ID that we are working on. As this is handled through a redux dispatch, we need to send to the state the groupID and the new array of series that should be updated
        const newGroupSeries = [];

        // Because on how the select works, we need to ensure that series is always updated to an empty array before we start adding new components, which will always be the updated version
        clusterGroupColumns.forEach(clusterColumn => {
            if (clusterColumn.field !== 'segment') {
                newGroupSeries.push({
                    id: clusterColumn.id,
                    name: clusterColumn.headerName,
                    type: 'column',
                    data: [], // Data is always empty as this will be filled only on the follow up function
                    clusterGroup: clusterGroupId
                });
            }
        });

        // Update the series for this group only
        dispatch(updateOptions({ newGroupSeries, clusterGroupId }));

        // Set new columns for all types. As this is an update, we just need to pass the new column and the groupId
        dispatch(updateRegularColumns({ clusterGroupColumns, clusterGroupId }));
        dispatch(updateCalcTypeColumns({ clusterGroupColumns, clusterGroupId }));
    };

    const handleChange = (event, newValue) => {
        setNavValue(newValue);
    };

    // Set the original for all tables in the comparison

    useEffect(() => {
        let mounted = true;
        setLoading(true);

        // Once we load the component, we want to set up the clusterSelection structure, which is an array of objects, one   per clusterGroup, where we list inside the clusters that we want to compare
        const clusterSelectionArray = [...clusterGroups];
        clusterSelectionArray.map(cGroup => {
            return (cGroup.selection = []);
        });

        dispatch(setClusterSelection(clusterSelectionArray));

        const fetchData = async => {
            // As part of our fetching of data, we need to retrieve the cluster comparison data for each cluster separately, and then add the result as part of a new key on the cluster, named comparisonData. In order to effectively retrieve this data, we will be creating one promise per cluster, where we will proceed only once all promises are resolved.
            const clusterPromises = [];

            // Filter the clusters based on the model
            clusters
                .filter(c => c.model === currentModel)
                .forEach(cluster => {
                    clusterPromises.push(
                        new Promise((resolve, reject) => {
                            APICaller.sendRequest('getclustercomparisondata', {
                                universe_id: universe.id,
                                cluster_id: cluster.id,
                                method: cluster.model
                            })
                                .then(response => {
                                    resolve({
                                        comparisonData: JSON.parse(response),
                                        id: cluster.id
                                    });
                                })
                                .catch(error => {
                                    reject(error);
                                });
                        })
                    );
                });

            // Once we have all the cluster promises, and if they all resolved, we can go ahead and add the comparisonData to each of the clusters.
            Promise.all(clusterPromises)
                .then(values => {
                    values.forEach(clusterValue => {
                        clusters
                            .filter(c => c.model === currentModel)
                            .find(c => c.id === clusterValue.id).comparisonData =
                            clusterValue.comparisonData;
                    });

                    if (values.length > 0) {
                        generateTablesRows();

                        if (ready) {
                            // Check if there is a saved selection with the currentModel that we are using
                            if (currentModel === 'kmeans' && kmeansSelection.length > 0) {
                                kmeansSelection.forEach(cgSelection => {
                                    // Call the handleClusterChange with both values, only if there's a selection to update
                                    if (cgSelection.selection.length > 0) {
                                        handleClusterChange(cgSelection.selection, cgSelection.id);
                                    }
                                });
                            }

                            // Check if there is a saved selection with the currentModel that we are using
                            if (currentModel === 'dbscan' && dbscanSelection.length > 0) {
                                dbscanSelection.forEach(cgSelection => {
                                    // Call the handleClusterChange with both values
                                    if (cgSelection.selection.length > 0) {
                                        handleClusterChange(cgSelection.selection, cgSelection.id);
                                    }
                                });
                            }
                            setLoading(false);
                        }
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        };

        // We only fetchData if there's no prevalenceData length more than 0
        if (mounted) {
            fetchData();
        }

        // Update the step on the project header to step 3
        dispatch(updateProjectStep('3'));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clusters, clusterGroups, currentModel, kmeansSelection, dbscanSelection, ready]);

    // Function to handle using the comparisonData info to generate the rows for all the tables
    const generateTablesRows = () => {
        // TODO: Because what drives the user view is setting up the columns, this means that technically when we are generating the table rows, we could add all the clusters information right over here, instead of loading per cluster selection. This might cause the tables to load slower, but the data would already be available for selection. Something to consider later on.

        // Once cluster data is available and parsed and stored, we can proceed to build up the array for the tables. Because we don't want to do this manually, table data rows are created based on these files, to ensure they are consistent and no manual writing of items is needed.
        const comparisonArray = clusters
            .filter(c => c.model === currentModel)
            .find(c => c.comparisonData).comparisonData;

        // Set Prevalence Data
        setPrevalenceData(
            comparisonArray
                .filter(el => el.table_name === 'Universe Prevalence')
                .map(
                    (item, index) =>
                        (item = {
                            id: index,
                            segment: item.table_element_name,
                            tableName: item.table_name
                        })
                )
        );

        // Set Cost Data
        setCostData(
            comparisonArray
                .filter(el => el.table_name === 'Costs')
                .map(
                    (item, index) =>
                        (item = {
                            id: index,
                            segment: item.table_element_name,
                            calcType: item.table_element_calculation_type,
                            tableName: item.table_name
                        })
                )
        );

        // Set Age Data
        setAgeData(
            comparisonArray
                .filter(el => el.table_name === 'Age')
                .map(
                    (item, index) =>
                        (item = {
                            id: index,
                            segment: item.table_element_name,
                            tableName: item.table_name
                        })
                )
        );

        // Set Age Range Data
        setAgeRangeData(
            comparisonArray
                .filter(el => el.table_name === 'Age Range')
                .map(
                    (item, index) =>
                        (item = {
                            id: index,
                            segment: item.table_element_name,
                            tableName: item.table_name
                        })
                )
        );

        // Set Gender Data
        setGenderData(
            comparisonArray
                .filter(el => el.table_name === 'Gender')
                .map(
                    (item, index) =>
                        (item = {
                            id: index,
                            segment: item.table_element_name,
                            tableName: item.table_name
                        })
                )
        );

        // Set Payer Type Data
        setPayerData(
            comparisonArray
                .filter(el => el.table_name === 'Payer Type')
                .map(
                    (item, index) =>
                        (item = {
                            id: index,
                            segment: item.table_element_name,
                            tableName: item.table_name
                        })
                )
        );

        // Set Region Data
        setRegionData(
            comparisonArray
                .filter(el => el.table_name === 'Region')
                .map(
                    (item, index) =>
                        (item = {
                            id: index,
                            segment: item.table_element_name,
                            tableName: item.table_name
                        })
                )
        );

        // Set State Data
        setStateData(
            comparisonArray
                .filter(el => el.table_name === 'State')
                .map(
                    (item, index) =>
                        (item = {
                            id: index,
                            segment: item.table_element_name,
                            tableName: item.table_name
                        })
                )
        );

        // Set Acute Chronic Data
        setAcuteChronic(
            comparisonArray
                .filter(
                    el =>
                        el.table_name === 'Acute Chronic' ||
                        el.table_name === 'Diagnoses Acute Chronic'
                )
                .map(
                    (item, index) =>
                        (item = {
                            id: index,
                            segment: item.table_element_name,
                            calcType: item.table_element_calculation_type,
                            tableName: item.table_name
                        })
                )
        );

        // Set Unique Acute Chronic Data
        setUniqueAcuteChronic(
            comparisonArray
                .filter(el => el.table_name === 'Unique Acute Chronic')
                .map(
                    (item, index) =>
                        (item = {
                            id: index,
                            segment: item.table_element_name,
                            calcType: item.table_element_calculation_type,
                            tableName: item.table_name
                        })
                )
        );

        // Set Diagnoses Level Low
        setDiagnosesLowLevel(
            comparisonArray
                .filter(el => el.table_name === 'Diagnoses Low Level')
                .map(
                    (item, index) =>
                        (item = {
                            id: index,
                            segment: item.table_element_name,
                            tableName: item.table_name
                        })
                )
        );

        // Set Diagnoses Level High
        setDiagnosesHighLevel(
            comparisonArray
                .filter(el => el.table_name === 'Diagnoses High Level')
                .map(
                    (item, index) =>
                        (item = {
                            id: index,
                            segment: item.table_element_name,
                            tableName: item.table_name
                        })
                )
        );

        // Set Hospitalizations
        setHospitalizations(
            comparisonArray
                .filter(el => el.table_name === 'Hospitalizations')
                .map(
                    (item, index) =>
                        (item = {
                            id: index,
                            segment: item.table_element_name,
                            calcType: item.table_element_calculation_type,
                            tableName: item.table_name
                        })
                )
        );

        // Set Prescriptions
        setPrescriptions(
            comparisonArray
                .filter(el => el.table_name === 'Prescriptions')
                .map(
                    (item, index) =>
                        (item = {
                            id: index,
                            segment: item.table_element_name,
                            tableName: item.table_name
                        })
                )
        );

        // Set Procedures
        setProcedures(
            comparisonArray
                .filter(el => el.table_name === 'Procedures')
                .map(
                    (item, index) =>
                        (item = {
                            id: index,
                            segment: item.table_element_name,
                            tableName: item.table_name
                        })
                )
        );

        // Set Specialties Total Visits
        setTotalSpecialtiesVisits(
            comparisonArray
                .filter(el => el.table_name === 'Total Specialty Visits Counts')
                .map(
                    (item, index) =>
                        (item = {
                            id: index,
                            segment: item.table_element_name,
                            calcType: item.table_element_calculation_type,
                            tableName: item.table_name
                        })
                )
        );

        // Set Specialties Unique Visits
        setUniqueSpecialtiesVisits(
            comparisonArray
                .filter(el => el.table_name === 'Unique Specialty Visits')
                .map(
                    (item, index) =>
                        (item = {
                            id: index,
                            segment: item.table_element_name,
                            tableName: item.table_name
                        })
                )
        );

        // Set Substance Abuse
        setSubstanceAbuse(
            comparisonArray
                .filter(el => el.table_name === 'Substance Abuse')
                .map(
                    (item, index) =>
                        (item = {
                            id: index,
                            segment: item.table_element_name,
                            tableName: item.table_name
                        })
                )
        );

        // Set Vaccines
        setVaccinations(
            comparisonArray
                .filter(el => el.table_name === 'Vaccines')
                .map(
                    (item, index) =>
                        (item = {
                            id: index,
                            segment: item.table_element_name,
                            tableName: item.table_name
                        })
                )
        );

        // Set Vaccines
        setBMI(
            comparisonArray
                .filter(el => el.table_name === 'BMI Category')
                .map(
                    (item, index) =>
                        (item = {
                            id: index,
                            segment: item.table_element_name,
                            tableName: item.table_name
                        })
                )
        );

        // Set Diastolic
        setDiastolicBP(
            comparisonArray
                .filter(el => el.table_name === 'Diastolic BP')
                .map(
                    (item, index) =>
                        (item = {
                            id: index,
                            segment: item.table_element_name,
                            tableName: item.table_name
                        })
                )
        );

        // Set Systolic
        setSystolicBP(
            comparisonArray
                .filter(el => el.table_name === 'Systolic BP')
                .map(
                    (item, index) =>
                        (item = {
                            id: index,
                            segment: item.table_element_name,
                            tableName: item.table_name
                        })
                )
        );

        // Set Labs
        setLabs(
            comparisonArray
                .filter(el => el.table_name === 'Labs')
                .map(
                    (item, index) =>
                        (item = {
                            id: index,
                            segment: item.table_element_name,
                            calcType: item.table_element_calculation_type,
                            tableName: item.table_name
                        })
                )
        );

        setReady(true);
    };

    // Because setting up the data will be similar for all the tables, with just two possible routes, one for regular columns and one for calc type columns, we create two functions to handle both use cases and which will be called when setting up the data when adding one more column.
    // Set Regular Columns Data
    const setRegularData = (item, labelId, calcType, type) => {
        // The only exception where we want to force the calculationType on a regular Data table is with prevalence, which does not includes an index. So we force this to be displayed by element_value. We need to pass a calcType because when we trigger the chance of the data Calcuation, the state is not updated quickly enough for this function to register the latest change, always getting the latest value, which causes inacurate data showing. If calctype is not passed, we can safely use the calculationType value that we have.
        let calculationTypeValue = calcType || calculationType;
        if (item.tableName === 'Universe Prevalence') {
            calculationTypeValue = 'table_element_value';
        }

        // Retrieve value that we want to add
        const dataValue = clusters
            .filter(c => c.model === currentModel)
            .find(c => c.label === labelId)
            .comparisonData.find(
                cD => cD.table_name === item.tableName && cD.table_element_name === item.segment
            )[calculationTypeValue];

        // Format data value
        let floatValue = parseFloat(dataValue).toLocaleString('en-US', {
            minimumFractionDigits: 2
        });

        // Assign value to the item for the table
        item[`cluster_${labelId}`] = floatValue;

        return item;
    };

    // Set Calc Type Columns Data. Because when using mean/median, there is only one value on the object, there is no need to dinamically use the calcType from the previous UI selection.
    const setCalcTypeData = (item, labelId) => {
        // Retrieve value that we want to add
        const dataValue = clusters
            .filter(c => c.model === currentModel)
            .find(c => c.label === labelId)
            .comparisonData.find(
                cD =>
                    cD.table_name === item.tableName &&
                    cD.table_element_name === item.segment &&
                    cD.table_element_calculation_type === item.calcType
            ).table_element_value;

        // Format data value
        let floatValue = parseFloat(dataValue).toLocaleString('en-US', {
            minimumFractionDigits: 2
        });

        // Assign value to the item
        item[`cluster_${labelId}`] = floatValue;

        return item;
    };

    // Function that will take a labelId param and add the cluster Id to the data row of each of the tables available
    const addClusterToData = (labelId, calcType) => {
        // Update Prevalence Data
        setPrevalenceData(
            prevalenceData.map(item => setRegularData(item, labelId, calcType, 'prevalence'))
        );

        // Update Costs Data
        setCostData(costData.map(item => setCalcTypeData(item, labelId)));

        // Update Age Data
        setAgeData(ageData.map(item => setRegularData(item, labelId, calcType)));

        // Update Age Range Data
        setAgeRangeData(ageRangeData.map(item => setRegularData(item, labelId, calcType)));

        // Update Gender Data
        setGenderData(genderData.map(item => setRegularData(item, labelId, calcType)));

        // Update Payer Type Data
        setPayerData(payerData.map(item => setRegularData(item, labelId, calcType)));

        // Update Region Data
        setRegionData(regionData.map(item => setRegularData(item, labelId, calcType)));

        // Update State Data
        setStateData(stateData.map(item => setRegularData(item, labelId, calcType)));

        // Acute Chronic Data
        setAcuteChronic(acuteChronic.map(item => setCalcTypeData(item, labelId)));

        // Unique Acute Chronic Data
        setUniqueAcuteChronic(uniqueAcuteChronic.map(item => setCalcTypeData(item, labelId)));

        // Diagnoses Low Level Data
        setDiagnosesLowLevel(
            diagnosesLowLevel.map(item => setRegularData(item, labelId, calcType))
        );

        // Diagnoses High Level Data
        setDiagnosesHighLevel(
            diagnosesHighLevel.map(item => setRegularData(item, labelId, calcType))
        );

        // Hospitalization Data
        setHospitalizations(hospitalizations.map(item => setCalcTypeData(item, labelId)));

        // Prescriptions Data
        setPrescriptions(prescriptions.map(item => setRegularData(item, labelId, calcType)));

        // Procedures Data
        setProcedures(procedures.map(item => setRegularData(item, labelId, calcType)));

        // Total Visits Data
        setTotalSpecialtiesVisits(
            totalSpecialtiesVisits.map(item => setCalcTypeData(item, labelId))
        );

        // Unique Visits Data
        setUniqueSpecialtiesVisits(
            uniqueSpecialtiesVisits.map(item => setRegularData(item, labelId, calcType))
        );

        // Substance Abuse Data
        setSubstanceAbuse(substanceAbuse.map(item => setRegularData(item, labelId, calcType)));

        // Vaccinations Data
        setVaccinations(vaccinations.map(item => setRegularData(item, labelId, calcType)));

        // BMI Diagnosis Data
        setBMI(BMI.map(item => setRegularData(item, labelId, calcType)));

        // Diastolic BP Data
        setDiastolicBP(DiastolicBP.map(item => setRegularData(item, labelId, calcType)));

        // Systolic BP Data
        setSystolicBP(SystolicBP.map(item => setRegularData(item, labelId, calcType)));

        // Labs Data
        setLabs(labs.map(item => setCalcTypeData(item, labelId)));
    };

    // Change the model view and save the previous cluster selection
    const updateModel = e => {
        // Dispatch redux change. We need this to ensure that the model that we use when loading the data is the correct one.
        setCurrentModel(e.target.value);

        // Clear the columns so we can start anew with the new model options
        dispatch(clearColumns());

        // Clear the series so that we don't have any leftover clusters from a previous model when rendering the chart
        dispatch(clearSeries());

        // Reset the isTable Array so we start as we have it originally on the table view
        setIsTableArray([
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true
        ]);

        // Save the previous selected clusters to the respective model array
        // Once we save, we want to check if there are any saved selections on the model. Because we are updating each of the clusterGroups individually through the redux hook, we just iterate the current clusterSelection and depending on the model, call the right dispatch
        clusterSelection.forEach(cG => {
            if (e.target.value === 'kmeans') {
                dispatch(
                    updateDbscanSelection({
                        clusterGroupId: cG.id,
                        clusterArray: cG.selection
                    })
                );
            } else {
                dispatch(
                    updateKmeansSelection({
                        clusterGroupId: cG.id,
                        clusterArray: cG.selection
                    })
                );
            }
        });

        // Reset Cluster Selection
        const clusterSelectionArray = [...clusterGroups];
        clusterSelectionArray.map(cGroup => {
            return (cGroup.selection = []);
        });

        setClusterSelection(clusterSelectionArray);
    };

    // Set type of data calculation
    const updateDataCalculationType = value => {
        // Update the component value of the type through the hook to the right value.
        setCalculationType(value);

        // Update the value for the yAxis Label for charts
        if (value === 'table_element_index_value') {
            dispatch(updateOptionsLabel('Index'));
        } else {
            dispatch(updateOptionsLabel('Proportion'));
        }
        // Once the calculation type has been updated, we can simply addClusterData to each of the regularColumn clusters per each of the elements on the array. Because the same columns are available, this will only update the values of the table. This will work because when we set up the data of each of the tables, we will always override whatever values the key previously had. W
        regularColumns.forEach(clusterColumn => {
            // We only do this of adding cluster to data if the ID is different than 0, which means that we are bypassing doing this for the first columns that will always render on the table.
            if (clusterColumn.field !== 'segment' || clusterColumn.field !== 'calcType') {
                addClusterToData(clusterColumn.id, value);
            }
        });
    };

    // Functions to handle retrieving the data required to load the data for the charts, being either the actual data for the series or the categories. For this, we only need the name of the table_element_value and nothing else, as the options already include which clusters we want to use and include all the information that we need from them.
    const retrieveChartData = (table, calcIndex = null) => {
        // Const array to hold the return value for the function
        const dataResponse = {
            seriesData: [],
            categories: []
        };
        // Set the calculationType separately, because when using Universe Prevalence, we will always use the element value no matter of the calculationType value
        // If the value of calcIndex is not null, this means that we are looking to retrieve the chart data of a table which includes mean/median calculation type. If that is the case, the calculation type value shouldbe the one that is stored currently in the index of the array.
        let calculationTypeValue = calculationType;

        // The only exception to the categories rule ocurrs for Universe Prevalence, where we actually just want to render one of the options, which is the count. So whenever the table is Universe Prevalence, automatically the columns will only be the Count.
        if (table === 'Universe Prevalence') {
            calculationTypeValue = 'table_element_value';
            dataResponse.categories = ['count'];
        }

        // If the calcIndex is not null, then we want to force the calculationType to use the table_element_value as index will not be available for these tables
        if (typeof calcIndex === 'number') {
            calculationTypeValue = 'table_element_value';
        }

        // The data that we retrieve needs to be applied specifically to the correct clusters, which are determined by the series on the current options
        options.series.forEach((s, index) => {
            // Categories applies the same for all the series, so we will only add this for the first series. So we keep track of this value and reset this to false once we're done with the first.
            let firstSeries = true;

            // Add one array per series on the seriesData array on top
            dataResponse.seriesData.push([]);

            // First get the segments that are associated to the table, as we can have from one to many segments in one same table (represented by rows)
            try {
                clusters
                    .filter(c => c.model === currentModel)
                    .find(c => c.label === s.id)
                    .comparisonData.filter(cD => cD.table_name === table)
                    .forEach(item => {
                        // Once we know what is the list of segments for a table, we can push the elements required for the chart, which are the values and the categories, which should match.
                        if (
                            firstSeries &&
                            table !== 'Universe Prevalence' &&
                            (typeof calcIndex !== 'number' ||
                                item.table_element_calculation_type ===
                                    calcTypeChartArray[calcIndex])
                        ) {
                            dataResponse.categories.push(item.table_element_name);
                        }

                        //At the same time, we only want to add one data input, the count one, to avoid other data for proportion being included.
                        if (table === 'Universe Prevalence') {
                            if (item.table_element_name === 'count') {
                                dataResponse.seriesData[index].push(
                                    parseFloat(item[calculationTypeValue])
                                );
                            }
                        } else {
                            if (
                                typeof calcIndex !== 'number' ||
                                item.table_element_calculation_type ===
                                    calcTypeChartArray[calcIndex]
                            ) {
                                dataResponse.seriesData[index].push(
                                    parseFloat(item[calculationTypeValue])
                                );
                            }
                        }
                    });

                firstSeries = false;
            } catch (error) {
                console.log(error);
            }
        });

        return dataResponse;
    };

    // Function that will handle updating how a chart would be viewed, either through mean or median calculation type. We need to update this and cause a re-render of the data for that graphic alone.
    const updateViewAsChart = (e, index) => {
        const newChartTypeArray = [...calcTypeChartArray];
        newChartTypeArray[index] = e.target.value;
        setCalcTypeChartArray(newChartTypeArray);
    };

    // Generic function to be called by all tables that will simply transform the options value passed by each table into an object which will include data and categories
    const generateChartOptions = (table, calcIndex = null) => {
        const { seriesData, categories } = retrieveChartData(table, calcIndex);

        // Creating a deep copy so that changes on this new object will not update the options object
        const chartOptions = JSON.parse(JSON.stringify(options));

        // Add the series value using the prevalenceSeriesData array
        chartOptions.series.forEach((serie, index) => {
            serie.data = seriesData[index];
        });

        // While this affects only calcType columns, we can proceed to clean the categories array so that we only have unique values.

        // We only add categories the first time, so it's only when the series categories is empty

        if (chartOptions.xAxis.categories.length === 0) {
            chartOptions.xAxis.categories = categories;
        }

        return chartOptions;
    };

    const displayTableChartContainer = (index, data, columns, table, calcIndex = 0) => {
        if (isTableArray[index]) {
            return (
                <StripedDataGrid
                    rows={data}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[10]}
                    autoHeight
                    autoPageSize={true}
                    loading={loading}
                    components={{ Toolbar: CustomToolbar }}
                    componentsProps={customFilterPanel}
                />
            );
        } else {
            // If the columns used for this chart are calcType columns, we need to render an additional element to the chart, which is the select to allow the view of either Mean/Median values.
            if (columns[1] && columns[1].field === 'calcType') {
                return (
                    <Box sx={{}}>
                        <FormControl
                            sx={{
                                mb: 2,
                                mr: 2,
                                w: 1,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <FormLabel id='data-calculation-chart-radio-group'>
                                View Chart as:
                            </FormLabel>
                            <RadioGroup
                                color='primary'
                                row
                                aria-labelledby='data-calculation-chart-radio-group'
                                name='data_calculation_chart-radio-buttons-group'
                                value={calcTypeChartArray[calcIndex]}
                                onChange={e => {
                                    updateViewAsChart(e, calcIndex);
                                }}
                            >
                                <FormControlLabel
                                    color='primary'
                                    value='mean'
                                    control={<Radio />}
                                    label='Mean'
                                />
                                <FormControlLabel
                                    value='median'
                                    control={<Radio />}
                                    label='Median'
                                />
                            </RadioGroup>
                        </FormControl>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={generateChartOptions(table, calcIndex)}
                            style={{ width: '100%' }}
                        />
                    </Box>
                );
            } else {
                return (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={generateChartOptions(table)}
                        style={{ width: '100%' }}
                    />
                );
            }
        }
    };

    // Function for the Table/Chart buttons for each table
    const displayButtons = index => {
        return (
            <Fragment>
                <IconButton
                    className={isTableArray[index] ? 'active-tab' : 'inactive-tab'}
                    aria-label='Table View'
                    sx={{ fontSize: '25px', mr: 1 }}
                    onClick={() => {
                        const newIsTableArray = [...isTableArray];
                        newIsTableArray[index] = true;
                        setIsTableArray(newIsTableArray);
                    }}
                >
                    <TableChartIcon fontSize='inherit' />
                </IconButton>
                <IconButton
                    className={!isTableArray[index] ? 'active-tab' : 'inactive-tab'}
                    aria-label='Graph View'
                    sx={{ fontSize: '25px' }}
                    onClick={() => {
                        const newIsTableArray = [...isTableArray];
                        newIsTableArray[index] = false;
                        setIsTableArray(newIsTableArray);
                    }}
                >
                    <LeaderboardIcon fontSize='inherit' />
                </IconButton>
            </Fragment>
        );
    };

    // Function to use the arrayBuffer of the universe XLS and generate the file for download
    const downloadXLS = async () => {
        let universeXLS;
        let fileName;

        universeXLS = await APICaller.clusterRequest('getClusterXLS', {
            universe_id: `${universe.id}`,
            suffix: '000',
            query: `${model}`
        });
        fileName = `${universe.universeName} ${model} Clusters Data.xlsx`;

        var blob = universeXLS;
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, fileName);
        } else {
            var downloadLink = window.document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob([blob]));
            downloadLink.download = fileName;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
    };

    return (
        <Fragment>
            <ProjectHeader goTo={props.goTo} />
            <Box>
                <Box
                    sx={{
                        backgroundColor: '#0c1742',
                        position: 'sticky',
                        top: 0,
                        zIndex: '100'
                    }}
                >
                    <Typography
                        variant='h1'
                        sx={{
                            fontSize: '2rem',
                            padding: 3,
                            textAlign: 'center',
                            color: 'white'
                        }}
                    >
                        <span style={{ textTransform: 'capitalize' }}>{currentModel}</span> Cluster
                        Comparison
                    </Typography>
                    <Grid
                        sx={{
                            pt: 1,
                            pb: 1,
                            mt: 1,
                            mb: 3,
                            display: 'grid',
                            gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
                            columnGap: 4,
                            rowGap: 2,
                            alignItems: 'start',
                            justifyItems: 'start'
                        }}
                    >
                        <Box sx={{ gridColumn: '1 / 2 span', width: 1 }}>
                            <Box
                                sx={{
                                    pl: 2,
                                    pb: 1,
                                    // mt: 1,
                                    mb: 3,
                                    display: 'flex',
                                    columnGap: 2,
                                    rowGap: 2,
                                    color: 'white',
                                    width: 1,
                                    flexWrap: 'wrap'
                                }}
                            >
                                {clusterGroups.map((clusterGroup, index) => (
                                    <FormControl
                                        sx={{ width: 300, color: 'white' }}
                                        key={index}
                                        className='whiteSelect'
                                    >
                                        <InputLabel id='demo-multiple-chip-label'>
                                            {clusterGroup.clusterGroupName}
                                        </InputLabel>
                                        <Select
                                            disabled={loading}
                                            color='whitePalette'
                                            labelId='demo-multiple-chip-label'
                                            id='demo-multiple-chip'
                                            multiple
                                            value={
                                                clusterSelection.find(
                                                    cG => cG.id === clusterGroup.id
                                                )
                                                    ? clusterSelection.find(
                                                          cG => cG.id === clusterGroup.id
                                                      ).selection
                                                    : []
                                            }
                                            onChange={e => {
                                                handleClusterChange(
                                                    e.target.value,
                                                    clusterGroup.id
                                                );
                                            }}
                                            input={
                                                <OutlinedInput
                                                    id='select-multiple-chip'
                                                    label={clusterGroup.clusterGroupName}
                                                />
                                            }
                                            renderValue={selected => (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexWrap: 'wrap',
                                                        gap: 0.5
                                                    }}
                                                >
                                                    {selected.map(value => (
                                                        <Chip
                                                            key={value}
                                                            label={
                                                                clusters
                                                                    .filter(
                                                                        c =>
                                                                            c.model === currentModel
                                                                    )
                                                                    .find(c => c.label === value)
                                                                    .name ||
                                                                `Cluster ${
                                                                    clusters
                                                                        .filter(
                                                                            c =>
                                                                                c.model ===
                                                                                currentModel
                                                                        )
                                                                        .find(
                                                                            c => c.label === value
                                                                        ).label
                                                                }`
                                                            }
                                                        />
                                                    ))}
                                                </Box>
                                            )}
                                            MenuProps={MenuProps}
                                        >
                                            {clusters
                                                .filter(c => c.model === currentModel)
                                                .map(cluster => {
                                                    // If the cluster has no group ID, we default this to 0 so it will render on the right No Cluster Group category
                                                    if (!cluster.groupId) {
                                                        cluster.groupId = 0;
                                                    }
                                                    return (
                                                        cluster.groupId === clusterGroup.id && (
                                                            <MenuItem
                                                                key={cluster.label}
                                                                value={cluster.label}
                                                                // style={getStyles(name, personName, theme)}
                                                            >
                                                                {cluster.name ||
                                                                    `Cluster ${cluster.label}`}
                                                            </MenuItem>
                                                        )
                                                    );
                                                })}
                                        </Select>
                                    </FormControl>
                                ))}
                            </Box>
                        </Box>
                        <Box sx={{ pl: 2 }}>
                            <FormControl
                                sx={{ width: 1, mb: 2, color: 'white' }}
                                className='whiteSelect'
                            >
                                <FormLabel id='data-calculation-radio-group'>
                                    Data Calculation:
                                </FormLabel>
                                <RadioGroup
                                    color='secondary'
                                    row
                                    aria-labelledby='data-calculation-radio-group'
                                    name='data_calculation-radio-buttons-group'
                                    value={calculationType}
                                    onChange={e => {
                                        updateDataCalculationType(e.target.value);
                                    }}
                                >
                                    <FormControlLabel
                                        color='secondary'
                                        value='table_element_index_value'
                                        control={<Radio color='secondary' />}
                                        label='Index'
                                    />
                                    <FormControlLabel
                                        color='secondary'
                                        value='table_element_value'
                                        control={<Radio color='secondary' />}
                                        label='Proportion'
                                    />
                                </RadioGroup>
                            </FormControl>
                            <FormControl
                                sx={{ width: 1, mb: 2, color: 'white' }}
                                className='whiteSelect'
                            >
                                <FormLabel id='data-calculation-radio-group'>Model:</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby='data-calculation-radio-group'
                                    name='data_calculation-radio-buttons-group'
                                    value={currentModel}
                                    onChange={updateModel}
                                    color='secondary'
                                >
                                    <FormControlLabel
                                        color='secondary'
                                        value='kmeans'
                                        control={<Radio color='secondary' />}
                                        label='KMeans'
                                    />
                                    <FormControlLabel
                                        color='secondary'
                                        value='dbscan'
                                        control={<Radio color='secondary' />}
                                        label='DBScan'
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Grid>
                </Box>

                <Container sx={{ mt: 2 }} maxWidth='false'>
                    <Button
                        variant='contained'
                        sx={{
                            minWidth: 'auto',
                            fontSize: '1rem',
                            lineHeight: 'unset',
                            float: 'right',
                            mb: 3
                        }}
                        disabled={loading}
                        onClick={downloadXLS}
                    >
                        Export All Clusters Data
                    </Button>{' '}
                    <Box
                        sx={{
                            maxWidth: { xs: 400, sm: 650, md: 1050, lg: 1150, xl: 1300 },
                            pl: 0,
                            mb: 3,
                            mt: 3
                        }}
                    >
                        <Tabs
                            value={navValue}
                            onChange={handleChange}
                            variant='scrollable'
                            scrollButtons='auto'
                            allowScrollButtonsMobile
                            aria-label='Comparison Categories Tabs'
                            sx={{
                                [`& .${tabsClasses.scrollButtons}`]: {
                                    '&.Mui-disabled': { opacity: 0.3 }
                                },
                                clear: 'both'
                            }}
                        >
                            <Tab label='Demographics' sx={{ pl: 0 }} />
                            <Tab label='Diagnoses' />
                            <Tab label='Hospitalizations' />
                            <Tab label='Prescriptions' />
                            <Tab label='Procedures' />
                            <Tab label='Specialties' />
                            <Tab label='Substance Abuse' />
                            <Tab label='Vaccinations' />
                            <Tab label='Vitals' />
                        </Tabs>
                    </Box>
                    {navValue === 0 && (
                        <Fragment>
                            <Box sx={{ mb: 3 }}>
                                <Box sx={{ position: 'absolute' }}>{displayButtons(0)}</Box>
                                <Typography variant='h5' sx={{ textAlign: 'center', mb: 2 }}>
                                    Universe Prevalence
                                </Typography>
                                {displayTableChartContainer(
                                    0,
                                    prevalenceData,
                                    regularColumns,
                                    'Universe Prevalence'
                                )}
                            </Box>
                            <Box sx={{ mb: 3 }}>
                                <Box sx={{ position: 'absolute' }}>{displayButtons(1)}</Box>
                                <Typography variant='h5' sx={{ textAlign: 'center', mb: 2 }}>
                                    Costs
                                </Typography>
                                {displayTableChartContainer(
                                    1,
                                    costData,
                                    calcTypeColumns,
                                    'Costs',
                                    0
                                )}
                            </Box>
                            <Box sx={{ mb: 3 }}>
                                <Box sx={{ position: 'absolute' }}>{displayButtons(2)}</Box>
                                <Typography variant='h5' sx={{ textAlign: 'center', mb: 2 }}>
                                    Age
                                </Typography>
                                {displayTableChartContainer(2, ageData, regularColumns, 'Age')}
                            </Box>
                            <Box sx={{ mb: 3 }}>
                                <Box sx={{ position: 'absolute' }}>{displayButtons(3)}</Box>
                                <Typography variant='h5' sx={{ textAlign: 'center', mb: 2 }}>
                                    Age Range
                                </Typography>
                                {displayTableChartContainer(
                                    3,
                                    ageRangeData,
                                    regularColumns,
                                    'Age Range'
                                )}
                            </Box>
                            <Box sx={{ mb: 3 }}>
                                <Box sx={{ position: 'absolute' }}>{displayButtons(4)}</Box>
                                <Typography variant='h5' sx={{ textAlign: 'center', mb: 2 }}>
                                    Gender
                                </Typography>
                                {displayTableChartContainer(
                                    4,
                                    genderData,
                                    regularColumns,
                                    'Gender'
                                )}
                            </Box>
                            <Box sx={{ mb: 3 }}>
                                <Box sx={{ position: 'absolute' }}>{displayButtons(5)}</Box>
                                <Typography variant='h5' sx={{ textAlign: 'center', mb: 2 }}>
                                    Payer Type
                                </Typography>
                                {displayTableChartContainer(
                                    5,
                                    payerData,
                                    regularColumns,
                                    'Payer Type'
                                )}
                            </Box>
                            <Box sx={{ mb: 3 }}>
                                <Box sx={{ position: 'absolute' }}>{displayButtons(6)}</Box>
                                <Typography variant='h5' sx={{ textAlign: 'center', mb: 2 }}>
                                    Region
                                </Typography>
                                {displayTableChartContainer(
                                    6,
                                    regionData,
                                    regularColumns,
                                    'Region'
                                )}
                            </Box>
                            <Box sx={{ mb: 3 }}>
                                <Box sx={{ position: 'absolute' }}>{displayButtons(7)}</Box>
                                <Typography variant='h5' sx={{ textAlign: 'center', mb: 2 }}>
                                    State
                                </Typography>
                                {displayTableChartContainer(7, stateData, regularColumns, 'State')}
                            </Box>
                        </Fragment>
                    )}
                    {navValue === 1 && (
                        <Fragment>
                            <Box sx={{ mb: 3 }}>
                                <Box sx={{ position: 'absolute' }}>{displayButtons(8)}</Box>
                                <Typography variant='h5' sx={{ textAlign: 'center', mb: 2 }}>
                                    Acute Chronic
                                </Typography>
                                {displayTableChartContainer(
                                    8,
                                    acuteChronic,
                                    calcTypeColumns,
                                    'Acute Chronic',
                                    1
                                )}
                            </Box>

                            <Box sx={{ mb: 3 }}>
                                <Box sx={{ position: 'absolute' }}>{displayButtons(9)}</Box>
                                <Typography variant='h5' sx={{ textAlign: 'center', mb: 2 }}>
                                    Unique Acute Chronic
                                </Typography>
                                {displayTableChartContainer(
                                    9,
                                    uniqueAcuteChronic,
                                    calcTypeColumns,
                                    'Unique Acute Chronic',
                                    2
                                )}
                            </Box>
                            <Box sx={{ mb: 3 }}>
                                <Box sx={{ position: 'absolute' }}>{displayButtons(10)}</Box>
                                <Typography variant='h5' sx={{ textAlign: 'center', mb: 2 }}>
                                    Diagnoses Low Level
                                </Typography>
                                {displayTableChartContainer(
                                    10,
                                    diagnosesLowLevel,
                                    regularColumns,
                                    'Diagnoses Low Level'
                                )}
                            </Box>
                            <Box sx={{ mb: 3 }}>
                                <Box sx={{ position: 'absolute' }}>{displayButtons(11)}</Box>
                                <Typography variant='h5' sx={{ textAlign: 'center', mb: 2 }}>
                                    Diagnoses High Level
                                </Typography>
                                {displayTableChartContainer(
                                    11,
                                    diagnosesHighLevel,
                                    regularColumns,
                                    'Diagnoses High Level'
                                )}
                            </Box>
                        </Fragment>
                    )}
                    {navValue === 2 && (
                        <Box sx={{ mb: 3 }}>
                            <Box sx={{ position: 'absolute' }}>{displayButtons(12)}</Box>
                            <Typography variant='h5' sx={{ textAlign: 'center', mb: 2 }}>
                                Hospitalizations
                            </Typography>
                            {displayTableChartContainer(
                                12,
                                hospitalizations,
                                calcTypeColumns,
                                'Hospitalizations',
                                3
                            )}
                        </Box>
                    )}
                    {navValue === 3 && (
                        <Box sx={{ mb: 3 }}>
                            <Box sx={{ position: 'absolute' }}>{displayButtons(13)}</Box>
                            <Typography variant='h5' sx={{ textAlign: 'center', mb: 2 }}>
                                Prescriptions
                            </Typography>
                            {displayTableChartContainer(
                                13,
                                prescriptions,
                                regularColumns,
                                'Prescriptions'
                            )}
                        </Box>
                    )}
                    {navValue === 4 && (
                        <Box sx={{ mb: 3 }}>
                            <Box sx={{ position: 'absolute' }}>{displayButtons(14)}</Box>
                            <Typography variant='h5' sx={{ textAlign: 'center', mb: 2 }}>
                                Procedures
                            </Typography>
                            {displayTableChartContainer(
                                14,
                                procedures,
                                regularColumns,
                                'Procedure Types'
                            )}
                        </Box>
                    )}
                    {navValue === 5 && (
                        <Fragment>
                            <Box sx={{ mb: 3 }}>
                                <Box sx={{ position: 'absolute' }}>{displayButtons(15)}</Box>
                                <Typography variant='h5' sx={{ textAlign: 'center', mb: 2 }}>
                                    Total Specialty Visits Counts
                                </Typography>
                                {displayTableChartContainer(
                                    15,
                                    totalSpecialtiesVisits,
                                    calcTypeColumns,
                                    'Total Specialty Visits Counts',
                                    4
                                )}
                            </Box>
                            <Box sx={{ mb: 3 }}>
                                <Box sx={{ position: 'absolute' }}>{displayButtons(16)}</Box>
                                <Typography variant='h5' sx={{ textAlign: 'center', mb: 2 }}>
                                    Unique Specialty Visits
                                </Typography>
                                {displayTableChartContainer(
                                    16,
                                    uniqueSpecialtiesVisits,
                                    regularColumns,
                                    'Unique Specialty Visits Counts'
                                )}
                            </Box>
                        </Fragment>
                    )}
                    {navValue === 6 && (
                        <Box sx={{ mb: 3 }}>
                            <Box sx={{ position: 'absolute' }}>{displayButtons(17)}</Box>
                            <Typography variant='h5' sx={{ textAlign: 'center', mb: 2 }}>
                                Substance Abuse
                            </Typography>
                            {displayTableChartContainer(
                                17,
                                substanceAbuse,
                                regularColumns,
                                'Substance Abuse'
                            )}
                        </Box>
                    )}
                    {navValue === 7 && (
                        <Box sx={{ mb: 3 }}>
                            <Box sx={{ position: 'absolute' }}>{displayButtons(18)}</Box>
                            <Typography variant='h5' sx={{ textAlign: 'center', mb: 2 }}>
                                Vaccinations
                            </Typography>
                            {displayTableChartContainer(
                                18,
                                vaccinations,
                                regularColumns,
                                'Vaccines'
                            )}
                        </Box>
                    )}
                    {navValue === 8 && (
                        <Fragment>
                            <Box sx={{ mb: 3 }}>
                                <Box sx={{ position: 'absolute' }}>{displayButtons(19)}</Box>
                                <Typography variant='h5' sx={{ textAlign: 'center', mb: 2 }}>
                                    BMI Diagnosis
                                </Typography>
                                {displayTableChartContainer(
                                    19,
                                    BMI,
                                    regularColumns,
                                    'BMI Category'
                                )}
                            </Box>
                            <Box sx={{ mb: 3 }}>
                                <Box sx={{ position: 'absolute' }}>{displayButtons(20)}</Box>
                                <Typography variant='h5' sx={{ textAlign: 'center', mb: 2 }}>
                                    Diastolic BP
                                </Typography>
                                {displayTableChartContainer(
                                    20,
                                    DiastolicBP,
                                    regularColumns,
                                    'Diastolic BP'
                                )}
                            </Box>
                            <Box sx={{ mb: 3 }}>
                                <Box sx={{ position: 'absolute' }}>{displayButtons(21)}</Box>
                                <Typography variant='h5' sx={{ textAlign: 'center', mb: 2 }}>
                                    Systolic BP
                                </Typography>
                                {displayTableChartContainer(
                                    21,
                                    SystolicBP,
                                    regularColumns,
                                    'Systolic BP'
                                )}
                            </Box>
                            <Box sx={{ mb: 3 }}>
                                <Box sx={{ position: 'absolute' }}>{displayButtons(22, true)}</Box>
                                <Typography variant='h5' sx={{ textAlign: 'center', mb: 2 }}>
                                    Labs
                                </Typography>
                                {displayTableChartContainer(22, labs, calcTypeColumns, 'Labs', 5)}
                            </Box>
                        </Fragment>
                    )}
                </Container>
            </Box>
        </Fragment>
    );
}
