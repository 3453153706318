import {
    SETCURRENTCLUSTER,
    SETCLUSTERDATA,
    SETMODEL,
    SETCLUSTERSELECTION,
    UPDATECLUSTERSELECTION,
    UPDATEKMEANSSELECTION,
    UPDATEDBSCANSELECTION,
    UPDATECLUSTER,
    UPDATECLUSTERLIST,
    UPDATECLUSTERGROUPLIST,
    UPDATEREGULARCOLUMNS,
    UPDATECALCTYPECOLUMNS,
    CLEARCOLUMNS,
    UPDATEOPTIONS,
    UPDATEOPTIONSLABEL,
    CLEARSERIES,
    CLEARMODELSELECTION
} from '../actions/cluster/clusterActionTypes';

const initialState = {
    current: {},
    clusterData: '',
    clusterList: [],
    clusterGroupList: [],
    model: '',
    clusterSelection: [],
    kmeansSelection: [],
    dbscanSelection: [],
    regularColumns: [
        {
            field: 'segment',
            headerName: 'Segment',
            sortable: true,
            flex: 1,
            minWidth: 200,
            id: 0
        }
    ],
    calcTypeColumns: [
        {
            field: 'segment',
            headerName: 'Segment',
            sortable: true,
            flex: 1,
            minWidth: 200,
            id: 0
        },
        {
            field: 'calcType',
            headerName: 'Calculation Type',
            sortable: true,
            flex: 1,
            minWidth: 150,
            id: 0
        }
    ],
    options: {
        title: {
            text: null
        },
        series: [],
        xAxis: {
            categories: []
        },
        yAxis: {
            title: {
                text: 'Index'
            }
        },
        credits: {
            enabled: false
        }
    }
};

export default function cluster(state = initialState, action) {
    switch (action.type) {
        case SETCURRENTCLUSTER: {
            return {
                ...state,
                current: action.payload
            };
        }
        case SETCLUSTERDATA: {
            return {
                ...state,
                clusterData: action.payload
            };
        }
        case SETMODEL: {
            return {
                ...state,
                model: action.payload
            };
        }
        case SETCLUSTERSELECTION: {
            return {
                ...state,
                clusterSelection: action.payload
            };
        }
        case UPDATECLUSTERSELECTION: {
            let newClusterGroupSelection = [...state.clusterSelection];
            newClusterGroupSelection.find(cG => cG.id === action.payload.clusterGroupId).selection =
                action.payload.clusterArray;

            return {
                ...state,
                clusterSelection: newClusterGroupSelection
            };
        }
        case UPDATEKMEANSSELECTION: {
            let newClusterGroupSelection = [...state.clusterSelection];
            newClusterGroupSelection.find(cG => cG.id === action.payload.clusterGroupId).selection =
                action.payload.clusterArray;

            return {
                ...state,
                kmeansSelection: JSON.parse(JSON.stringify(newClusterGroupSelection))
            };
        }
        case UPDATEDBSCANSELECTION: {
            let newClusterGroupSelection = [...state.clusterSelection];
            newClusterGroupSelection.find(cG => cG.id === action.payload.clusterGroupId).selection =
                action.payload.clusterArray;

            return {
                ...state,
                dbscanSelection: JSON.parse(JSON.stringify(newClusterGroupSelection))
            };
        }
        case CLEARMODELSELECTION: {
            return {
                ...state,
                kmeansSelection: [],
                dbscanSelection: []
            };
        }
        case UPDATECLUSTER: {
            return {
                ...state,
                current: action.payload
            };
        }
        case UPDATECLUSTERLIST: {
            return {
                ...state,
                clusterList: action.payload.sort((a, b) => (a.label > b.label ? 1 : -1))
            };
        }
        case UPDATECLUSTERGROUPLIST: {
            // We want to add to the beginning of every single of these arrays the No Cluster Group option. So instead of replacing the whole array, we just add to it. We only add it once if there's no id 0 on the array already.
            if (!action.payload.find(el => el.id === 0)) {
                action.payload.unshift({
                    clusterGroupDescription: 'No Cluster Group',
                    clusterGroupName: 'No Cluster Group',
                    id: 0
                });
            }

            return {
                ...state,
                clusterGroupList: action.payload
            };
        }
        case UPDATEREGULARCOLUMNS: {
            // In order to properly update the columns, we first want to remove all the columns given for the clusterGroup, so we can add them again. For this, we filter first the array for all the elements that are not belonging on the same clusterGroup, effectively removing them from the array altogether.
            let newGroupColumns = state.regularColumns.filter(
                c => c.clusterGroup !== action.payload.clusterGroupId
            );

            // Once we have the cleaned up array, we can add back the elements of the new selection to the array
            newGroupColumns = [...newGroupColumns, ...action.payload.clusterGroupColumns];

            // Update the state element for regular column with the new selection
            return {
                ...state,
                regularColumns: JSON.parse(JSON.stringify(newGroupColumns))
            };
        }
        case UPDATECALCTYPECOLUMNS: {
            // In order to properly update the columns, we first want to remove all the columns given for the clusterGroup, so we can add them again. For this, we filter first the array for all the elements that are not belonging on the same clusterGroup, effectively removing them from the array altogether.
            let newGroupColumns = state.calcTypeColumns.filter(
                c => c.clusterGroup !== action.payload.clusterGroupId
            );

            // Once we have the cleaned up array, we can add back the elements of the new selection to the array
            newGroupColumns = [...newGroupColumns, ...action.payload.clusterGroupColumns];

            // Update the state element for regular column with the new selection
            return {
                ...state,
                calcTypeColumns: JSON.parse(JSON.stringify(newGroupColumns))
            };
        }
        case CLEARCOLUMNS: {
            // Whenever we switch from one model to the other, we need to clear the columns available. To do this, we simply set up the state for both regular and calcType to be their original values

            return {
                ...state,
                regularColumns: [
                    {
                        field: 'segment',
                        headerName: 'Segment',
                        sortable: true,
                        flex: 1,
                        minWidth: 200,
                        id: 0
                    }
                ],
                calcTypeColumns: [
                    {
                        field: 'segment',
                        headerName: 'Segment',
                        sortable: true,
                        flex: 1,
                        minWidth: 200,
                        id: 0
                    },
                    {
                        field: 'calcType',
                        headerName: 'Calculation Type',
                        sortable: true,
                        flex: 1,
                        minWidth: 150,
                        id: 0
                    }
                ]
            };
        }
        case UPDATEOPTIONS: {
            // In order to properly update the series, we first want to remove all the series elements given for the clusterGroup, so we can add them again. For this, we filter first the array for all the elements that are not belonging on the same clusterGroup, effectively removing them from the array altogether.
            let newGroupSeries = state.options.series.filter(
                s => s.clusterGroup !== action.payload.clusterGroupId
            );

            // Once we have the cleaned up array, we can add back the elements of the new selection to the array
            newGroupSeries = [...newGroupSeries, ...action.payload.newGroupSeries];

            // Create one new options object
            let newOptions = { ...state.options };

            // Update the series
            newOptions.series = newGroupSeries;

            // Update the state element for regular column with the new selection
            return {
                ...state,
                options: JSON.parse(JSON.stringify(newOptions))
            };
        }
        case UPDATEOPTIONSLABEL: {
            let newOptions = { ...state.options };
            newOptions.yAxis.title.text = action.payload;

            return {
                ...state,
                options: JSON.parse(JSON.stringify(newOptions))
            };
        }
        case CLEARSERIES: {
            // Create one new options object
            let newOptions = { ...state.options };

            // Update the series
            newOptions.series = [];

            // Update the state element for regular column with the new selection
            return {
                ...state,
                options: JSON.parse(JSON.stringify(newOptions))
            };
        }
        default:
            return state;
    }
}
