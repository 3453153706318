import {
    ADDUSER,
    UPDATEUSERROLE,
    UPDATEUSERDETAILS,
    UPDATEUSERSTATUS,
    CLEARUSERS
} from '../actions/users/usersActionTypes';

const initialState = [];

export default function users(state = initialState, action) {
    switch (action.type) {
        case CLEARUSERS: {
            return action.payload;
        }
        case ADDUSER: {
            return [...state, action.payload];
        }
        case UPDATEUSERROLE: {
            const newUsers = [...state];
            newUsers[action.payload.index].roleId = action.payload.role;
            return newUsers;
        }
        case UPDATEUSERDETAILS: {
            const newUsers = [...state];

            // When updating the users, we are really only updating 4 values. First Name, Last Name, emailAddress and publicisEmailAddress. Other values such as role, activeFlag or lastLogin should stay the same. So we focus only on updating these values.
            newUsers[action.payload.index].firstName = action.payload.user.first_name;
            newUsers[action.payload.index].lastName = action.payload.user.last_name;
            newUsers[action.payload.index].emailAddress = action.payload.user.agency_email_address;
            newUsers[action.payload.index].publicisEmailAddress = action.payload.user.lion_login;

            return newUsers;
        }
        case UPDATEUSERSTATUS: {
            const newUsers = [...state];
            newUsers[action.payload.index].activeFlag = action.payload.activeFlag;
            return newUsers;
        }
        default:
            return state;
    }
}
