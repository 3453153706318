import {
    SETCURRENTCLUSTER,
    SETCLUSTERDATA,
    SETMODEL,
    SETCLUSTERSELECTION,
    UPDATECLUSTERSELECTION,
    UPDATEKMEANSSELECTION,
    UPDATEDBSCANSELECTION,
    CLEARMODELSELECTION,
    UPDATECLUSTER,
    UPDATECLUSTERLIST,
    UPDATECLUSTERGROUPLIST,
    UPDATEREGULARCOLUMNS,
    UPDATECALCTYPECOLUMNS,
    CLEARCOLUMNS,
    UPDATEOPTIONS,
    UPDATEOPTIONSLABEL,
    CLEARSERIES
} from './clusterActionTypes';

export const setClusterData = clusterData => ({
    type: SETCLUSTERDATA,
    payload: clusterData
});

export const setCurrentCluster = cluster => ({
    type: SETCURRENTCLUSTER,
    payload: cluster
});

export const setModel = model => ({
    type: SETMODEL,
    payload: model
});

export const setClusterSelection = clusterSelection => ({
    type: SETCLUSTERSELECTION,
    payload: clusterSelection
});

export const updateClusterSelection = clusterGroupSelection => ({
    type: UPDATECLUSTERSELECTION,
    payload: clusterGroupSelection
});

export const updateKmeansSelection = clusterGroupSelection => ({
    type: UPDATEKMEANSSELECTION,
    payload: clusterGroupSelection
});

export const updateDbscanSelection = clusterGroupSelection => ({
    type: UPDATEDBSCANSELECTION,
    payload: clusterGroupSelection
});

export const clearModelSelection = () => ({
    type: CLEARMODELSELECTION,
    payload: 'clear'
});

export const updateCluster = cluster => ({
    type: UPDATECLUSTER,
    payload: cluster
});

export const updateClusterList = clusterList => ({
    type: UPDATECLUSTERLIST,
    payload: clusterList ? clusterList : []
});

export const updateClusterGroupList = clusterGroupList => ({
    type: UPDATECLUSTERGROUPLIST,
    payload: clusterGroupList ? clusterGroupList : []
});

export const updateRegularColumns = column => ({
    type: UPDATEREGULARCOLUMNS,
    payload: column
});

export const updateCalcTypeColumns = column => ({
    type: UPDATECALCTYPECOLUMNS,
    payload: column
});

export const clearColumns = () => ({
    type: CLEARCOLUMNS,
    payload: 'clear'
});

export const updateOptions = series => ({
    type: UPDATEOPTIONS,
    payload: series
});

export const updateOptionsLabel = label => ({
    type: UPDATEOPTIONSLABEL,
    payload: label
});

export const clearSeries = () => ({
    type: CLEARSERIES,
    payload: 'clear'
});
