// React
import React, { useState, useEffect, Fragment } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
    updateUserRole,
    updateUserStatus,
    addUser,
    clearUsers,
    updateUserDetails
} from '../../redux/actions/users/usersActions';

// Utilities
import APICaller from '../../utils/APICaller';

// Materialize Includes for general
import { Box } from '@mui/system';
import {
    Container,
    Select,
    MenuItem,
    Snackbar,
    Alert,
    Slide,
    Typography,
    IconButton,
    Skeleton,
    Button,
    InputLabel,
    FormControl,
    Switch,
    CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport
} from '@mui/x-data-grid';
import { HtmlTooltip } from '../../assets/custom-mui';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AddIcon from '@mui/icons-material/Add';

// Materialize Includes for Dialog
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogContentText,
    DialogActions,
    TextField
} from '@mui/material';

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 40,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 24
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)'
        },
        '& .active': {
            color: '#177ddc'
        }
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff'
            }
        }
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 24,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200
        })
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
        '& p': {
            fontSize: '12px'
        }
    },
    p: {
        fontSize: '12px'
    }
}));

export default function UserAdmin(props) {
    // State used for Dialog for creating/editing users
    const [dialogType, setDialogType] = useState('add');
    const [updatedUser, setUpdatedUser] = useState(false);
    const [openUser, setOpenUser] = useState(false);
    const [saving, setSaving] = useState(false);

    // State used for Data Grid table rendering
    const [userRole, setUserRole] = useState();
    const [roles, setRoles] = useState([]);
    const [rolesInfo, setRolesInfo] = useState([]);
    const [loading, setLoading] = useState(false);

    // State used for snackbar notifications
    const [open, setOpen] = useState(false);
    const [transition] = useState(Slide);
    const [message, setMessage] = useState({
        text: '',
        status: 'success'
    });

    // Redux State
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const users = useSelector(state => state.users);

    // Function to fetch users and their roles to render on the datagrid. We need this function outside the useEffect as usual, so we can call this function whenever a new user is created as well.
    const fetchUsers = async () => {
        setLoading(true);
        dispatch(clearUsers());

        let dbRoles = await APICaller.sendRequest('getroles', {});

        dbRoles.map(r => {
            r.roleName = `${r.roleName.split('user')[0]} User`;
            return r;
        });
        setRoles(dbRoles);

        // Once we are done retrieving the important information of roles and users, we need to modify the user array to add the role assigned to each user
        let currentUsers = await APICaller.sendRequest('listusers', {});

        // We need to render the role information so we can pull the description into the help text element
        setRolesInfo(await APICaller.sendRequest('roleobjectpermission', {}));
        currentUsers.map(async u => {
            let role = await APICaller.sendRequest('getuserroles', {
                user_id: u.id
            });
            u.roleId = role.length > 0 ? role[0].roleId : 0;

            dispatch(addUser(u));
        });

        setLoading(false);
    };

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            // Clear users so we start with the array in blank
            fetchUsers();
        }

        return function cleanup() {
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getFullName = params => {
        return `${params.row.firstName || ''} ${params.row.lastName || ''}`;
    };

    const generateDate = params => {
        return new Date(params.row.insertDtm).toDateString().toLocaleString();
    };

    const userAction = async (e, user) => {
        e.preventDefault();
        setSaving(true);
        const { firstName, lastName, lionEmail, agencyEmail } = e.target;

        if (dialogType === 'add') {
            // Commenting the API call as this is not yet functional.
            let response = await APICaller.sendRequest('createuser', {
                user: {
                    first_name: firstName.value,
                    last_name: lastName.value,
                    publicis_email_address: lionEmail.value,
                    email_address: agencyEmail.value,
                    role_id: userRole
                }
            });

            if (response[0] <= 0) {
                let message =
                    response[0] === 0
                        ? 'User already exist in the systems.'
                        : 'Error while trying to save user. Please try again.';
                setMessage({
                    text: <Typography>{message}</Typography>,
                    status: 'error'
                });
            } else {
                setMessage({
                    text: (
                        <Typography>
                            {response.firstName} {response.lastName} has been added successfully.
                        </Typography>
                    ),
                    status: 'success'
                });

                // Update the userRole to blank, because the dialog has been closed
                setUserRole('');

                // Close dialog
                setOpenUser(false);

                // Trigger re-rendering of the Datagrid, first set up loading to true to represent there is a change happening while we retrieve all of the relevant information
                fetchUsers();
            }
        } else {
            const { firstName, lastName, lionEmail, agencyEmail } = e.target;

            const update_user_data = {
                user_id: user.id,
                first_name: firstName.value,
                last_name: lastName.value,
                agency_email_address: agencyEmail.value,
                lion_login: lionEmail.value
            };

            // Commenting the API call as this is not yet functional.
            let response = await APICaller.sendRequest('updateuserinfo', { update_user_data });

            if (response.length < 1) {
                let message = 'Error while trying to update user. Please try again.';
                setMessage({
                    text: <Typography>{message}</Typography>,
                    status: 'error'
                });
            } else {
                setMessage({
                    text: (
                        <Typography>
                            {firstName.value} {lastName.value} has been updated successfully.
                        </Typography>
                    ),
                    status: 'success'
                });

                // Close dialog
                setOpenUser(false);

                // Update the state of the users to keep track of the update
                dispatch(
                    updateUserDetails({
                        index: users.findIndex(u => u.id === user.id),
                        user: update_user_data
                    })
                );
            }
        }

        // Open the snackbar once the message has been set properly
        setOpen(true);
        setSaving(false);
    };

    // Function to handle updating the status of a user to inactive/active status
    const updateStatus = async (e, user) => {
        let statusText = e.target.checked ? 'active' : 'inactive';
        let statusId = e.target.checked ? 1 : 0;

        setOpen(true);
        setMessage({
            text: (
                <Typography>
                    Updating {user.firstName} {user.lastName} status. Please wait.
                </Typography>
            ),
            status: 'info'
        });

        let response = await APICaller.sendRequest('updateuser', {
            update_user_data: {
                user_id: user.id,
                active: statusId
            },
            action: 'active'
        });

        setOpen(false);

        if (response === 1) {
            setMessage({
                text: (
                    <Typography>
                        {user.firstName} {user.lastName} is now {statusText}.
                    </Typography>
                ),
                status: 'success'
            });
            // Update the state of the users to keep track of the update
            dispatch(
                updateUserStatus({
                    index: users.findIndex(u => u.id === user.id),
                    activeFlag: statusId
                })
            );
        } else {
            setMessage({
                text: (
                    <Typography>
                        There's was an issue updating {user.firstName} {user.lastName} status to{' '}
                        <strong>{statusText}</strong>. Please try again.
                    </Typography>
                ),
                status: 'success'
            });
        }

        setOpen(true);
    };

    // Function that will handle the process of updating a role. While we are always assuming an update, if the current role of a user is 0, it means that there are no entries in the DB. That means that for this scenario, we need to create the role instead.
    const updateRole = async (e, userId, firstName, lastName) => {
        let roleId = e.target.value;
        setOpen(true);
        setMessage({
            text: (
                <Typography>
                    Updating {firstName} {lastName} role. Please wait.
                </Typography>
            ),
            status: 'info'
        });

        let response;

        if (roleId === 0) {
            let create_role = {
                user_id: userId,
                role_id: roleId
            };
            response = await APICaller.sendRequest('createuserrole', { create_role });
        } else {
            let update_role = {
                user_id: userId,
                role_id: roleId
            };
            response = await APICaller.sendRequest('updateuserrole', { update_role });
        }

        setOpen(false);

        // Update snackbar
        if (response.length > 0) {
            setMessage({
                text: (
                    <Typography>
                        {firstName} {lastName} has been assigned with the{' '}
                        {roles.find(r => r.userId === roleId).roleName} role successfully.
                    </Typography>
                ),
                status: 'success'
            });
        } else {
            setMessage({
                text: (
                    <Typography>
                        The assignment of role {roles.find(r => r.userId === roleId).roleName} for{' '}
                        {firstName} {lastName} failed. Please try again.
                    </Typography>
                ),
                status: 'error'
            });
        }

        setOpen(true);

        // Update the state of the users to keep track of the update
        dispatch(
            updateUserRole({
                index: users.findIndex(u => u.id === userId),
                role: roleId
            })
        );
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    // Function to handle sub component for selects
    const RoleSelect = props => {
        if (!props.user.roleId || props.user.roleId === 0) {
            return (
                <Skeleton
                    key={`roleSkeleton_${props.user.id}`}
                    variant='text'
                    animation='pulse'
                    sx={{ width: 3 / 4, height: '20px' }}
                ></Skeleton>
            );
        } else {
            return (
                <Select
                    key={`roleSelect_${props.user.id}`}
                    required
                    labelId='client-helper-label'
                    id='client-select-select'
                    onChange={e => {
                        updateRole(e, props.user.id, props.user.firstName, props.user.lastName);
                    }}
                    defaultValue=''
                    value={props.user.roleId || 0}
                    sx={{ textTransform: 'capitalize', width: 3 / 4, border: 0, height: '32px' }}
                >
                    {roles.map((roleOption, index) => (
                        <MenuItem
                            key={index}
                            value={roleOption.userId}
                            sx={{ textTransform: 'capitalize' }}
                        >
                            <HtmlTooltip
                                title={
                                    <Fragment>
                                        <Typography
                                            color='inherit'
                                            sx={{ textTransform: 'capitalize' }}
                                        >
                                            {roleOption.roleName}
                                        </Typography>
                                        <p style={{ textTransform: '' }}>
                                            {
                                                rolesInfo.find(r => r.roleId === roleOption.userId)
                                                    .roleDescription
                                            }
                                        </p>
                                    </Fragment>
                                }
                            >
                                <IconButton>
                                    <HelpOutlineIcon
                                        sx={{
                                            fontSize: 18,
                                            paddingTop: 0
                                        }}
                                    />
                                </IconButton>
                            </HtmlTooltip>
                            {roleOption.roleName}
                        </MenuItem>
                    ))}
                </Select>
            );
        }
    };

    // Function to handle the switch component for enable/disable
    const Switch = props => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexFlow: 'column',
                    pt: 2,
                    pb: 2,
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <AntSwitch
                    checked={props.user.activeFlag === 1 ? true : false}
                    inputProps={{
                        'aria-label': 'ant design',
                        'data-testid': `statusSwitch_${props.user.id}`
                    }}
                    onChange={e => {
                        updateStatus(e, props.user);
                    }}
                />
                <Typography
                    className={props.user.activeFlag ? 'active-label' : ''}
                    sx={{ fontSize: '14px' }}
                >
                    <strong>{props.user.activeFlag === 1 ? 'Active' : 'Inactive'}</strong>
                </Typography>
            </Box>
        );
    };

    const columns = [
        {
            field: 'fullName',
            headerName: 'Name',
            flex: 1,
            valueGetter: getFullName
        },
        {
            field: 'emailAddress',
            headerName: 'Email',
            flex: 1
        },
        {
            field: 'roleId',
            headerName: 'Role',
            flex: 1,
            renderCell: params =>
                user.role.user.update ? (
                    <RoleSelect
                        updateRole={updateRole}
                        roles={roles}
                        user={params.row}
                        data-testid={`roleSelect_${params.row.id}`}
                    ></RoleSelect>
                ) : (
                    <Typography sx={{ textTransform: 'capitalize' }}>
                        {/* {roles.find(r => r.userId === params.row.roleId).roleName} */}
                        {params.row.roleId}
                    </Typography>
                )
        },
        {
            field: 'insertDtm',
            headerName: 'Created On',
            flex: 1,
            valueGetter: generateDate
        },
        {
            field: 'lastLoginDtm',
            headerName: 'Last Login',
            flex: 1
        },
        {
            field: 'activeFlag',
            headerName: 'Status',
            flex: 0.5,
            align: 'center',
            headerAlign: 'center',
            renderCell: params =>
                user.id !== params.row.id && user.role.user.update ? (
                    <Switch user={params.row} updateStatus={updateStatus}></Switch>
                ) : (
                    <Typography className='active-label' sx={{ fontSize: '14px' }}>
                        <strong>Active</strong>
                    </Typography>
                )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 0.5,
            align: 'center',
            headerAlign: 'center',
            renderCell: params =>
                user.role.user.update && (
                    <Button
                        onClick={() => {
                            setDialogType('edit');
                            setUpdatedUser(params.row);
                            setOpenUser(true);
                        }}
                    >
                        Edit User
                    </Button>
                )
        }
    ];

    return (
        <Fragment>
            <Container
                maxWidth='xl'
                sx={{
                    mt: 3
                }}
            >
                <Box sx={{ width: 1 }}>
                    <h1>User Console</h1>
                    {user.role.user.create && (
                        <Button
                            size='large'
                            variant='contained'
                            endIcon={<AddIcon />}
                            sx={{ mb: 2 }}
                            onClick={() => {
                                setDialogType('add');
                                setUpdatedUser(null);
                                setOpenUser(true);
                            }}
                        >
                            Add New User
                        </Button>
                    )}
                    <div style={{ height: 700, width: '100%', border: 0 }}>
                        <DataGrid
                            disableVirtualization
                            isRowSelectable={() => false}
                            rows={users}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                            loading={loading || users.length === 0}
                            components={{
                                Toolbar: CustomToolbar
                            }}
                            sx={{ border: 0 }}
                        />
                    </div>
                </Box>
            </Container>

            {/* Create and Edit Current user Dialog */}
            <Dialog
                open={openUser}
                onClose={() => {
                    setOpenUser(false);
                }}
                TransitionComponent={Transition}
                aria-describedby='alert-dialog-addUser-description'
                maxWidth='sm'
            >
                <DialogTitle sx={{ pb: 1 }}>
                    <span style={{ textTransform: 'capitalize' }}>{dialogType}</span> User
                </DialogTitle>

                <form
                    onSubmit={e => userAction(e, updatedUser)}
                    className={`${saving ? 'hide-form' : ''}`}
                >
                    <DialogContent sx={{ pt: 0, rowGap: 2 }}>
                        {dialogType === 'add' ? (
                            <DialogContentText id='alert-dialog-addUser-description'>
                                Add new user to the systems.
                            </DialogContentText>
                        ) : (
                            <DialogContentText id='alert-dialog-addUser-description'>
                                Edit current user.
                            </DialogContentText>
                        )}

                        <TextField
                            sx={{ mb: 2, mt: 2 }}
                            required
                            margin='dense'
                            id='firstName'
                            label='First Name'
                            type='text'
                            fullWidth
                            variant='standard'
                            defaultValue={updatedUser ? updatedUser.firstName : ''}
                        />
                        <TextField
                            sx={{ mb: 2, mt: 0 }}
                            required
                            margin='dense'
                            id='lastName'
                            label='Last Name'
                            type='text'
                            fullWidth
                            variant='standard'
                            defaultValue={updatedUser ? updatedUser.lastName : ''}
                        />
                        <TextField
                            sx={{ mb: 2 }}
                            required
                            margin='dense'
                            id='lionEmail'
                            label='Lion Login (.net)'
                            type='email'
                            fullWidth
                            variant='standard'
                            defaultValue={updatedUser ? updatedUser.publicisEmailAddress : ''}
                        />
                        <TextField
                            sx={{ mb: 2 }}
                            required
                            margin='dense'
                            id='agencyEmail'
                            label='Agency Email Address (.com)'
                            type='email'
                            fullWidth
                            variant='standard'
                            defaultValue={updatedUser ? updatedUser.emailAddress : ''}
                        />
                        {dialogType === 'add' && (
                            <FormControl fullWidth sx={{ mt: 2 }}>
                                <InputLabel id='select-user-role-text'>Select a Role</InputLabel>
                                <Select
                                    required
                                    labelId='select-user-role-text'
                                    inputProps={{
                                        'data-testid': 'user-role-select'
                                    }}
                                    id='role'
                                    defaultValue={updatedUser ? updatedUser.roleId : ''}
                                    onChange={e => {
                                        setUserRole(e.target.value);
                                    }}
                                >
                                    <MenuItem value={4}>Guest User</MenuItem>
                                    <MenuItem value={3}>Standard User</MenuItem>
                                    <MenuItem value={2}>Power User</MenuItem>
                                    <MenuItem value={1}>Super User</MenuItem>
                                    <MenuItem value={5}>Tech Admin User</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                    </DialogContent>
                    <DialogActions>
                        {dialogType === 'add' ? (
                            <Button type='submit' disabled={saving}>
                                Add
                            </Button>
                        ) : (
                            <Button type='submit' disabled={saving}>
                                Edit
                            </Button>
                        )}

                        <Button
                            onClick={() => {
                                setOpenUser(false);
                            }}
                            color='error'
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
                {saving && (
                    <Container
                        sx={{
                            display: 'flex',
                            flexFlow: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 6
                        }}
                    >
                        <CircularProgress color='primary' />
                        <Typography
                            variant='h5'
                            sx={{ mt: 2, fontWeight: 800, textAlign: 'center' }}
                        >
                            The user is being {dialogType === 'add' ? 'added' : 'updated'} in our
                            systems. <br></br>Please wait.
                        </Typography>
                    </Container>
                )}
            </Dialog>
            <Snackbar
                open={open}
                autoHideDuration={4000}
                onClose={handleClose}
                TransitionComponent={transition}
            >
                <Alert onClose={handleClose} severity={message.status} sx={{ width: '100%' }}>
                    {message.text}
                </Alert>
            </Snackbar>
        </Fragment>
    );
}
