// React
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useEffect, Fragment, useCallback } from 'react';

// Refdux
import { useDispatch, useSelector } from 'react-redux';
import {
    updateOptionList,
    buildDiagnosisData,
    buildPrescriptionsData,
    buildProceduresData,
    buildSpecialistsData
} from '../../redux/actions/query/queryActions';
import { updateProject } from '../../redux/actions/project/projectActions';
import { updateUniverse, updateUniverseList } from '../../redux/actions/universe/universeActions';
import {
    updateClusterList,
    updateClusterGroupList,
    clearColumns,
    clearModelSelection,
    clearSeries
} from '../../redux/actions/cluster/clusterActions';

// Utilities
import APICaller from '../../utils/APICaller';
import * as env from '../../utils/EnvironmentVariablesReader';

// Materialize Includes
import { Box } from '@mui/system';

import {
    Button,
    Grid,
    Container,
    Typography,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Autocomplete,
    TextField,
    OutlinedInput,
    Checkbox,
    ListItemText,
    IconButton,
    Pagination,
    Tab
} from '@mui/material';
import { HtmlTooltip } from '../../assets/custom-mui';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

// Imports required for DataGrid
import {DataGrid as MuiDataGrid, gridClasses} from '@mui/x-data-grid';
import {alpha, styled} from '@mui/material/styles';

import {
    DataGrid,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector
} from '@mui/x-data-grid';

const ODD_OPACITY = 0.2;
const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: theme.palette.grey[200],
        '&:hover, &.Mui-hovered': {
            backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
            '@media (hover: none)': {
                backgroundColor: 'transparent'
            }
        }
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component={'div'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
        <Pagination
            variant='outlined'
            color='primary'
            count={pageCount}
            page={page + 1}
            onChange={(event, value) => apiRef.current.setPage(value - 1)}
        />
    );
}

// function a11yProps(index) {
//     return {
//         id: `simple-tab-${index}`,
//         'aria-controls': `simple-tabpanel-${index}`,
//     };
// }

export default function Landing(props) {
    const dispatch = useDispatch();

    // State for DropDown Options
    // const [date_options, setDateOptions] = useState([]);
    const [user_options, setUserOptions] = useState([]);
    const [client_options, setClientOptions] = useState([]);
    const [clients, setClients] = useState([]);
    const [users, setUsers] = useState([]);
    const [keywords, setKeywords] = useState([]);

    // State for Table Data
    const [table_data, setTableData] = useState([]);

    // State for Search State
    const [client, setClient] = useState('');
    const [update_at, setUpdateAt] = useState('');
    const [user_id, setUserId] = useState('');
    const [search_project_name, setSearchProjectName] = useState('');

    // State for AutoComplete data
    const [project_autocomplete, setProjectAutocomplete] = useState([]);
    const [keywords_autocomplete, setKeywordsAutocomplete] = useState([]);

    // State for Table Columns
    const [recent_universes, setRecentuniverses] = useState([]);
    const [universe_columns, setuniverseColumns] = useState([]);
    const [project_columns, setProjectColumns] = useState([]);
    const [currentUniverseTable, setCurrentUniverseTable] = useState('1');
    // const [projectError, setProjectError] = useState(false);

    // Skeleton display and filter state
    const [universeLoading, setUniversenLoading] = useState(true);
    const [projectsLoading, setProjectsLoading] = useState(true);
    const [filtered, setFiltered] = useState(false);

    const [tabValue] = useState(0);

    // Redux State Values
    const query = useSelector(state => state.query);
    const user = useSelector(state => state.user);

    // const handleChange = (event, newValue) => {
    //     setTabValue(newValue);
    // };

    // Function to handle the change of the checkboxes inside the Keywords filter dropdown
    const keywordHandleChange = event => {
        const {
            target: { value }
        } = event;
        setKeywords(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        );
    };

    const loadProjects = async (data, users, clients) => {
        let projects = null;
        setProjectColumns([
            {
                field: 'client',
                headerName: 'Client',
                sortable: true,
                flex: 1,
                minWidth: 200
            },
            {
                field: 'project_name',
                headerName: 'Project Name',
                hideable: false,
                sortable: true,
                flex: 1,
                minWidth: 250,
                renderCell: project => {
                    return (
                        <Button
                            // startIcon={<LaunchIcon />}
                            sx={{
                                pl: 0,
                                textAlign: 'left',
                                whiteSpace: 'normal',
                                wordWrap: 'break-word'
                            }}
                            onClick={e => loadProject(e)}
                            value={project.row.id}
                        >
                            {project.row.project_name}
                        </Button>
                    );
                }
            },
            {
                field: 'keywords',
                headerName: 'Keywords',
                sortable: true,
                flex: 1
            },
            {
                field: 'date_created',
                headerName: 'Date Created',
                sortable: true,
                flex: 1
            },
            // {
            //     field: 'update_date',
            //     headerName: 'Date Modified',
            //     sortable: true,
            //     flex: 1
            // },
            {
                field: 'created_by',
                headerName: 'Created By',
                sortable: true,
                flex: 1
            }
        ]);
        projects = await APICaller.sendRequest('listprojects', data);
        let project_data = [];

        if (projects && projects.length > 0) {
            for (let project of projects) {
                let user = users.find(x => x.id === project.creationUserName);
                let client = clients.find(x => x.id === project.clientId);
                let p = {
                    id: project.id,
                    client: client ? client.name : 'No Client',
                    project_name: project.projectName,
                    keywords: project.projectKeywords,
                    date_created: project.creationDate,
                    update_date: project.updateDate != null ? project.updateDate : 'N/A',
                    created_by: `${user ? `${user.firstName} ${user.lastName}` : ''}`
                };
                project_data.push(p);
            }
            project_data.sort(function (a, b) {
                var key1 = new Date(a.date_created).getTime();
                var key2 = new Date(b.date_created).getTime();
                if (key1 > key2) {
                    return -1;
                } else if (key1 === key2) {
                    return 0;
                } else {
                    return 1;
                }
            });
            setTableData(project_data);
            setProjectsLoading(false);
        } else {
            setProjectsLoading(false);
            return;
        }
    };

    const DataGriduniverses = styled(MuiDataGrid)(({ theme }) => ({
        '& .MuiDataGrid-columnHeaders': { display: 'none' },
        '& .MuiDataGrid-virtualScroller': { marginTop: '0!important' },
        '& .MuiDataGrid-cell': { padding: 0 }
    }));

    const loadRecentuniverses = async (users, path) => {
        setUniversenLoading(true);
        let dataRecentUniverses = [];

        setuniverseColumns([
            {
                field: 'universeInfo',
                flex: 1,
                width: 1,
                renderCell: universe => (
                    <Box>
                        <Button
                            // startIcon={<LaunchIcon />}
                            sx={{
                                textAlign: 'left',
                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                                fontSize: '1rem',
                                pl: 0,
                                pr: 0
                            }}
                            onClick={e => openUniverse(e)}
                            value={universe.row.id}
                        >
                            {universe.row.universeName}
                        </Button>
                        <span>{universe.row.universeInfo}</span>
                    </Box>
                )
            }
        ]);

        dataRecentUniverses = await APICaller.sendRequest(path, {});
        if (dataRecentUniverses && dataRecentUniverses.length === 0) {
            setUniversenLoading(false);
            return;
        }

        let updatedUniverses = [];

        if (dataRecentUniverses.length > 0) {
            for (let universe of dataRecentUniverses) {
                // Retrieve the user that either created or updated the universe
                let universeUser = users.find(
                    u =>
                        u.id ===
                        universe[path === 'listrecentuniverses' ? 'updateUserId' : 'viewUserId']
                );

                // If there is no updateUser, then we display an unknown text
                if (!universeUser) {
                    universeUser = {
                        firstName: 'Unknown',
                        lastName: ''
                    };
                }

                let recentuniverse;

                // Depending on the path used, the userInfo (the text that will appear in the table) will be different. So we need to adapt this text based on the path received by the function.
                if (path === 'listrecentuniverses') {
                    recentuniverse = {
                        id: universe.id,
                        universeName: universe.universeName,
                        universeInfo: ` · ${universe.updateDtm ? 'Updated on' : 'Created on'} ${
                            universe.updateDtm ? universe.updateDtm : universe.insertDtm
                        } by ${universeUser.firstName} ${universeUser.lastName}`
                    };
                } else {
                    recentuniverse = {
                        id: universe.id,
                        universeName: universe.universeName,
                        universeInfo: ` · Viewed on ${
                            universe.viewDtm ? universe.viewDtm : universe.viewDtm
                        } by ${universeUser.firstName} ${universeUser.lastName}`
                    };
                }

                updatedUniverses.push(recentuniverse);
            }
            setRecentuniverses(updatedUniverses);
            setUniversenLoading(false);
        }
    };

    // Function to handle opening an universe overview page, where we only pass the value of the event so we can pass this as props to the overview component.
    const openUniverse = event => {
        props.goTo(`universe_overview/${parseInt(event.target.value)}`);
    };

    const fetchData = async e => {
        setProjectsLoading(true);
        // window.dataLayer.push({
        //     event: 'event',
        //     eventProps: {
        //         category: 'project',
        //         label: 'User filtered projects on the table',
        //         action: 'filter',
        //         value: `Filtered using: Name (${search_project_name}), client(${client}), keywords(${search_keywords}), updated_at(${update_at}), user_id(${user_id})`
        //     }
        // });
        e.preventDefault();
        await loadProjects(
            {
                project_params: {
                    project_name: search_project_name,
                    client: client,
                    keywords: keywords.join(','),
                    update_at: update_at === '' ? 1 : update_at,
                    user_id: user_id
                }
            },
            users,
            clients
        );
        setProjectsLoading(false);
        setFiltered(true);
    };

    const clearSearch = () => {
        // When we clear, we want to clear the inputs as well, by changing the values of the component state
        setProjectName('');

        loadProjects(
            {
                project_params: {
                    project_name: '',
                    client: '',
                    keywords: '',
                    update_at: 0,
                    user_id: ''
                }
            },
            users,
            clients
        );

        setUserId('');
        setUpdateAt('');
        setClient('');
        setKeywords([]);
        setSearchProjectName('');
        setFiltered(false);
    };

    const loadProject = useCallback(
        async e => {
            let mounted = true;
            if (mounted) {
                // setLoading(true);
                // e.preventDefault(e);
                // let data = {
                //     project_id: e.target.value
                // };
                // let project = await APICaller.sendRequest('getprojectbyid', data);
                // let client_data = {
                //     client_id: project.clientId
                // };
                // let client = await APICaller.sendRequest('getclientbyid', client_data);
                // // window.dataLayer.push({
                // //     event: 'event',
                // //     eventProps: {
                // //         category: 'project',
                // //         label: 'User loaded a project from the table results',
                // //         action: 'load',
                // //         value: `Project ${project.id} from client ${client.id}`
                // //     }
                // // });
                // dispatch(getClient(client));
                // dispatch(getProject(project));
                // setLoading(false);
                props.goTo(`project_overview/${e.target.value}`);
            }

            return () => {
                mounted = false;
            };
        },
        [props]
    );

    const loadOptionList = async () => {
        let listages = await APICaller.sendRequest('listagegroups', {});
        let listgenders = await APICaller.sendRequest('listgenders', {});
        let listregions = await APICaller.sendRequest('listregions', {});
        let liststates = await APICaller.sendRequest('liststates', {});

        // Add an empty states array in every region
        listregions.map(r => {
            return (r.states = []);
        });

        // Once we have both regions and states, we need to alter the array of states to add the respective regions
        liststates.forEach(state => {
            listregions
                .find(r => r.id === state.regionId)
                .states.push({
                    id: state.id,
                    code: state.code,
                    description: state.description
                });
        });

        dispatch(
            updateOptionList({
                listages: listages,
                listgenders: listgenders,
                listregions: listregions,
                liststates: liststates
            })
        );
    };

    const loadDimensionData = async dimension => {
        // When loading a dimension data, we need to do a special procedure when dealing with diagnosis, procedures and prescriptions, where we need to create a promise to retrieve the info in a batch. The only exception is visit specialties which we can call normally.
        if (dimension === 'listvisitspecialties') {
            dispatch(buildSpecialistsData(await APICaller.sendRequest('listvisitspecialties', {})));
        } else {
            fetch(`${env.APP_BASE_URL}/${dimension}.json`)
                .then(res => res.json())
                .then(result => {
                    // Dispatch for each data that we want to add to the state
                    switch (dimension) {
                        case 'diagnosis':
                            dispatch(buildDiagnosisData(result));
                            break;
                        case 'procedure':
                            dispatch(buildProceduresData(result));
                            break;
                        case 'prescription':
                            dispatch(buildPrescriptionsData(result));
                            break;
                        default:
                            break;
                    }
                })
                .catch();
        }
    };

    useEffect(() => {
        let mounted = true;

        // Clear all the redux values that we might have stored from a previous cluster comparison, to avoid issues
        dispatch(clearColumns());
        dispatch(clearSeries());
        dispatch(clearModelSelection());

        const fetchData = async () => {
            let default_data = {
                project_params: {
                    project_name: '',
                    client: '',
                    keywords: '',
                    update_at: 0,
                    user_id: ''
                }
            };

            let projects = await APICaller.sendRequest('listprojects', default_data);
            let project_data = [];
            let keywords_items = [];
            let project_items = [];

            // Set up the component hook for users and clients
            let users = await APICaller.sendRequest('listusers', {});
            let clients = await APICaller.sendRequest('listclients', {});

            setUsers(users);
            setClients(clients);

            loadProjects(default_data, users, clients);
            loadRecentuniverses(users, 'listrecentuniverses');

            if (!projects || projects.length === 0) {
                // setProjectError(true);
                return;
            }

            for (let project of projects) {
                let user = await users.find(x => x.id === project.creationUserName);
                let client = await clients.find(x => x.id === project.clientId);
                let p = {
                    id: project.id,
                    client: client ? client.name : 'No Client',
                    project_name: project.projectName,
                    keywords: project.projectKeywords,
                    date_created: project.creationDate,
                    update_date: project.updateDate !== null ? project.updateDate : 'N/A',
                    created_by: `${user.firstName} ${user.lastName}`
                };

                let project_item = {
                    value: project.id + project.projectName,
                    label: project.projectName
                };

                // For the keywords, we don't want to simply store a string with the full keyword of the project, but rather want to be able to separate them by commas so we can create a checkbox filter. So for this, we first split the keyword by commas and store the result in keyword item as spread.
                keywords_items.push(...p.keywords.split(',').map(kw => kw.trim()));
                project_items.push(project_item);
                project_data.push(p);
            }

            await project_data.sort(function (a, b) {
                var key1 = new Date(a.date_created).getTime();
                var key2 = new Date(b.date_created).getTime();
                if (key1 > key2) {
                    return -1;
                } else if (key1 === key2) {
                    return 0;
                } else {
                    return 1;
                }
            });

            let client_options = [];
            client_options.push({ value: '', label: 'All Clients' });
            for (let client of clients) {
                let option = { value: client.id, label: client.name };
                client_options.push(option);
            }

            let user_options = [];
            user_options.push({ value: '', label: 'Any User' });
            for (let user of users) {
                let user_option = {
                    value: user.id,
                    label: `${user.firstName} ${user.lastName}`
                };
                if (!user_options.find(uOption => uOption.value === user.id)) {
                    user_options.push(user_option);
                }
            }

            // let date_options = [
            //     { value: '', label: 'Any Date' },
            //     { value: 1, label: 'Past 1 Month' },
            //     { value: 2, label: 'Past 2 Months' },
            //     { value: 3, label: 'Past 3 Months' },
            //     { value: 6, label: 'Past 6 Months' }
            // ];

            setClientOptions(client_options);
            setUserOptions(user_options);
            // setDateOptions(date_options);
            setTableData(project_data);
            setProjectsLoading(false);
            setProjectAutocomplete(project_items);

            // Before we do any changes to the auto-complete, we want to ensure that we make them all lower case first
            // When adding the keywords for the autocomplete, we need to ensure that we are passing unique values. To do this, we turn the array into a set.
            setKeywordsAutocomplete([...new Set(keywords_items.map(k => k.toLowerCase()))]);

            // Once we are done loading the information required for the landing page, we want to proceed to load the data required for the QueryBuilder. This is the best place to load the data, because the user has logged into the application but hasn't yet loaded the QB. This will ensure that the data will be available the moment the user selects the QB, rather than having this happening on the QB Component itself, which would cause some delays for rendering.
            // If there are no listData options, we need to create this and add to state
            // Dispatch with the updated list dimension data
            if (!query.optionList) {
                loadOptionList();
            }

            if (!query.diagnosisData) {
                loadDimensionData('diagnosis');
            }

            if (!query.prescriptionsData) {
                loadDimensionData('prescription');
            }

            if (!query.proceduresData) {
                loadDimensionData('procedure');
            }

            if (!query.specialistsData) {
                loadDimensionData('listvisitspecialties');
            }
        };

        if (mounted) {
            // window.dataLayer.push({
            //     event: 'pageview',
            //     page: {
            //         url: '',
            //         title: 'Dashboard'
            //     }
            // });
            fetchData();
        }

        // Clear the current project, universe, cluster, clusterLIst and Client
        dispatch(updateProject({}));
        dispatch(updateUniverse({}));
        dispatch(updateUniverseList([]));
        dispatch(updateClusterGroupList([]));
        dispatch(updateClusterList([]));

        return () => (mounted = false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    // Function to handle change on the navigation tab for recent universes
    const handleChangeTab = async (event, newValue) => {
        // Update the hook for the component so we can keep track of the UI change
        setCurrentUniverseTable(newValue);

        // Setup the table data depending on the value, using the path param
        await loadRecentuniverses(
            users,
            newValue === '1' ? 'listrecentuniverses' : 'listrecentlyvieweduniverses'
        );
    };

    const createProjectOnClick = e => {
        e.preventDefault();
        props.goTo('project_creation');
    };

    const userChange = event => {
        setUserId(event.target.value);
    };

    const clientChange = event => {
        setClient(event.target.value);
    };

    const setProjectName = event => {
        // If the event is an empty string, we are calling this when we clear the search. So we can simply pass this empty string to the setProjectName
        setSearchProjectName(event === '' ? event : event.target.value);
    };

    return (
        <Fragment>
            <Container maxWidth='xl'></Container>

            <Grid className='landing-grid'>
                <Container
                    sx={{ backgroundColor: 'white', pt: 2 }}
                    maxWidth={false}
                >
                    <Typography
                        variant='h1'
                        sx={{ fontSize: '1.5em', mt: 4, mb: 2, fontWeight: 500 }}
                    >
                        Dashboard
                    </Typography>
                    <Box
                        sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}
                        className='recently-tab'
                    >
                        <Typography
                            variant='h2'
                            sx={{ fontSize: '1.125rem', fontWeight: 'bolder', flex: '1' }}
                        >
                            Audience Universes
                        </Typography>
                        <TabContext value={currentUniverseTable}>
                            <Box
                                sx={{
                                    flex: '2'
                                }}
                            >
                                <TabList
                                    onChange={handleChangeTab}
                                    aria-label='Update Recent Universe Tables'
                                >
                                    <Tab
                                        label='Recently Updated'
                                        value='1'
                                        disabled={currentUniverseTable === '1'}
                                        inputProps={{
                                            'data-testid': 'test_recentlyUpdated'
                                        }}
                                    />
                                    <Tab
                                        label='Recently Viewed'
                                        value='2'
                                        disabled={currentUniverseTable === '2'}
                                        inputProps={{
                                            'data-testid': 'test_recentlyViewed'
                                        }}
                                    />
                                </TabList>
                            </Box>
                        </TabContext>
                    </Box>

                    <Box sx={{ pt: 2, pb: 2, fontSize: '1rem' }}>
                        <DataGriduniverses
                            // getRowId={row => `recentUniverse_${row.id}`}
                            rows={recent_universes}
                            isRowSelectable={() => false}
                            columns={universe_columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            autoHeight
                            getRowHeight={() => 'auto'}
                            autoPageSize={true}
                            loading={universeLoading}
                            components={{
                                Pagination: CustomPagination
                            }}
                        />
                    </Box>
                </Container>
                {user.role.project.create && (
                    <Container
                        sx={{
                            backgroundColor: '#efefef',
                            color: '',
                            pt: 2,
                            pb: 2,
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Container sx={{ textAlign: 'center' }}>
                            <Typography variant='body1' component={'div'} sx={{ mb: 5 }}>
                                To create a new patient universe project, please click the button
                                below.
                            </Typography>
                            <Button
                                size='large'
                                variant='contained'
                                endIcon={<AddIcon />}
                                onClick={e => createProjectOnClick(e)}
                                disabled={universeLoading}
                                color='primary'
                            >
                                Create new project
                            </Button>
                        </Container>
                    </Container>
                )}
            </Grid>
            <Container maxWidth='false'>
                <Typography
                    variant='h2'
                    sx={{ pt: 2, mb: 2, fontSize: '1.125rem', fontWeight: 'bolder' }}
                >
                    Projects
                    <HtmlTooltip
                        title={
                            <Fragment>
                                <Typography color='inherit' sx={{}}>
                                    A project allows to group multiple universes based on the same
                                    client.
                                </Typography>
                            </Fragment>
                        }
                    >
                        <IconButton aria-label='Project Tooltip Button'>
                            <HelpOutlineIcon
                                sx={{
                                    fontSize: 18,
                                    paddingTop: 0
                                }}
                            />
                        </IconButton>
                    </HtmlTooltip>
                </Typography>
                {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Projects" {...a11yProps(0)} />
                        <Tab label="Active" {...a11yProps(1)} disabled />
                        <Tab label="Archive" {...a11yProps(2)} disabled />
                    </Tabs>
                </Box> */}
                <TabPanel value={tabValue} index={0} sx={{ m: 0 }}>
                    <Grid sx={{ mt: 2, mb: 2 }}>
                        <form
                            onSubmit={e => fetchData(e)}
                            style={{
                                display: 'flex',
                                padding: '0',
                                flexFlow: 'column',
                                rowGap: '8px'
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'grid',
                                    columnGap: 1
                                }}
                                style={{
                                    gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr)'
                                }}
                            >
                                <FormControl sx={{ minWidth: 120 }} size='small'>
                                    <Autocomplete
                                        disablePortal
                                        id='combo-box-projects'
                                        options={project_autocomplete}
                                        onSelect={setProjectName}
                                        value={search_project_name}
                                        isOptionEqualToValue={(option, value) =>
                                            option.id === value.id
                                        }
                                        renderInput={formatResult => (
                                            <TextField
                                                {...formatResult}
                                                label='Search by Projects'
                                            />
                                        )}
                                    />
                                </FormControl>
                                <FormControl sx={{ minWidth: 120 }} size='large'>
                                    <InputLabel id='demo-multiple-checkbox-label'>
                                        Keywords
                                    </InputLabel>
                                    <Select
                                        labelId='demo-multiple-checkbox-label'
                                        id='combo-box-keywords'
                                        multiple
                                        value={keywords}
                                        onChange={keywordHandleChange}
                                        input={<OutlinedInput label='Keywords' />}
                                        renderValue={selected => selected.join(', ')}
                                        // MenuProps={MenuProps}
                                    >
                                        {keywords_autocomplete.map(keyword => (
                                            <MenuItem key={keyword} value={keyword} sx={{}}>
                                                <Checkbox
                                                    checked={keywords.indexOf(keyword) > -1}
                                                />
                                                <ListItemText primary={keyword} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box
                                sx={{
                                    display: 'grid',
                                    columnGap: 1
                                }}
                                style={{
                                    gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr)'
                                }}
                            >
                                <FormControl sx={{ minWidth: 120 }} size='large'>
                                    <InputLabel id='client-helper-label'>
                                        Search by Client
                                    </InputLabel>
                                    <Select
                                        aria-labelledby='client-helper-label'
                                        id='client-helper-select'
                                        label='Search by Client'
                                        onChange={clientChange}
                                        value={client}
                                    >
                                        {client_options.map((clientOption, index) => (
                                            <MenuItem key={index} value={clientOption.value}>
                                                {clientOption.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                {/* <FormControl sx={{ minWidth: 120 }} size='large'>
                                    <InputLabel id='datemodified-helper-label'>
                                        Search by Date Modified
                                    </InputLabel>
                                    <Select
                                        placeholder='Past 3 Months'
                                        aria-labelledby='datemodified-helper-label'
                                        id='date-modified-select'
                                        label='Search by Date Modified'
                                        onChange={dateChange}
                                        defaultValue=''
                                    >
                                        {date_options.map((dateOption, index) => (
                                            <MenuItem key={index} value={dateOption.value}>
                                                {dateOption.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl> */}
                                <FormControl sx={{ minWidth: 120 }} size='large'>
                                    <InputLabel id='createdBy-helper-label'>
                                        Search by Created By
                                    </InputLabel>
                                    <Select
                                        placeholder='Any User'
                                        aria-labelledby='createdBy-helper-label'
                                        id='createdBy-select-select'
                                        label='Search by Created By'
                                        onChange={userChange}
                                        defaultValue=''
                                    >
                                        {user_options.map((userOption, index) => (
                                            <MenuItem key={index} value={userOption.value}>
                                                {userOption.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box sx={{ display: 'flex', columnGap: 1 }}>
                                <Button
                                    variant='outlined'
                                    fontSize='small'
                                    endIcon={<SearchIcon />}
                                    type='submit'
                                    sx={{ width: 0.5, height: '100%' }}
                                    disabled={
                                        !search_project_name &&
                                        keywords.length === 0 &&
                                        !client &&
                                        !update_at &&
                                        !user_id
                                    }
                                >
                                    Submit Search
                                </Button>
                                <Button
                                    variant='outlined'
                                    fontSize='small'
                                    endIcon={<ClearIcon />}
                                    type='submit'
                                    disabled={!filtered}
                                    sx={{ width: 0.5, height: '100%' }}
                                    onClick={clearSearch}
                                >
                                    Clear
                                </Button>
                            </Box>
                        </form>
                    </Grid>
                </TabPanel>
                <TabPanel value={tabValue} index={1}></TabPanel>
                <TabPanel value={tabValue} index={2}></TabPanel>

                {/* <Collapse in={projectError && filtered}>
                    <Alert
                        severity='error'
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setProjectError(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                    >
                        There are no available projects with the current search parameters. Please try with another input.
                    </Alert>
                </Collapse> */}

                <Box style={{}}>
                    <StripedDataGrid
                        getRowClassName={params =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }
                        getRowId={row => `project_${row.id}`}
                        rows={table_data}
                        isRowSelectable={() => false}
                        columns={project_columns}
                        pageSize={5}
                        rowsPerPageOptions={[10]}
                        autoHeight
                        autoPageSize={true}
                        loading={projectsLoading}
                        disableColumnFilter={true}
                    />
                </Box>
            </Container>
        </Fragment>
    );
}
