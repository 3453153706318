import { AUTH, LOGOUT, LOGINFAILED, UPDATEROLE } from '../actions/user/userActionTypes';

const initialState = null;

export default function user(state = initialState, action) {
    switch (action.type) {
        case AUTH: {
            return action.payload;
        }
        case LOGOUT: {
            return action.payload;
        }
        case LOGINFAILED: {
            return action.payload;
        }
        case UPDATEROLE: {
            return {
                ...state,
                role: action.payload
            };
        }
        default:
            return state;
    }
}
