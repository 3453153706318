// React
import React, { useState, useEffect, Fragment } from 'react';
import { useSearchParams } from 'react-router-dom';

// Redux
import { useDispatch } from 'react-redux';
import { authentication } from './redux/actions/user/userActions';
import { registerUpdate } from './redux/actions/universe/universeActions';
import { updateNotification } from './redux/actions/notification/notificationsActions';

// Components
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import Landing from './components/pages/Landing';
import CreateProject from './components/pages/CreateProject';
import CreateUniverse from './components/pages/CreateUniverse';
import QueryBuilder from './components/pages/QueryBuilder';
import ProjectOverview from './components/pages/ProjectOverview';
import UniverseOverview from './components/pages/UniverseOverview';
import CreateCluster from './components/pages/CreateCluster';
import ClusterOverview from './components/pages/ClusterOverview';
import ClusterComparison from './components/pages/ClusterComparison';

// Ancillary Pages Component
import AboutInsagic from './components/ancillaryPages/AboutInsagic';
import Segmentation from './components/ancillaryPages/Segmentation';
import ContactUs from './components/ancillaryPages/ContactUs';

// Utilities
import '@popperjs/core'; // Edit here
import APICaller from './utils/APICaller';
import LLCaller from './utils/LLCaller';
import jwt_decode from 'jwt-decode';

// CSS Styling
import './assets/styles/App.scss';

// Includes required for the Theme Provider
import { ThemeProvider, createTheme, experimental_sx as sx } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Snackbar from '@mui/material/Snackbar';
import { Alert, Typography, Container, Box, LinearProgress } from '@mui/material';
import Slide from '@mui/material/Slide';
import UserAdmin from './components/pages/UserAdmin';

//Icons from MUI
import LoginSharpIcon from '@mui/icons-material/LoginSharp';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

export default function Home(props) {
    // MUI Custom Theme
    let theme = createTheme({
        typography: {
            fontFamily: ['Roboto'].join(','),
            button: {
                textTransform: 'capitalize',
                color: 'primaryButton',
                borderRadius: '30px'
            }
            // fontSize: '1rem'
        },
        shape: {
            borderRadius: 0
        },
        palette: {
            primary: {
                light: '#0c1742',
                main: '#0c1742',
                dark: '#0c1742',
                contrastText: '#FFFFFF'
            },
            secondary: {
                light: '#fd943c',
                main: '#fd943c',
                dark: '#fd943c',
                contrastText: '#FFFFFF'
            },
            tertiary: {
                light: '#d43beb',
                main: '#d43beb',
                dark: '#d43beb',
                contrastText: '#FFFFFF'
            },
            quaternary: {
                light: '#0bdde7',
                main: '#0bdde7',
                dark: '#0bdde7',
                contrastText: '#FFFFFF'
            },
            whitePalette: {
                light: '#FFFFFF',
                main: '#FFFFFF',
                dark: '#FFFFFF',
                contrastText: '#FFFFFF'
            }
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: sx({
                        borderRadius: '30px',
                        color: 'primaryButton',
                        justifyContent: 'center'
                    })
                },
                defaultProps: {
                    // color: 'primaryButton'
                }
            }
        }
    });

    // This is a app function that we need to pass to two different components, Login and Header.This will handle retrieving the role information from the endpoints and filling the structure that we expect.
    const retrieveRoleDetails = async user => {
        // Before we dispatch the user information response, we want to retrieve the permissions of the user and know if they are power users.
        let availableRoles = await APICaller.sendRequest('getuserroles', {
            user_id: user
        });

        let availablePrivileges = await APICaller.sendRequest('useraccessprivileges', {
            user_id: user
        });

        // Once we have the role information, we want to build up an object that will include the permissions as well, along with the authentication details}
        let roleObject = {
            id: !availableRoles || availableRoles.length === 0 ? 0 : availableRoles[0].roleId,
            project: {},
            query: {},
            universe: {},
            user: {}
        };

        ['project', 'query', 'universe', 'user'].forEach(object => {
            ['create', 'delete', 'update', 'view'].forEach(type => {
                let arrayItem = availablePrivileges.find(
                    priv => priv.objectName === object && priv.permissionName === type
                );
                if (arrayItem) {
                    roleObject[object][type] = arrayItem.objectRangeName;
                }
            });
        });

        return roleObject;
    };

    const goTo = section => {
        // When we call the goTo for the overview pages, we are passing a query param with the ID of the element we wish to load. In order to retrieve this id, we need to split the string on the ?, and use the second value as the string param.
        const sectionName = section.split('/')[0];
        const sectionId = section.split('/')[1];

        switch (sectionName) {
            case 'project_creation':
                setView(<CreateProject goTo={goTo} setOpen={setOpen} setMessage={setMessage} />);
                break;
            case 'landing':
                setView(<Landing goTo={goTo} />);
                break;
            case 'universe':
                setView(<CreateUniverse goTo={goTo} setOpen={setOpen} setMessage={setMessage} />);
                break;
            case 'query_builder':
                setView(
                    <QueryBuilder
                        goTo={goTo}
                        sendNotification={sendNotification}
                        setOpen={setOpen}
                        setMessage={setMessage}
                    />
                );
                break;
            case 'project_overview':
                setView(<ProjectOverview goTo={goTo} id={sectionId} />);
                break;
            case 'universe_overview':
                setView(
                    <UniverseOverview
                        goTo={goTo}
                        id={sectionId}
                        setOpen={setOpen}
                        setMessage={setMessage}
                    />
                );
                break;
            case 'cluster_creation':
                setView(<CreateCluster goTo={goTo} setOpen={setOpen} setMessage={setMessage} />);
                break;
            case 'cluster_overview':
                setView(<ClusterOverview goTo={goTo} id={sectionId} />);
                break;
            case 'cluster_comparison':
                setView(<ClusterComparison goTo={goTo} id={sectionId} />);
                break;
            case 'user_admin':
                setView(<UserAdmin goTo={goTo} setOpen={setOpen} setMessage={setMessage} />);
                break;
            case 'aboutinsagic':
                setView(<AboutInsagic goTo={goTo} />);
                break;
            case 'segmentation':
                setView(<Segmentation goTo={goTo} />);
                break;
            case 'contactus':
                setView(<ContactUs goTo={goTo} />);
                break;
            default:
                break;
        }
    };

    // Redux
    const dispatch = useDispatch();

    // Component Hooks
    const [view, setView] = useState('');
    const [message, setMessage] = useState({
        text: '',
        status: 'info'
    });
    const [open, setOpen] = useState(false);
    const [exist, setExist] = useState(false);
    const [active, setActive] = useState(false);
    const [done, setDone] = useState(false);
    const [error, setError] = useState(false);
    const [errMessage, setErrMessage] = useState('');
    const [searchParams, setSearchParams] = useSearchParams('');
    const [transition] = useState(Slide);

    const useStyles = makeStyles({
        root: {
            height: 10,
            borderRadius: 5
        },
        bar: () => ({
            borderRadius: 5,
            background: 'linear-gradient(90deg, #0bdde7 0%, #fd943c 50%, #d43beb 100%)'
        })
    });

    const Progress = () => {
        const classes = useStyles();

        return (
            <LinearProgress
                classes={{ root: classes.root, bar: classes.bar }}
                variant='indeterminate'
            />
        );
    };

    useEffect(() => {
        // FetchData function to be used in we have a need to call the LL again.
        const continueWithLL = async () => {
            let response = await LLCaller.sendRequest('login', { location: 'home' });
            window.location.replace(response.data.redirectURL);
        };

        // Handle retrieving the element from the URL and then clearing the state and value
        let token = searchParams.get('token');
        let idToken = searchParams.get('idToken');
        let errorAccess = searchParams.get('error');

        // If we are getting an error from the response instead of a token, it means that something happened while doing the login of the app, and we should display an error page instead of continuing to try to access the app.
        if (errorAccess) {
            setDone(true);
            setError(true);
            setErrMessage(decodeURI(errorAccess));

            // Clear and set search params
            searchParams.delete('errorAccess');
            setSearchParams(searchParams);

            return;
        }

        // If we are using the user props, passed by a testing component or any other option, we want to simply use this user object and call the retrieveUser function.
        if (props.user) {
            retrieveUser(props.user);
        } else if (idToken && token) {
            // Once we have the tokens, we want to check if they are still valid. If they are not, we clear them so we have to call the LL SSO process again.
            if (
                new Date().getTime() < jwt_decode(idToken).exp ||
                new Date().getTime() < jwt_decode(token).exp
            ) {
                continueWithLL();
                return;
            }

            // 👇️ delete each query param
            searchParams.delete('token');
            searchParams.delete('idToken');

            // 👇️ update state after
            setSearchParams(searchParams);

            // Decode the string and determine the user information. Add the name_id to the component state to check later. We add the full object because we might want to use the name to display a message if the user does not exist.
            let userInfo = jwt_decode(idToken);

            // Set the token in the API Caller Utility to use in follow up requests
            APICaller.setToken(token);

            // Search for the given user in the system DB. If there's no user reported, an error page must be displayed to allow users the detail about how to open the request
            retrieveUser(userInfo, token);
        } else {
            // If there is no user param in the URL, the user is trying to access the Home directly. If that is the case, we want to ensure that we call the LL process automatically.
            continueWithLL();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Function that calls the authenticate user endpoing. Due to the LL changes, this endpoint will simply return if the user can be found within the system DB.
    const retrieveUser = async (userInfo, token) => {
        let response = await APICaller.sendRequest('authenticateuser', {
            auth: {
                email_address: userInfo.unique_name
            }
        });

        if (response !== 'User does not exists') {
            // Before we proceed, we want to know if the user account is currently active. If it is, then we proceed with the accessGranted function. If not, then we want to setExist as true, but keep the active as false. View is not updated but done will be set up to true.
            if (response.activeFlag === 1) {
                accessGranted(response, userInfo, token);
            } else {
                setDone(true);
                setExist(true);
            }
        } else {
            setDone(true);
        }
    };

    // Function to be called once the access has been granted thanks to a success response
    const accessGranted = async (user, userInfo, token) => {
        // Add the right role based on the information that we retrieve
        user.role = await retrieveRoleDetails(user.id);

        // Ensure that the user is considered as authenticated
        user.authenticated = true;

        // Add the sessionIndex and the name_id to the user object
        // user.sessionIndex = userInfo.session_index;
        user.nameId = userInfo.unique_name;
        user.token = token;

        // Update the user state with the response information
        dispatch(authentication(user));

        // Before we start rendering the site, we want to update the lastLogin status of this specific user. Because this is happening at this level, there's no need to update the redux state, as we might have multiple users on the site at the same time.
        await APICaller.sendRequest('updateuser', {
            update_user_data: {
                user_id: user.id
            },
            action: 'login'
        });

        // Allow render of the site
        setView(<Landing goTo={goTo} />);
        setDone(true);
        setExist(true);
        setActive(true);
    };

    // window.dataLayer.push({
    // 	event: 'pageview'
    // });

    // Function to be used to receive notifications from the Universe Cart once a Universe Profile is saved. The function is then call with a timeout (which will be configurable per number of dimensions). This function will handle then calling the get Universe by ID endpoint after the set time, and then push the notification to the users by means of the snackbar.
    const sendNotification = async (universeId, universeName, projectName, notification, type) => {
        const worker = new Worker('./universeWorker.js');

        // We post a message to the worker with the important information that we will need to constantly call the service per worker. The worker will simply return the same information onmessage to continue with the call.
        worker.postMessage({
            universeId,
            universeName,
            projectName,
            notification,
            type
        });

        // Once we receive a message from the worker, 10 seconds has passed and we should retrieve the data again from the service. If we get a response back, we can terminate the worker. If not, then we send a message again to the worker with the same data to continue.
        worker.onmessage = async e => {
            let universe = await APICaller.sendRequest('getuniversebyid', {
                universe_id: e.data.universeId
            });

            let universeDestinations = await APICaller.sendRequest('getuniversedestinations', {
                universe_id: e.data.universeId
            });

            if (type === 'query') {
                if (
                    universeDestinations[0] &&
                    universeDestinations[0].aggregateS3BucketUrl &&
                    universeDestinations[0].aggregateDbObjectName
                ) {
                    setMessage({
                        text: (
                            <Typography>
                                Your Universe Profile for {e.data.universeName} is now available on
                                Project {e.data.projectName}.
                            </Typography>
                        ),
                        status: 'success'
                    });

                    setOpen(true);

                    // Dispatch a change for the universe redux to let the state know a change has been applied to one of the universes. Once we dispatch the universe, we want to dispatch again an object with an invalid id which is 0, this means this will avoid any re-rendering happening in other universes till we have a new one with a valid id.
                    dispatch(registerUpdate({ id: e.data.universeId }));
                    dispatch(registerUpdate({ id: 0 }));

                    // If the job is done, then we want to update the notifications entry for the item with two updates. To add the completed date and time, plus editing the isNew value to true, so the display will show in the UI.
                    dispatch(
                        updateNotification({
                            id: e.data.universeId,
                            completed: new Date(),
                            isNew: true,
                            type: e.data.type
                        })
                    );

                    // The job is now done, we can proceed to terminate the worker
                    worker.terminate();
                } else {
                    worker.postMessage({
                        universeId: e.data.universeId,
                        universeName: e.data.universeName,
                        projectName: e.data.projectName,
                        notification: e.data.notification,
                        type: e.data.type
                    });
                }
            } else {
                if (
                    universe[0] &&
                    (universe[0].universeClusterDbscanCount > 0 ||
                        universe[0].universeClusterKmeansCount > 0)
                ) {
                    setMessage({
                        text: (
                            <Typography>
                                Your Clusters for {e.data.universeName} are now available on Project{' '}
                                {e.data.projectName}.
                            </Typography>
                        ),
                        status: 'success'
                    });

                    setOpen(true);

                    // Dispatch a change for the universe redux to let the state know a change has been applied to one of the universes. Once we dispatch the universe, we want to dispatch again an object with an invalid id which is 0, this means this will avoid any re-rendering happening in other universes till we have a new one with a valid id.
                    dispatch(registerUpdate(universe[0]));
                    dispatch(registerUpdate({ id: 0 }));

                    // If the job is done, then we want to update the notifications entry for the item with two updates. To add the completed date and time, plus editing the isNew value to true, so the display will show in the UI.
                    dispatch(
                        updateNotification({
                            id: e.data.universeId,
                            completed: new Date(),
                            isNew: true,
                            type: e.data.type
                        })
                    );

                    // The job is now done, we can proceed to terminate the worker
                    worker.terminate();
                } else {
                    worker.postMessage({
                        universeId: e.data.universeId,
                        universeName: e.data.universeName,
                        projectName: e.data.projectName,
                        notification: e.data.notification,
                        type: e.data.type
                    });
                }
            }
        };
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <ThemeProvider theme={theme}>
            {done && (
                <div className='App' version='288dc0b5'>
                    {exist && active && (
                        <Fragment>
                            <Header goTo={goTo} retrieveRoleDetails={retrieveRoleDetails} />
                            <main>
                                <section>
                                    {view}
                                    <Snackbar
                                        open={open}
                                        autoHideDuration={4000}
                                        onClose={handleClose}
                                        TransitionComponent={transition}
                                    >
                                        <Alert
                                            onClose={handleClose}
                                            severity={message.status}
                                            sx={{ width: '100%' }}
                                        >
                                            {message.text}
                                        </Alert>
                                    </Snackbar>
                                </section>
                                <footer>
                                    <Footer goTo={goTo} />
                                </footer>
                            </main>
                        </Fragment>
                    )}
                    {exist && !active && (
                        <Container
                            sx={{
                                textAlign: 'center',
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                display: 'flex',
                                flexFlow: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '70%'
                            }}
                        >
                            <WarningAmberIcon size='large' sx={{ width: '3em', height: '3em' }} />
                            <Typography
                                variant='h1'
                                sx={{ p: 6, fontWeight: 'bold', fontSize: '30px', pt: 4, pb: 4 }}
                            >
                                Welcome!
                            </Typography>
                            <Typography variant='body1' sx={{ fontSize: '19px' }}>
                                It looks like your Insagic Reporting Tool account is no longer active.
                                <br></br>
                                <br></br>
                                Please open a ticket{' '}
                                <a
                                    href='https://issues.publicishealth.com/projects/ARM/issues/ARM-28?filter=allissues'
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    here
                                </a>{' '}
                                to resolve and reactive your account.
                            </Typography>
                        </Container>
                    )}
                    {!exist && !error && (
                        <Container
                            sx={{
                                textAlign: 'center',
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                display: 'flex',
                                flexFlow: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '70%'
                            }}
                        >
                            <LoginSharpIcon size='large' sx={{ width: '3em', height: '3em' }} />
                            <Typography
                                variant='h1'
                                sx={{ p: 6, fontWeight: 'bold', fontSize: '30px', pt: 4, pb: 4 }}
                            >
                                Welcome!
                            </Typography>
                            <Typography variant='body1' sx={{ fontSize: '19px' }}>
                                It looks this is your first-time logging into the Insagic Reporting Tool.
                                <br></br>
                                <br></br>
                                Please open a ticket{' '}
                                <a
                                    href='https://issues.publicishealth.com/projects/ARM/issues/ARM-28?filter=allissues'
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    here
                                </a>{' '}
                                to request access to the platform. Once your ticket has been closed
                                and access granted, you can come back to this website to view the
                                site.
                            </Typography>
                        </Container>
                    )}
                </div>
            )}
            {!done && (
                <main>
                    <Container
                        sx={{
                            textAlign: 'center',
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            display: 'flex',
                            flexFlow: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '70%'
                        }}
                    >
                        <Typography variant='h1' sx={{ p: 6, fontSize: '3rem', pt: 4, pb: 4 }}>
                            Welcome to the Insagic Reporting Tool!
                        </Typography>
                        <Box sx={{ width: '100%' }}>
                            <Progress />
                        </Box>
                    </Container>
                </main>
            )}
            {error && (
                <main>
                    <Container
                        sx={{
                            textAlign: 'center',
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            display: 'flex',
                            flexFlow: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '70%'
                        }}
                    >
                        <WarningAmberIcon size='large' sx={{ width: '3em', height: '3em' }} />
                        <Typography
                            variant='h1'
                            sx={{ p: 6, fontWeight: 'bold', fontSize: '30px', pt: 4, pb: 4 }}
                        >
                            An error has ocurred while trying to load the website.
                        </Typography>
                        <Typography variant='body1' sx={{ fontSize: '19px' }}>
                            It looks like there was an error while trying to load the LionLogin
                            authentication. We apologize for this inconvenience.
                            <br></br>
                            <br></br>
                            The error reported by our systems is: {errMessage}.
                        </Typography>
                    </Container>
                </main>
            )}
        </ThemeProvider>
    );
}
