import {
    BUILDQUERY,
    BUILDOPTIONLIST,
    UPDATEQUERY,
    UPDATEOPTIONLIST,
    UPDATECUSTOMQUERY,
    UPDATEUSECUSTOM,
    UPDATEDEMOGRAPHICS,
    BUILDDIAGNOSISDATA,
    UPDATEDIAGNOSIS,
    BUILDPROCEDURESDATA,
    UPDATEPROCEDURES,
    BUILDPRESCRIPTIONSDATA,
    UPDATEPRESCRIPTIONS,
    BUILDSPECIALISTSDATA,
    UPDATESPECIALISTS
} from './queryActionTypes';

export const buildQuery = query => ({
    type: BUILDQUERY,
    payload: query
});

export const buildOptionList = optionList => ({
    type: BUILDOPTIONLIST,
    payload: optionList
});

export const updateQuery = query => ({
    type: UPDATEQUERY,
    payload: query
});

export const updateCustomquery = customQuery => ({
    type: UPDATECUSTOMQUERY,
    payload: customQuery
});

export const updateUseCustom = useCustom => ({
    type: UPDATEUSECUSTOM,
    payload: useCustom
});

export const updateOptionList = optionList => ({
    type: UPDATEOPTIONLIST,
    payload: optionList
});

export const updateDemographics = demographics => ({
    type: UPDATEDEMOGRAPHICS,
    payload: {
        demographics
    }
});

export const buildDiagnosisData = diagnosisData => ({
    type: BUILDDIAGNOSISDATA,
    payload: diagnosisData
});

export const updateDiagnosis = diagnosis => ({
    type: UPDATEDIAGNOSIS,
    payload: {
        diagnosis
    }
});

export const buildProceduresData = proceduresData => ({
    type: BUILDPROCEDURESDATA,
    payload: proceduresData
});

export const updateProcedures = procedures => ({
    type: UPDATEPROCEDURES,
    payload: {
        procedures
    }
});

export const buildPrescriptionsData = prescriptionsData => ({
    type: BUILDPRESCRIPTIONSDATA,
    payload: prescriptionsData
});

export const updatePrescriptions = prescriptions => ({
    type: UPDATEPRESCRIPTIONS,
    payload: {
        prescriptions
    }
});

export const buildSpecialistsData = specialistsData => ({
    type: BUILDSPECIALISTSDATA,
    payload: specialistsData
});

export const updateSpecialists = specialists => ({
    type: UPDATESPECIALISTS,
    payload: {
        specialists
    }
});
