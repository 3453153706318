import {
    CREATEUNIVERSE,
    UPDATEUNIVERSE,
    UPDATEUNIVERSELIST,
    REGISTERUPDATE
} from '../actions/universe/universeActionTypes';

const initialState = {
    current: {},
    updatedUniverse: {},
    universeList: []
};

export default function universe(state = initialState, action) {
    switch (action.type) {
        case CREATEUNIVERSE: {
            return {
                ...state,
                current: action.payload
            };
        }
        case UPDATEUNIVERSELIST: {
            return {
                ...state,
                universeList: action.payload.sort((a, b) => (a.id > b.id ? 1 : -1))
            };
        }
        case UPDATEUNIVERSE: {
            return {
                ...state,
                current: action.payload
            };
        }
        case REGISTERUPDATE: {
            return {
                ...state,
                updatedUniverse: action.payload
            };
        }
        default:
            return state;
    }
}
