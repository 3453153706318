// React
import React, { useEffect, Fragment, useState } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { updateDiagnosis } from '../../redux/actions/query/queryActions';

// Materialize Includes for Actions
import { Box } from '@mui/system';
import {
    Button,
    FormControlLabel,
    FormControl,
    Container,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Slide,
    TextField,
    Checkbox,
    Skeleton,
    Backdrop,
    CircularProgress,
    Card,
    CardActions,
    CardContent,
    Select,
    MenuItem,
    InputLabel,
    Divider
} from '@mui/material';
import { HtmlTooltip } from '../../assets/custom-mui';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { LightTooltip } from '../../assets/custom-mui';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

export default function Diagnosis(props) {
    const dispatch = useDispatch();

    // optionList is a variable that we only care about in the context of this component. There is no need to add this to the state, so we handle this through hooks of the component
    const query = useSelector(state => state.query);

    // The functionalities of the Modal to choose a dimension can be handled through the component state versus the store state, as we have no need to store this elsewhere.
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchExecuted, setSearchExecuted] = useState(false);
    const [searchResult, setSearchResult] = useState([]);
    const [checkResults, setCheckResults] = useState([]);
    const [searchInput, setSearchInput] = useState([]);
    const [userSelection, setUserSelection] = useState([]);
    const [currentGroup, setCurrentGroup] = useState({
        group: {
            type: 'inclusion',
            dimension: []
        },
        index: 0,
        type: 'add'
    });

    useEffect(() => {
        // Clear the checkResults array
        setCheckResults([]);

        if (query.diagnosisData) {
            setLoading(false);
        }
    }, [query.diagnosisData]);

    const handleClickOpen = (group, index, type, dimensionIndex = null) => {
        // Reset the search value
        setSearchExecuted(false);
        setCheckResults([]);

        const currentGroup = {
            group,
            index,
            type
        };

        if (type === 'edit') {
            // When we are editing a dimension, we want to set up the search to already show up with what we previously selected, as what as the selected text input. We also set up the dimensionIndex in currentGroup so we can use to update the dimension afterwards
            setSearchInput('');

            // We want to simply pass the ID array of the selection to both the userSelection and checkResults when editing. But depending on when this is called, we might have either just IDS on the results or actual IDs. We create a new array that will only include IDS and we will use this in all use cases
            let resultArray;
            if (currentGroup.group.dimension[dimensionIndex].results.find(r => !r.id)) {
                resultArray = currentGroup.group.dimension[dimensionIndex].results;
            } else {
                resultArray = currentGroup.group.dimension[dimensionIndex].results.map(r => r.id);
            }
            setUserSelection(resultArray);
            setCheckResults(resultArray);
            currentGroup.group.dimensionIndex = dimensionIndex;
        }

        setCurrentGroup(currentGroup);

        setOpen(true);
    };

    const handleClose = () => {
        // Clear all the state component values to their original value
        setSearchResult([]);
        setCheckResults([]);
        setSearchInput('');
        setUserSelection([]);
        setOpen(false);
    };

    // Function that will keep track of the search input text the moment we do changes
    const handleSearchChange = event => {
        setSearchInput(event.target.value);
    };

    // Function that will search the parameter of the text input in the current query.diagnosisData array, returning all elements that have a similar code or word in the description
    const searchDimension = searchInput => {
        setSearchExecuted(true);
        setLoading(true);

        // Create one variable to hold the filter array to avoid changing the original one
        let resultsArray = [];

        // Search results by code
        resultsArray = [
            ...resultsArray,
            ...query.diagnosisData.filter(item => item.code.indexOf(searchInput.toUpperCase()) > -1)
        ];

        // Search results by description
        resultsArray = [
            ...resultsArray,
            ...query.diagnosisData.filter(
                item => item.description.toLowerCase().indexOf(searchInput.toLowerCase()) > -1
            )
        ];

        setSearchResult(resultsArray);
        setLoading(false);
    };

    // Function to select all the results that we have displayed on the UI
    const selectAllOptions = () => {
        // Use an array to hold all the elements that we will be adding to the User Selection once done
        let resultAll = [];
        const updatedCheckResult = [...checkResults];

        // Add all the results. To the same process of pushing into the checkResults and adding the user Selection as well
        searchResult.forEach(searchElement => {
            // We only proceed wih the next code if the current id is not already included in the userSelection list
            if (!updatedCheckResult.find(is => is === searchElement.id)) {
                updatedCheckResult.push(searchElement.id);
                resultAll.push({
                    id: searchElement.id,
                    code: query.diagnosisData.find(d => d.id === searchElement.id).code,
                    description: query.diagnosisData.find(d => d.id === searchElement.id)
                        .description,
                    icd: query.diagnosisData.find(d => d.id === searchElement.id).icd
                });
            }
        });

        // Update the User Selection State with the results
        setUserSelection([...userSelection, ...resultAll]);
        setCheckResults(updatedCheckResult);
    };

    // Function to unselect all current checked options from the UI
    const selectNoneOptions = () => {
        // This will clear the checkboxes from the UI
        setCheckResults([]);

        // Then we need to also clear the userSelection to an empty array
        setUserSelection([]);
    };

    // Function that will update the selection dimension from the options checked by the user. We can also add the dimensions dynamically by passing an id directly.
    const addDimensionSelection = event => {
        let isChecked = event.target.checked;
        let searchId = parseInt(event.target.id);
        const updatedCheckResult = [...checkResults];

        if (isChecked) {
            // Add the result to the array of results
            updatedCheckResult.push(searchId);

            setUserSelection([
                ...userSelection,
                {
                    id: searchId,
                    code: query.diagnosisData.find(d => d.id === searchId).code,
                    description: query.diagnosisData.find(d => d.id === searchId).description,
                    icd: query.diagnosisData.find(d => d.id === searchId).icd
                }
            ]);
        } else {
            // Remove the result from the array
            updatedCheckResult.splice(
                updatedCheckResult.findIndex(rI => rI === searchId),
                1
            );

            // Check if the current User Selection is using IDs or full objects. This might vary depending of us editing an existing dimension or creating a new one.
            if (userSelection.find(i => !i.id)) {
                setUserSelection(
                    userSelection.filter(function (el) {
                        return el !== searchId;
                    })
                );
            } else {
                setUserSelection(
                    userSelection.filter(function (el) {
                        return el.id !== searchId;
                    })
                );
            }
        }

        setCheckResults(updatedCheckResult);
    };

    // Once we save the dimensions, we need to go back to work with the store state, vs component, as we do want to link the selections to an actual group to render it outside the dialog. When we save dimensions, we want to save the array with all the data included, plus the search that we use to get that array.

    // For editing a dimension, we only need to pass the groupIndex, so we can call the same function but just have a difference on how to work with the data. Reason why is because we already have the currentGroup value in the component state, which we can use to understand which dimension we are currently editing and with what.

    const saveDimensions = (groupIndex, type) => {
        setCheckResults([]);
        props.registerChanges();

        // Work directly with the query value. This will not change the state, but it is easier to ensure that we are not losing any of the other diagnosis values that we might already save.
        if (type === 'add') {
            // When adding, we simply need to add one more value into the array.
            query.diagnosis[groupIndex].dimension = [
                ...query.diagnosis[groupIndex].dimension,
                {
                    results: userSelection,
                    searchValue: searchInput,
                    operator: 'or'
                }
            ];
        } else {
            // When updating the dimension, we want to only update the dimension we already have in place, using the currentGroup value to help ourselves in understand what to update
            query.diagnosis[groupIndex].dimension[currentGroup.group.dimensionIndex] = {
                results: userSelection,
                searchValue: searchInput,
                operator:
                    query.diagnosis[groupIndex].dimension[currentGroup.group.dimensionIndex]
                        .operator
            };
        }

        // Once the updated Group is ready, update the state of diagnosis with the new query value
        dispatch(updateDiagnosis(query.diagnosis));

        // Clear all the state component values to their original value
        setSearchResult([]);
        setSearchInput('');
        setUserSelection([]);

        setOpen(false);
    };

    const removeDimension = (groupIndex, dimensionIndex) => {
        props.registerChanges();
        query.diagnosis[groupIndex].dimension.splice(dimensionIndex, 1);

        dispatch(updateDiagnosis(query.diagnosis));
    };

    // Function to change the Operator of a dimension
    const handleOperatorChange = (groupIndex, dimensionIndex, e) => {
        query.diagnosis[groupIndex].dimension[dimensionIndex].operator = e.target.value;
        dispatch(updateDiagnosis(query.diagnosis));
    };

    // Cards required functions

    const toTitleCase = str => {
        return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    };

    return (
        <Box sx={{ mt: 4 }}>
            <Typography variant='h3' sx={{ fontSize: '1.5rem' }} gutterBottom component='div'>
                Diagnosis Dimensions
            </Typography>

            {query.diagnosis &&
                query.diagnosis.length > 0 &&
                query.diagnosis.map((group, index) => (
                    <div key={index} style={{ marginTop: '32px' }}>
                        <Typography variant='h6' gutterBottom component='div'>
                            {toTitleCase(group.type)} Group
                            <HtmlTooltip
                                title={
                                    <Fragment>
                                        <Typography color='inherit' sx={{}}>
                                            The set of billing codes and diagnosis information that{' '}
                                            {group.type === 'inclusion' ? 'includes' : 'excludes'} a
                                            patient in the universe.
                                        </Typography>
                                    </Fragment>
                                }
                            >
                                <IconButton>
                                    <HelpOutlineIcon
                                        sx={{
                                            fontSize: 18,
                                            paddingTop: 0
                                        }}
                                    />
                                </IconButton>
                            </HtmlTooltip>
                            {/* <Button size="small" endIcon={<DeleteIcon />} variant="contained" sx={{ fontSize: "11px", ml: "8px" }} color="error" onClick={() => { handleClickOpen(group, index, 'add') }}>Delete</Button> */}
                        </Typography>
                        <Fragment>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    marginBottom: '16px',
                                    flexFlow: 'column',
                                    rowGap: '8px'
                                }}
                            >
                                <Button
                                    style={{ textTransform: 'inherit' }}
                                    size='small'
                                    variant='outlined'
                                    onClick={() => {
                                        handleClickOpen(group, index, 'add');
                                    }}
                                    endIcon={<AddIcon />}
                                >
                                    Add New Dimension(s)
                                </Button>
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    columnGap: '8px',
                                    flexWrap: 'wrap',
                                    rowGap: '8px'
                                }}
                            >
                                {group.dimension.map((dimension, jindex) => (
                                    // What we get on the map is just an ID, to simply matters when adding the query. So we want to get the details of the dimension with the ID
                                    <Fragment>
                                        <Card
                                            key={jindex}
                                            variant='outlined'
                                            sx={{
                                                width: '400px',
                                                position: 'relative',
                                                pb: '50px'
                                            }}
                                        >
                                            <CardContent>
                                                <Typography
                                                    sx={{ fontSize: 14, mb: 1.5 }}
                                                    color='text.secondary'
                                                    gutterBottom
                                                >
                                                    Dimension(s) from{' '}
                                                    <strong>"{dimension.searchValue}"</strong> query
                                                </Typography>
                                                {dimension.results.length > 0 && (
                                                    <Fragment>
                                                        {dimension.results.map((result, index) => {
                                                            // When we are updating a universe, we will not have any code or description values for the results. We will only have the ID. So for this situations, we need to retrieve the details from the list of diagnosisData.
                                                            if (!result.code) {
                                                                if (query.diagnosisData) {
                                                                    result =
                                                                        query.diagnosisData.find(
                                                                            r => r.id === result
                                                                        );
                                                                    return (
                                                                        <Typography
                                                                            variant='body2'
                                                                            key={index}
                                                                            style={{
                                                                                marginBottom: '0px'
                                                                            }}
                                                                        >
                                                                            <strong>
                                                                                ICD{result.icd} |{' '}
                                                                                {result.code}
                                                                            </strong>{' '}
                                                                            |{' '}
                                                                            {toTitleCase(
                                                                                result.description
                                                                            )}
                                                                        </Typography>
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <Skeleton
                                                                            key={index}
                                                                            variant='text'
                                                                            animation='pulse'
                                                                            sx={{
                                                                                width: 1,
                                                                                height: '20px'
                                                                            }}
                                                                        ></Skeleton>
                                                                    );
                                                                }
                                                            } else {
                                                                return (
                                                                    <Typography
                                                                        variant='body2'
                                                                        key={index}
                                                                        style={{
                                                                            marginBottom: '0px'
                                                                        }}
                                                                    >
                                                                        <strong>
                                                                            ICD{result.icd} |{' '}
                                                                            {result.code}
                                                                        </strong>{' '}
                                                                        |{' '}
                                                                        {toTitleCase(
                                                                            result.description
                                                                        )}
                                                                    </Typography>
                                                                );
                                                            }
                                                        })}
                                                    </Fragment>
                                                )}
                                            </CardContent>
                                            <FormControl
                                                sx={{
                                                    m: 1,
                                                    minWidth: 120,
                                                    position: 'absolute',
                                                    right: 0,
                                                    bottom: 0
                                                }}
                                                size='small'
                                            >
                                                <InputLabel id='demo-simple-select-helper-label'>
                                                    Operator
                                                </InputLabel>
                                                <Select
                                                    labelId='demo-simple-select-helper-label'
                                                    id={'operator-select-' + index + '-' + jindex}
                                                    value={dimension.operator}
                                                    label='Operator'
                                                    onChange={e => {
                                                        handleOperatorChange(index, jindex, e);
                                                    }}
                                                >
                                                    <MenuItem value='and'>And</MenuItem>
                                                    <MenuItem value='or'>Or</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <CardActions
                                                sx={{
                                                    paddingLeft: 0,
                                                    position: 'absolute',
                                                    bottom: 0
                                                }}
                                            >
                                                <Button
                                                    size='small'
                                                    onClick={() => {
                                                        handleClickOpen(
                                                            group,
                                                            index,
                                                            'edit',
                                                            jindex
                                                        );
                                                    }}
                                                    disabled={!query.diagnosisData}
                                                >
                                                    Edit
                                                </Button>{' '}
                                                |
                                                <Button
                                                    size='small'
                                                    onClick={() => {
                                                        removeDimension(index, jindex);
                                                    }}
                                                >
                                                    Remove
                                                </Button>
                                            </CardActions>
                                        </Card>
                                        {group.dimension[jindex + 1] && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <Divider variant='middle'>AND</Divider>
                                            </Box>
                                        )}
                                    </Fragment>
                                ))}
                            </div>
                        </Fragment>
                    </div>
                ))}

            {/* DIALOG COMPONENT FOR ADDING DIMENSIONS */}
            <Fragment>
                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                    aria-labelledby='diagnosis_dialog_label'
                    aria-label='Diagnosis Search Dialog'
                    role='dialog'
                >
                    <nav role='navigation'>
                        <AppBar sx={{ position: 'relative', backgroundColor: '#0c1742' }}>
                            <Toolbar>
                                <IconButton
                                    edge='start'
                                    color='inherit'
                                    onClick={handleClose}
                                    aria-label='close'
                                >
                                    <CloseIcon />
                                </IconButton>
                                <Typography
                                    sx={{ ml: 2, flex: 1, fontSize: '1.25rem' }}
                                    variant='h1'
                                    id='diagnosis_dialog_label'
                                >
                                    {toTitleCase(currentGroup.type)} Diagnosis{' '}
                                    <strong>{currentGroup.group.type}</strong> Dimension(s)
                                </Typography>
                                {currentGroup.type === 'add' && (
                                    <Button
                                        color='secondary'
                                        onClick={() => saveDimensions(currentGroup.index, 'add')}
                                        disabled={!userSelection.length > 0}
                                        variant='outlined'
                                    >
                                        Add Selected Dimensions
                                    </Button>
                                )}
                                {currentGroup.type === 'edit' && (
                                    <Button
                                        color='secondary'
                                        onClick={() => saveDimensions(currentGroup.index, 'edit')}
                                        disabled={!userSelection.length > 0}
                                        variant='outlined'
                                    >
                                        Edit Dimensions
                                    </Button>
                                )}
                            </Toolbar>
                        </AppBar>
                    </nav>
                    <main role='main'>
                        <Container
                            sx={{
                                pt: 5,
                                display: 'flex',
                                justifyContent: 'flex-start'
                            }}
                        >
                            <TextField
                                sx={{ width: 1 / 2 }}
                                id='searchText'
                                label='Search by code or keyword'
                                variant='filled'
                                value={searchInput}
                                onChange={handleSearchChange}
                                inputProps={{
                                    'data-testid': 'test_diagnosis_search'
                                }}
                            />
                            <Button
                                variant='contained'
                                size='large'
                                sx={{ ml: 3 }}
                                onClick={e => searchDimension(searchInput)}
                            >
                                Search
                            </Button>
                        </Container>
                        {loading ? (
                            <Container>
                                <Skeleton
                                    animation='pulse'
                                    sx={{ width: 1, height: '300px', mt: '-50px', mb: '-100' }}
                                ></Skeleton>
                            </Container>
                        ) : (
                            <Fragment>
                                {searchResult.length > 0 && searchResult.length < 350 && (
                                    <Container sx={{ mt: 4, width: 3 / 4, float: 'left' }}>
                                        <Typography
                                            sx={{ weight: 400 }}
                                            variant='h6'
                                            gutterBottom
                                            component='div'
                                        >
                                            Matching Results{' '}
                                            <span style={{ fontSize: '70%' }}>
                                                (Results: {searchResult.length})
                                            </span>
                                        </Typography>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                columnGap: '8px',
                                                margin: '16px 0px 16px 0px'
                                            }}
                                        >
                                            <Button variant='outlined' onClick={selectAllOptions}>
                                                Select All
                                            </Button>
                                            <Button
                                                variant='outlined'
                                                onClick={selectNoneOptions}
                                                disabled={checkResults.length === 0}
                                            >
                                                Select None
                                            </Button>
                                        </div>
                                        <FormControl
                                            sx={{ width: 1 }}
                                            component='fieldset'
                                            variant='standard'
                                        >
                                            <Fragment>
                                                {searchResult.map((searchItem, index) => {
                                                    // If there's no ID, this means that we are editing this from a rendered edited universe. Then we need to retrieve the right code and description from the diagnosisData
                                                    if (!searchItem.id) {
                                                        searchItem = query.diagnosisData.find(
                                                            dItem => dItem.id === searchItem
                                                        );
                                                    }
                                                    return (
                                                        <FormControlLabel
                                                            key={index}
                                                            control={
                                                                <Checkbox
                                                                    onChange={addDimensionSelection}
                                                                    name={searchItem.id.toString()}
                                                                    category='diagnosisSearchItem'
                                                                    key={
                                                                        'diagnosisSearchItem_' +
                                                                        searchItem.id.toString()
                                                                    }
                                                                    id={searchItem.id.toString()}
                                                                    checked={checkResults.includes(
                                                                        searchItem.id
                                                                    )}
                                                                    inputProps={{
                                                                        'aria-labelledby':
                                                                            searchItem.description,
                                                                        'data-testid': `test_diagnosis_${searchItem.code}`
                                                                    }}
                                                                />
                                                            }
                                                            label={
                                                                'ICD' +
                                                                searchItem.icd +
                                                                ' | ' +
                                                                searchItem.code +
                                                                ' | ' +
                                                                toTitleCase(searchItem.description)
                                                            }
                                                        />
                                                    );
                                                })}
                                            </Fragment>
                                        </FormControl>
                                    </Container>
                                )}

                                {searchResult.length > 350 && (
                                    <Container sx={{ mt: 4, width: 3 / 4, float: 'left' }}>
                                        <Typography variant='body1'>
                                            Your current search has returned {searchResult.length}{' '}
                                            results and exceeds the maximum of <strong>350</strong>.
                                            Please try a more specific search input.
                                        </Typography>
                                    </Container>
                                )}

                                {searchExecuted && searchResult.length === 0 && (
                                    <Container sx={{ mt: 4, width: 3 / 4, float: 'left' }}>
                                        <Typography variant='body1'>
                                            There are no diagnosis results with your current search.
                                        </Typography>
                                    </Container>
                                )}

                                <Container sx={{ mt: 4, width: 1 / 4, float: 'right' }}>
                                    <Typography
                                        sx={{ weight: 400 }}
                                        variant='h6'
                                        gutterBottom
                                        component='div'
                                        data-testid='test_items_total_container'
                                    >
                                        Current Selection{' '}
                                        <span style={{ fontSize: '70%' }}>
                                            (Items: {checkResults.length})
                                        </span>
                                    </Typography>

                                    <Box>
                                        <ul style={{ paddingLeft: '16px' }}>
                                            {checkResults.map(r => {
                                                try {
                                                    // Retrieving the actual value of R, not just the ID, but the actual object so we can render both code and description
                                                    r = query.diagnosisData.find(
                                                        dataItem => dataItem.id === r
                                                    );

                                                    // Adding lowercase in all the words so we can then capitalize through CSS on styling.
                                                    r.description = r.description.toLowerCase();

                                                    return (
                                                        <LightTooltip title={r.description}>
                                                            <li
                                                                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                                                                tabIndex='0'
                                                                style={{
                                                                    marginBottom: '8px',
                                                                    textTransform: 'capitalize'
                                                                }}
                                                            >
                                                                {r.code +
                                                                    ' | ' +
                                                                    r.description
                                                                        .split(' ')
                                                                        .slice(0, 3)
                                                                        .join(' ')}
                                                                {r.description.split(' ').length >
                                                                    3 && <span>...</span>}
                                                            </li>
                                                        </LightTooltip>
                                                    );
                                                } catch (error) {
                                                    console.error(error);
                                                }
                                            })}
                                        </ul>
                                    </Box>
                                </Container>
                            </Fragment>
                        )}

                        <Backdrop
                            sx={{
                                color: '#fff',
                                zIndex: theme => theme.zIndex.drawer + 1,
                                display: 'flex',
                                flexFlow: 'column',
                                backgroundColor: 'rgba(0, 0, 0, 0.8)'
                            }}
                            open={!query.diagnosisData}
                        >
                            <CircularProgress color='inherit' />
                            <Typography variant='h5' sx={{ mt: 2, fontWeight: 800 }}>
                                Retrieving all the diagnosis list.
                            </Typography>
                        </Backdrop>
                    </main>
                </Dialog>
            </Fragment>
        </Box>
    );
}
