import { CHOOSE, GETCLIENT } from '../actions/client/clientActionTypes';

const initialState = {};

export default function client(state = initialState, action) {
    switch (action.type) {
        case CHOOSE: {
            return action.payload;
        }
        case GETCLIENT: {
            return action.payload;
        }
        default:
            return state;
    }
}
