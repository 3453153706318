// MUI Includes
import { Typography, Box } from '@mui/material';

// Logo Include
import logo from '../../assets/img/logos/Primary_Insagic-Logo-Tagline.png';

export default function Segmentation() {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                pt: 7,
                pb: 3
            }}
        >
            <Box
                sx={{
                    width: 1 / 2,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexFlow: 'column'
                }}
            >
                <img
                    src={logo}
                    alt='Insagic Platform Logo'
                    style={{ width: 'auto', height: '110px', marginBottom: '32px' }}
                ></img>

                <Typography variant='h1' sx={{ fontSize: '2.5rem', fontWeight: '400' }}>
                    Profiling
                </Typography>

                <Typography variant='body1'>
                    <p>
                        The Insagic Reporting Tool was developed for the Insagic Data Science team.
                        It enables the creation and analysis of patient segmentations in an
                        efficient and automated workflow. This system allows for the fast turnaround
                        of profiling for client projects, simple segmentations for internal use or
                        pitch-ware, and the centralization of machine learning efforts to
                        de-duplicate work between projects enabling iterative improvement of our own
                        models and methodologies.
                    </p>
                </Typography>
            </Box>
        </Box>
    );
}
