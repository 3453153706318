// Utilities
import axios from 'axios';
import * as env from './EnvironmentVariablesReader';
import LLCaller from './LLCaller';

// Components
import Home from '../Home';

let token;

class APICaller {
    // Set the token one time to continue to use it with follow up requests
    setToken = authToken => {
        token = authToken || '';
    };

    sendRequest = async (path, data, useOld = false) => {
        try {
            let config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };

            let response = await axios.post(`${env.LAMBDA_BASE_URL}/${path}`, data, config);

            return response.data ? response.data.result : [];
        } catch (error) {
            // If the response that we get is Unauthorized, we want to call the LL Caller so we can go through the login process again. This we avoid having the app to load with an invalid token.
            if (error.response.data.message === 'Unauthorized') {
                let response = await LLCaller.sendRequest('login', { location: 'home' });
                window.location.replace(response.data.redirectURL);
            }
            return error;
        }
    };

    clusterRequest = async (path, data) => {
        let url = '';

        url = `${env.WEB_API_BASE_URL}/${path}`;

        let config = {};
        if (path === 'getClusterData' || path === 'getClusterXLS') {
            config = {
                responseType: 'arraybuffer',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
        } else {
            config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
        }

        let response = await axios.post(url, data, config);
        return response.data;
    };
}

export default new APICaller();
