// React
import React, { useState, useEffect, useRef, Fragment } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { updateQuery, buildQuery } from '../../redux/actions/query/queryActions';
import { updateUniverse, updateUniverseList } from '../../redux/actions/universe/universeActions';
import { updateProjectStep, getProject } from '../../redux/actions/project/projectActions';
import { getClient } from '../../redux/actions/client/clientActions';
import {
    updateClusterList,
    updateClusterGroupList,
    setClusterData,
    setModel,
    clearColumns,
    clearSeries,
    clearModelSelection,
    updateKmeansSelection,
    updateDbscanSelection,
    setClusterSelection
} from '../../redux/actions/cluster/clusterActions';

// Utilities
import APICaller from '../../utils/APICaller';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import TextFormatter from '../../utils/TextFormatter';

// Components
import ProjectHeader from '../layout/ProjectHeader';

// Materialize Includes
import {
    Button,
    Grid,
    Container,
    Box,
    Typography,
    Skeleton,
    TextField,
    IconButton,
    Select,
    InputLabel,
    MenuItem,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Slide,
    FormControl,
    Stepper,
    Step,
    StepLabel,
    Alert
} from '@mui/material';

// Import Icons
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import WarningIcon from '@mui/icons-material/Warning';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

// Data Grid Includes
import { alpha, styled } from '@mui/material/styles';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
const ODD_OPACITY = 0.2;

// Require custom Data Grid to handle stripped view capabilities
const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: theme.palette.grey[200],
        '&:hover, &.Mui-hovered': {
            backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
            '@media (hover: none)': {
                backgroundColor: 'transparent'
            }
        }
    }
}));

const SecondaryBtnOnDarkBg = styled(Button)(({ theme }) => ({
    '&.Mui-disabled': {
        background: theme.palette.grey['A400'],
        color: theme.palette.grey['A300']
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

// Steps for Clustering Progress
const steps = ['Creating Clusters', 'Generating Aggregate Data', 'Clustering Completed'];

export default function UniverseOverview(props) {

    // Status Constants
    const STATUS_BEGIN = 'BEGIN';
    const STATUS_END = 'END';
    const STATUS_FAIL = 'FAIL';

    // Refs
    const universeStatusRef = useRef([]);
    const clusteringStatusRef = useRef([]);

    // Component Hooks
    const [loading, setLoading] = useState(true);
    const [clusterLoading, setClusterLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [editUniverse, setEditUniverse] = useState(false);
    // const [editCluster, setEditCluster] = useState(false);
    const [create, setCreate] = useState(false);
    const [useQuery, setUseQuery] = useState(false);
    const [universeEditable, setUniverseEditable] = useState(false);
    const [stepValue, setStepValue] = useState(-1);

    // Cluster Related Hooks
    const [open, setOpen] = useState(false);
    const [clusterEditable, setClusterEditable] = useState([]);
    const [filesReady, setFilesReady] = useState(false);
    const [clusterZip, setClusterZip] = useState(null);
    const [openGroup, setOpenGroup] = useState(false);

    // Redux Hooks
    const dispatch = useDispatch();
    const project = useSelector(state => state.project.current);
    const universe = useSelector(state => state.universe.current);
    const updatedUniverse = useSelector(state => state.universe.updatedUniverse);
    const universes = useSelector(state => state.universe.universeList);
    const clusters = useSelector(state => state.cluster.clusterList);
    const clusterGroups = useSelector(state => state.cluster.clusterGroupList);
    const optionList = useSelector(state => state.query.optionList);
    const user = useSelector(state => state.user);

    // Button hooks
    const [enableAddClustersBtn, setEnableAddClustersBtn] = useState(false);
    const [enableEditClustersBtn, setEnableEditClustersBtn] = useState(false);
    const [enableEditUniverseBtn, setEnableEditUniverseBtn] = useState(false);
    const [enableDownloadUniverseBtn, setEnableDownloadUniverseBtn] = useState(false);

    useEffect(() => {
        // If the updatedUniverse is not an empty object, then we want to evaluate if the id matches the Id of the
        // currently opened universe. If it does, we want to continue with the rest of the code. If not, we want to
        // avoid doing any of the functions below.
        if (updatedUniverse.id) {
            if (updatedUniverse.id !== universe.id) {
                return;
            }
        }

        // Clear all the redux values that we might have stored from a previous cluster comparison, to avoid issues
        dispatch(clearColumns());
        dispatch(clearSeries());
        dispatch(clearModelSelection());

        // Clear the cluster list and cluster group list to avoid universes showing a previous list
        // dispatch(updateClusterList([]));

        let mounted = true;

        // Set up the data required to retrieve project using the props ID value passed when calling the component
        let data = {
            universe_id: props.id
        };

        // When we open the universe overview, even if we don't want to fetch the data, we always want to update the
        // last view endpoint so we can register this properly on the dashboard
        APICaller.sendRequest('setuniverselastview', {
            universe_id: parseInt(props.id),
            user_id: user.id
        });

        const fetchClusterData = async () => {
            const clustersAvailable = await APICaller.sendRequest('listuniverseclusters', data);
            dispatch(
                updateClusterGroupList(
                    await APICaller.sendRequest('getclustergroups', {
                        universe_id: parseInt(props.id)
                    })
                )
            );
            dispatch(updateClusterList(clustersAvailable));
            dispatch(setClusterData());

            if (clustersAvailable.length > 0) {
                setClusterZip(await APICaller.clusterRequest('getClusterData', data));
            }

            // Once the cluster data is done, we can set the loading for clusters as false
            setClusterLoading(false);
        };

        const fetchData = async () => {
            // In order to pull the information from a universe, we use two different sources. In one hand, we will be
            // retrieving the basic universe information from the universeList, which means that we can render the
            // universeOverview faster without having to wait for any sort of data. This will update the state for the
            // universe, as we don't need any real-time updates to be reflected here.
            let retrievedUniverse;
            if (universes && universes.length > 0) {
                retrievedUniverse = await APICaller.sendRequest('getuniversebyid', {
                    universe_id: props.id
                });
                dispatch(updateUniverse(await retrievedUniverse[0]));
                setLoading(false);
            }

            let universeDestinations = await APICaller.sendRequest('getuniversedestinations', {
                universe_id: props.id
            });

            // While it is faster to load the universe from the universeslist, there's the use case where we are loading
            // the universe directly from the landing page. This means that there's no universeList available and
            // therefore we should dispatch based on the retrievedUniverse, which we have already pulled.
            if (!universes || universes.length === 0) {
                // In the other hand, we need to know when the CSV is updated, and for this, we do need to send a
                // request. This data is only useful to determine of the value of the setCSVReady component hook.
                // Therefore, a new variable is used specifically for this, called retrievedUniverse.
                retrievedUniverse = await APICaller.sendRequest('getuniversebyid', {
                    universe_id: props.id
                });

                dispatch(updateUniverseList(await APICaller.sendRequest('listprojectuniverses', {
                    project_id: retrievedUniverse[0].projectId
                })));

                // If there's no universe, not only do we need to update the universe based on the data, but we also
                // need to update the redux store for projects, as we don't have that available already.
                let project = await APICaller.sendRequest('getprojectbyid', {
                    project_id: retrievedUniverse[0].projectId
                });
                let client = await APICaller.sendRequest('getclientbyid', {
                    client_id: project.clientId
                });

                dispatch(getClient(client));
                dispatch(getProject(project));
                dispatch(updateUniverse(await retrievedUniverse[0]));
                setLoading(false);
            }

            // Check if there's a files data available for this universe. We check this against the response from the
            // endpoint versus the state, as there might be some delay between updating the store and viewing the
            // correct data. Because this affects both the CSV and XLS, we use the same hook for both. We do this only
            // if there is an actual array element on the destinations
            if (universeDestinations.length > 0) {
                setFilesReady(
                    universeDestinations[0].aggregateDbObjectName &&
                        universeDestinations[0].aggregateS3BucketUrl
                );
            }

            universeStatusRef.current = await fetchStatus('universe_status');
            clusteringStatusRef.current = await fetchStatus('clustering_status');

            // These functions run through the logic checking universeStatus and clusteringStatus to
            // enable/disable/show/hide buttons
            canEditUniverse();
            canDownloadUniverse();
            canAddClusters();
            canEditClusters();

            // Sets value for clustering progress display
            setStepperValue();
        };

        if (mounted) {
            try {
                fetchClusterData();
                fetchData();
            } catch (error) {
                console.error(error);
            }
        }

        // Function that indicates if the user is allowed to edit a universe. This will take into consideration two
        // options. Either the user has the ability to edit all or the user is the owner of the project. We use the
        // same object (universe) to determine if the user can edit both universes and clusters, so we add a variable
        // to handle this and then set it up to both.
        if (!user.role.universe.update) {
            setEditUniverse(false);
        } else {
            if (user.role.universe.update === 'all') {
                setEditUniverse(true);
            } else {
                setEditUniverse(project.creationUserName === user.id);
            }
        }

        // Function that sets a value of true/false to indicate if user can create a new universe
        if (!user.role.universe.create) {
            setCreate(false);
        } else {
            if (user.role.universe.create === 'all') {
                setCreate(true);
            } else {
                setCreate(project.creationUserName === user.id);
            }
        }

        // Code that sets the value of true/false to indicate if user can edit or work with universes
        // Function that sets a value of true/false to indicate if user can create a new universes
        if (!user.role.query.create) {
            setUseQuery(false);
        } else {
            if (user.role.query.create === 'all') {
                setUseQuery(true);
            } else {
                setUseQuery(project.creationUserName === user.id);
            }
        }

        // Update the step on the project header to step 2
        dispatch(updateProjectStep('2'));

        return () => (mounted = false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedUniverse]);

    const fetchStatus = async (type) => {
        const response = await APICaller.sendRequest('statuscheck', {
            universe_id: parseInt(props.id),
            type: type
        });

        if (response) {
            return JSON.parse(response);
        }
    };

    const canEditUniverse = () => {

        // User can edit universe if statuscheck response returns 404 or if universe status is fail or
        // if both universe status and aggregate status are end and clustering statuses are null or
        // if universe status is end and aggregate status is fail
        if (!universeStatusRef.current ||
            universeStatusRef.current?.universeStatus === STATUS_FAIL ||
            (universeStatusRef.current?.universeStatus === STATUS_END &&
                universeStatusRef.current?.aggregateStatus === STATUS_END &&
                ((!clusteringStatusRef.current?.clusteringStatus && !clusteringStatusRef.current?.aggregateStatus) ||
                (clusteringStatusRef.current?.clusteringStatus === STATUS_END && clusteringStatusRef.current?.aggregateStatus === STATUS_END))) ||
            (universeStatusRef.current?.universeStatus === STATUS_END && universeStatusRef.current?.aggregateStatus === STATUS_FAIL)) {
            setEnableEditUniverseBtn(true);
        } else {
            setEnableEditUniverseBtn(false);
        }
    };

    const canDownloadUniverse = () => {

        // User can download Universe XLS if statuscheck response returns universe status and aggregate status end
        if (universeStatusRef.current?.universeStatus === STATUS_END &&
            universeStatusRef.current?.aggregateStatus === STATUS_END) {
            setEnableDownloadUniverseBtn(true);
        } else {
            setEnableDownloadUniverseBtn(false);
        }
    };

    const canAddClusters = () => {

        // User can add clusters if statuscheck response returns universe status and aggregate status end andclustering
        // status and aggregate are null, end/fail, or end/end
        if (universeStatusRef.current?.universeStatus === STATUS_END &&
            universeStatusRef.current?.aggregateStatus === STATUS_END &&
            ((!clusteringStatusRef.current?.clusteringStatus && !clusteringStatusRef.current?.aggregateStatus) ||
                (clusteringStatusRef.current?.clusteringStatus === STATUS_END &&
                    clusteringStatusRef.current?.aggregateStatus === STATUS_FAIL) ||
                (clusteringStatusRef.current?.clusteringStatus === STATUS_END &&
                    clusteringStatusRef.current?.aggregateStatus === STATUS_END))) {
            setEnableAddClustersBtn(true);
        } else {
            setEnableAddClustersBtn(false);
        }
    };

    const canEditClusters = () => {

        // User can edit clusters if clusters length > 0 and statuscheck response returns clustering status is end and
        // aggregate is fail or end
        if (clusteringStatusRef.current?.clusteringStatus === STATUS_END &&
            (clusteringStatusRef.current?.aggregateStatus === STATUS_FAIL ||
                clusteringStatusRef.current?.aggregateStatus === STATUS_END)) {
            setEnableEditClustersBtn(true);
        } else {
            setEnableEditClustersBtn(false);
        }
    };

    // Function to use the arrayBuffer of the universe XLS and generate the file for download
    const downloadXLS = async (model = null) => {
        let universeXLS;
        let fileName;

        if (model) {
            universeXLS = await APICaller.clusterRequest('getClusterXLS', {
                universe_id: `${universe.id}`,
                suffix: '000',
                query: `${model}`
            });
            fileName = `${universe.universeName} ${model} Clusters Data.xlsx`;
        } else {
            universeXLS = await APICaller.clusterRequest('getClusterXLS', {
                universe_id: `${universe.id}`,
                suffix: '000',
                query: ''
            });
            fileName = `${universe.universeName} Aggregate Data.xlsx`;
        }

        var blob = universeXLS;
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, fileName);
        } else {
            var downloadLink = window.document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob([blob]));
            downloadLink.download = fileName;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
    };

    const downloadZip = () => {
        let zip = new JSZip();

        zip.loadAsync(clusterZip).then(zip => {
            zip.generateAsync({ type: 'blob' }).then(function (clusterZip) {
                saveAs(clusterZip, `${universe.universeName} Cluster Model.zip`); // 2) trigger the download
            });
        });
    };

    // Function to handle editing a universe. In order to do so, we need to first retrieve the universe and then
    // proceed to load the Query Builder after updating the state for the query
    const editQuery = () => {
        // If there's no criteriajson in the universe, this means that the user created the universe but didn't make
        // any changes to it. Because this means that in fact the universe is clear of any previously created values,
        // we can simply proceed to call the Query Builder without updating any of the states, which are already cleared.
        if (
            universe.criteriaJson !== '' &&
            JSON.parse(universe.criteriaJson) &&
            JSON.parse(universe.criteriaJson)
        ) {
            // Depending on when we are loading the query, we might have it with the filters array (coming from the
            // QB) or without the filters (coming from the universe data). So we need to consider both ways to retrieve
            // the filters data.
            let universeFilters = JSON.parse(universe.criteriaJson).filters
                ? JSON.parse(universe.criteriaJson).filters
                : JSON.parse(universe.criteriaJson);

            // Once we have the proper criteria JSON object, we need to map this to the state of the different
            // dimensions before loading the Query Builder

            // Map the values for demographics
            let loadedQuery = {
                type: 'update',
                demographics: {
                    age: [...universeFilters.inclusions[0].demographics.age_ranges.values],
                    gender: [...universeFilters.inclusions[0].demographics.genders.values],
                    region: [], // We pass an empty array for regions because we don't get that information back from the service
                    state: [...universeFilters.inclusions[0].demographics.states.values]
                },
                // When adding the diagnosis, procedures, specialist and prescriptions, we will worry about adding just
                // one single dimension, because there is no way to track how many dimensions might have been used in
                // the initial query. So we just store in the state one single dimension for each available group
                // (exclusion and inclusion), passing the right operator. As we don't have information of the
                // searchValue, we keep searchValue as 'Loaded Search' for now.
                diagnosis: [
                    {
                        type: 'inclusion',
                        dimension: []
                    },
                    {
                        type: 'exclusion',
                        dimension: []
                    }
                ],
                procedures: [
                    {
                        type: 'inclusion',
                        dimension: []
                    },
                    {
                        type: 'exclusion',
                        dimension: []
                    }
                ],
                prescriptions: [
                    {
                        type: 'inclusion',
                        dimension: []
                    },
                    {
                        type: 'exclusion',
                        dimension: []
                    }
                ],
                specialists: [
                    {
                        type: 'inclusion',
                        dimension: []
                    },
                    {
                        type: 'exclusion',
                        dimension: []
                    }
                ]
            };

            // Once we are done with the basic demographics, we need to evaluate the states and region array. While the
            // region is not retrieved from the service, we do need to know if any of the regions should show up as
            // completed, therefore added into the state array
            if (optionList) {
                optionList.listregions.forEach(region => {
                    if (
                        region.id !== 0 &&
                        region.states.every(s => loadedQuery.demographics.state.includes(s.id))
                    ) {
                        loadedQuery.demographics.region.push(region.id);
                    }
                });
            }

            // We want to ensure that we are only adding elements to the dimension array whenever we do have either AND
            // or OR array values for the given dimension. So we value this with a forEach with each name
            let dimensionArray = [
                {
                    index: 1,
                    name: 'diagnosis'
                },
                {
                    index: 2,
                    name: 'procedures'
                },
                {
                    index: 3,
                    name: 'prescriptions'
                },
                {
                    index: 4,
                    name: 'specialists'
                }
            ];

            dimensionArray.forEach(dimension => {
                // Add a tracking variable to know if we should update as well as an object to track the AND and OR
                // values arrays separately between inclusions and exclusions
                let dataDimension =
                    dimension.name === 'specialists' ? 'visit_specialty' : dimension.name;
                let dimensionOperators = {
                    inclusions: {
                        and: [
                            ...universeFilters.inclusions[dimension.index][dataDimension].AND
                                .and_values
                        ],
                        or: [
                            ...universeFilters.inclusions[dimension.index][dataDimension].OR
                                .or_values
                        ]
                    },
                    exclusions: {
                        and: [
                            ...universeFilters.exclusions[dimension.index][dataDimension].AND
                                .and_values
                        ],
                        or: [
                            ...universeFilters.exclusions[dimension.index][dataDimension].OR
                                .or_values
                        ]
                    }
                };

                let typesArray = [
                    {
                        index: 0,
                        name: 'inclusions'
                    },
                    {
                        index: 1,
                        name: 'exclusions'
                    }
                ];

                // Add one dimension per operator only if there are values, first with the inclusions and then with
                // exclusions through a for each. At the end of this two for each, we should have the loadedQuery fully
                // populated for all the use cases that we have for operators and dimensions
                typesArray.forEach(type => {
                    ['and', 'or'].forEach(operator => {
                        if (dimensionOperators[type.name][operator].length > 0) {
                            loadedQuery[dimension.name][type.index].dimension.push({
                                results: [...dimensionOperators[type.name][operator]],
                                searchValue: 'Loaded Search',
                                operator: operator
                            });
                        }
                    });
                });
            });

            // Dispatch the change on the query to display whatever we retrieved from the system
            dispatch(updateQuery(loadedQuery));

            // Dispatch a change to the universe so we can keep track of the ID that we need for the Query Builder
            // dispatch(createCluster(universe));
        } else {
            dispatch(
                buildQuery({
                    type: 'add', // We are going to add a key for type just to keep track if this is a new query or an updated one, which will help us define which endpoints to call on universe as well as some UI changes
                    demographics: {
                        age: [0],
                        gender: [0],
                        region: [0],
                        state: []
                    },
                    // The diagnosis is an array of groups, each with their own values and operators
                    diagnosis: [
                        {
                            type: 'inclusion',
                            dimension: []
                        },
                        {
                            type: 'exclusion',
                            dimension: []
                        }
                    ],
                    // The prescriptions is an array of groups, each with their own values and operators
                    prescriptions: [
                        {
                            type: 'inclusion',
                            dimension: []
                        },
                        {
                            type: 'exclusion',
                            dimension: []
                        }
                    ],
                    procedures: [
                        {
                            type: 'inclusion',
                            dimension: []
                        },
                        {
                            type: 'exclusion',
                            dimension: []
                        }
                    ],
                    specialists: [
                        {
                            type: 'inclusion',
                            dimension: []
                        },
                        {
                            type: 'exclusion',
                            dimension: []
                        }
                    ]
                })
            );

            // dispatch(createUniverse(universe));
        }

        // Take users to the Query Builder
        props.goTo('query_builder');
    };

    const setStepperValue = () => {

        // Control where on the clustering step we are, based on the values from before. Set creating clusters step
        // as in progress.
        if (clusteringStatusRef.current?.clusteringStatus === STATUS_BEGIN) {
            setStepValue(0);
        }

        // We assume that if clustering creation has ended successfully that data aggregation is in progress even
        // though there's a brief period when aggregate status may still be null. Set generating aggregate data
        // step as in progress.
        if (clusteringStatusRef.current?.clusteringStatus === STATUS_END &&
            clusteringStatusRef.current?.aggregateStatus !== STATUS_END) {
            setStepValue(1);
        }

        // If both clustering creation and data aggregation have succeeded set step value to number beyond the
        // steps visible so all display with completed checkmark.
        if (clusteringStatusRef.current?.clusteringStatus === STATUS_END &&
            clusteringStatusRef.current?.aggregateStatus === STATUS_END) {
            setStepValue(4);
        }
    };

    const updateUniverseInfo = async e => {
        e.preventDefault();
        setUpdating(true);
        setUniverseEditable(false);

        // Update Universe Current State with the values from the Edit. Because there are only two possible changes,
        // either the name of the sourceID, we will proceed to update them here.
        await dispatch(
            updateUniverse({
                ...universe,
                universeName: await TextFormatter.formatText(e.target.universeName.value),
                universeDescription: await TextFormatter.formatText(
                    e.target.universeDescription.value
                )
            })
        );

        // Update the Universe List with the new name and description
        const newUniverseList = [...universes];
        newUniverseList.find(u => u.id === universe.id).universeName = e.target.universeName.value;
        newUniverseList.find(u => u.id === universe.id).universeDescription =
            e.target.universeDescription.value;

        await dispatch(updateUniverseList(newUniverseList));

        let update_universe_metadata = {
            universe_id: universe.id,
            universe_name: await TextFormatter.formatText(e.target.universeName.value),
            universe_description: await TextFormatter.formatText(
                e.target.universeDescription.value
            ),
            criteria: '',
            criteria_json: JSON.parse(universe.criteriaJson),
            user_id: user.id
        };
        try {
            await APICaller.sendRequest('updateuniverse', { update_universe_metadata });
        } catch (error) {
            console.error(error);
        }
        setUpdating(false);
    };

    // Function that will receive a clusterID and return a clusterName based on the list retrieved by the data.
    const getClusterGroupName = clusterGroupId => {
        if (!clusterGroups.find(c => c.id === clusterGroupId)) {
            return 'Error';
        } else {
            return clusterGroups.find(c => c.id === clusterGroupId).clusterGroupName;
        }
    };

    // Function that will return if a given step has failed
    const isStepFailed = step => {
        if (step === 0 && clusteringStatusRef.current?.clusteringStatus === STATUS_FAIL) {
            return true;
        }

        if (step === 1 && clusteringStatusRef.current?.aggregateStatus === STATUS_FAIL) {
            return true;
        }

        return false;
    };

    // Function that will open the page to the wizard to create a cluster
    const createCluster = e => {
        e.preventDefault();
        props.goTo('cluster_creation');
    };

    // Function that will display a dialog to create a new cluster group
    const createClusterGroup = async e => {
        e.preventDefault();

        // Set up component hooks to display text on updates and status
        setSaving(true);
        props.setMessage({
            text: (
                <Typography>
                    Creating the {e.target.groupName.value} cluster group. Please wait.
                </Typography>
            ),
            status: 'info'
        });
        props.setOpen(true);

        try {
            let data = {
                cluster_group_data: {
                    name: e.target.groupName.value,
                    description: e.target.groupDescription.value
                }
            };

            let clusterGroup = await APICaller.sendRequest('createclustergroup', data);

            if (clusterGroup.message === 'Request failed with status code 500') {
                throw new Error('Internal Server Error');
            }

            props.setMessage({
                text: (
                    <Typography>
                        The {e.target.groupName.value} cluster group has been created successfully.
                    </Typography>
                ),
                status: 'success'
            });
            props.setOpen(true);
            setSaving(false);

            dispatch(updateClusterGroupList([...clusterGroups, clusterGroup]));

            setOpenGroup(false);
        } catch (error) {
            setSaving(false);
            props.setMessage({
                text: (
                    <Typography>
                        Error creating the {e.target.groupName.value} cluster group, per{' '}
                        {error.message}
                    </Typography>
                ),
                status: 'error'
            });
            props.setOpen(true);
            return;
        }
    };

    // When we click on a specific cluster, we want to open the Cluster Comparison with this cluster already selected
    // on the options on top. So we just need to ensure that we update the right model selection, depending on the
    // cluster and take users to the comparison page
    const openCluster = e => {
        e.preventDefault();

        // Once we load the component, we want to set up the clusterSelection structure, which is an array of objects,
        // one per clusterGroup, where we list inside the clusters that we want to compare
        const clusterSelectionArray = [...clusterGroups];
        clusterSelectionArray.map(cGroup => {
            return (cGroup.selection = []);
        });

        dispatch(setClusterSelection(clusterSelectionArray));

        // Retrieve the cluster information
        let cluster = clusters.find(c => c.id === parseInt(e.target.value));

        // Set the type of the model
        dispatch(setModel(cluster.model));

        // Check the model of the cluster that we wish to open and set up the selection with this ID
        if (cluster.model === 'kmeans') {
            dispatch(
                updateKmeansSelection({
                    clusterGroupId: cluster.groupId,
                    clusterArray: [cluster.label]
                })
            );
        } else {
            dispatch(
                updateDbscanSelection({
                    clusterGroupId: cluster.groupId,
                    clusterArray: [cluster.label]
                })
            );
        }

        // Open the Cluster Comparison
        props.goTo(`cluster_comparison/${parseInt(universe.id)}`);
    };

    // Function that will set up an unique cluster to be editable on the table.
    const setClusterToEdit = clusterId => {
        setClusterEditable([...clusterEditable, clusterId]);
    };

    // Function that will remove an unique cluster from the editable list
    const removeClusterToEdit = clusterId => {
        const newClusterEditable = clusterEditable.filter(c => c !== clusterId);
        setClusterEditable(newClusterEditable);
    };

    // Function to handle updating the cluster information, including the name and cluster group
    const updateCluster = async (e, id) => {
        e.preventDefault();
        setClusterLoading(true);

        try {
            // Update the Universe List with the new name and description
            if (clusters.length === 0) {
                throw new Error('No clusters in list');
            }
            const newClusterList = [...clusters];
            newClusterList.find(u => u.id === id).name = e.target.clusterName.value;
            newClusterList.find(u => u.id === id).groupId = parseInt(e.target.clusterGroup.value);

            const cluster_metadata = {
                cluster_id: id,
                cluster_name: await TextFormatter.formatText(e.target.clusterName.value),
                cluster_description: '',
                group_id: parseInt(e.target.clusterGroup.value),
                user_id: user.id
            };

            await APICaller.sendRequest('updateclusterinfo', { cluster_metadata });
            await dispatch(updateClusterList(newClusterList));

            props.setMessage({
                text: (
                    <Typography>
                        Updated Cluster {e.target.clusterName.value} successfully.
                    </Typography>
                ),
                status: 'success'
            });
            props.setOpen(true);

            setClusterLoading(false);
            removeClusterToEdit(id);
        } catch (error) {
            setClusterLoading(false);
            props.setMessage({
                text: (
                    <Typography>
                        Error updating Cluster {e.target.clusterName.value}. Please try again.
                    </Typography>
                ),
                status: 'error'
            });
            props.setOpen(true);
            console.error(error);
        }
    };

    // Function to handle opening the cluster comparison. We need to pass the ID of the universe, as we want to ensure
    // that we are opening this based on the universe alone.
    const openClusterComparison = (e, type) => {
        e.preventDefault();

        // Update model on the state
        dispatch(setModel(type));

        // Before we redirect to the cluster comparison, we take the type so we can filter out the relevant clusters
        // for the comparison, updating the redux state value for clusterList
        props.goTo(`cluster_comparison/${parseInt(universe.id)}`);
    };

    const columns = [
        {
            field: 'name',
            headerName: 'Cluster',
            hideable: false,
            sortable: true,
            flex: 1,
            minWidth: 200,
            renderCell: cluster => {
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            flexFlow: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            columnGap: 2,
                            mt: 1,
                            mb: 2,
                            width: 1
                        }}
                    >
                        {clusterEditable.length > 0 &&
                        clusterEditable.find(c => c === cluster.row.id) ? (
                            <form
                                onSubmit={e => updateCluster(e, cluster.row.id)}
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    justifyContent: 'flex-start',
                                    flexFlow: 'column'
                                }}
                            >
                                <TextField
                                    sx={{ mb: 2, mt: 2, width: 1 }}
                                    required
                                    margin='dense'
                                    id='clusterName'
                                    label='Cluster Name'
                                    type='text'
                                    size='small'
                                    defaultValue={cluster.row.name}
                                    onKeyDown={event => {
                                        if (event.code === 'Space') event.stopPropagation();
                                    }}
                                />
                                <FormControl sx={{ width: 1, mt: 2 }} size='large'>
                                    <InputLabel id='clusterGroup-helper-label'>
                                        Cluster Group
                                    </InputLabel>

                                    <Select
                                        required
                                        size='small'
                                        labelId='clusterGroup-helper-label'
                                        id='clusterGroup'
                                        label='Cluster Group'
                                        defaultValue={cluster.row.groupId}
                                        inputProps={{
                                            'data-testid': 'client-select-element',
                                            id: 'clusterGroup'
                                        }}
                                        sx={{ width: 1 }}
                                    >
                                        {clusterGroups.map((clusterOption, index) => (
                                            <MenuItem key={index} value={clusterOption.id}>
                                                {clusterOption.clusterGroupName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <Box>
                                    <Button type='submit' color='primary'>
                                        Update
                                    </Button>

                                    <Button
                                        onClick={e => {
                                            e.preventDefault();
                                            removeClusterToEdit(cluster.row.id);
                                        }}
                                        color='error'
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                            </form>
                        ) : (
                            <Fragment>
                                {editUniverse && (
                                    <IconButton
                                        onClick={e => {
                                            e.preventDefault();
                                            setClusterToEdit(cluster.row.id);
                                        }}
                                        aria-label={`edit ${
                                            cluster.row.name || `Cluster ${cluster.row.label}`
                                        } (id: ${cluster.row.label})`}
                                        size='large'
                                        color='primary'
                                        sx={{ width: '20px', height: '20px' }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                )}
                                <Box>
                                    <Button
                                        // startIcon={<LaunchIcon />}
                                        sx={{
                                            pl: 0,
                                            textAlign: 'left',
                                            whiteSpace: 'normal',
                                            wordWrap: 'break-word',
                                            justifyContent: 'flex-start',
                                            fontSize: '1rem'
                                        }}
                                        onClick={e => openCluster(e)}
                                        value={cluster.row.id}
                                    >
                                        {cluster.row.name || `Cluster ${cluster.row.label}`}
                                    </Button>
                                    <Typography variant='body2' sx={{ fontSize: '0.8rem' }}>
                                        {getClusterGroupName(parseInt(cluster.row.groupId || 0))}
                                    </Typography>
                                </Box>
                            </Fragment>
                        )}
                    </Box>
                );
            }
        },
        {
            field: 'model',
            headerName: 'Model',
            flex: 1
        },
        {
            field: 'patientCount',
            headerName: 'Cluster Size (US Adults)',
            flex: 1,
            renderCell: cluster => {
                return cluster.row.patientCount.toLocaleString('en-US');
            }
        },
        {
            field: 'patientProportion',
            headerName: '% of All Cluster',
            flex: 1,
            renderCell: cluster => {
                return `${parseFloat(cluster.row.patientProportion * 100).toFixed(2)}%`;
            }
        }
    ];

    return (
        <Fragment>
            <ProjectHeader goTo={props.goTo} />
            <Box>
                <Grid
                    sx={{
                        backgroundColor: '#0c1742',
                        pt: 1,
                        pb: 1,
                        display: 'grid',
                        gridTemplateColumns: '2fr 1fr',
                        columnGap: 2,
                        alignItems: 'center',
                        color: 'white'
                    }}
                >
                    <Container sx={{ pt: 2, pb: 2 }} maxWidth='false'>
                        {universeEditable ? (
                            <form
                                onSubmit={e => updateUniverseInfo(e)}
                                style={{
                                    width: '100%'
                                }}
                            >
                                <TextField
                                    sx={{ mb: 2, mt: 2, width: 3 / 4 }}
                                    required
                                    margin='dense'
                                    id='universeName'
                                    label='Universe Name'
                                    type='text'
                                    defaultValue={
                                        universe.universeName ? universe.universeName : ''
                                    }
                                    color='whitePalette'
                                    focused
                                />
                                <TextField
                                    required
                                    multiline
                                    rows={5}
                                    sx={{ width: 1, mt: 2, mb: 2 }}
                                    id='universeDescription'
                                    label='Universe Description'
                                    color='whitePalette'
                                    focused
                                    inputProps={{
                                        'data-testid': 'universe-textfield-description'
                                    }}
                                    defaultValue={
                                        universe.universeDescription
                                            ? universe.universeDescription
                                            : ''
                                    }
                                />
                                <Button type='submit' color='secondary'>
                                    Update
                                </Button>

                                <Button
                                    onClick={e => {
                                        e.preventDefault();
                                        setUniverseEditable(false);
                                    }}
                                    color='error'
                                >
                                    Cancel
                                </Button>
                            </form>
                        ) : (
                            <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 2 }}>
                                {editUniverse && !updating && !loading && (
                                    <IconButton
                                        onClick={e => {
                                            e.preventDefault();
                                            setUniverseEditable(true);
                                        }}
                                        title='Edit universe details'
                                        aria-label='Edit universe details'
                                        size='large'
                                        sx={{
                                            width: '20px',
                                            height: '20px',
                                            color: 'white'
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                )}
                                <Box sx={{ width: 1 }}>
                                    <Typography
                                        variant='h1'
                                        sx={{
                                            fontSize: '1.5rem',
                                            textAlign: 'left',
                                            pt: 2
                                        }}
                                    >
                                        {updating || loading ? (
                                            <Skeleton
                                                sx={{
                                                    borderRadius: '20px',
                                                    textAlign: 'center',
                                                    backgroundColor: 'white'
                                                }}
                                            />
                                        ) : (
                                            universe.universeName
                                        )}
                                    </Typography>
                                    <Typography variant='body1' sx={{ mt: 2, mb: 2 }}>
                                        {updating || loading ? (
                                            <Skeleton
                                                sx={{
                                                    backgroundColor: 'white',
                                                    borderRadius: '20px'
                                                }}
                                            />
                                        ) : (
                                            universe.universeDescription
                                        )}
                                    </Typography>
                                    {universe.universePatientCount > 0 && (
                                        <Box
                                            sx={{
                                                fontSize: '0.9rem',
                                                border: '1px solid white',
                                                display: 'inline-flex',
                                                p: 1
                                            }}
                                        >
                                            {updating || loading ? (
                                                <Skeleton
                                                    width={150}
                                                    sx={{
                                                        borderRadius: '20px',
                                                        textAlign: 'center',
                                                        backgroundColor: 'white'
                                                    }}
                                                />
                                            ) : (
                                                <span>
                                                    Current Patient Count:{' '}
                                                    {universe.universePatientCount}
                                                </span>
                                            )}
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        )}
                    </Container>
                    {!universeEditable && (
                        <Container>
                            {/* Adding a cluster should only be available when the user has edit rights, there's a
                            CSV ready, and the clustering status is not in progress. Clustering status informs the
                            stepper display as well as button visibility and state. */}
                            {create &&
                                filesReady &&
                                clusters.length === 0 &&
                                !clusterLoading && (
                                    <SecondaryBtnOnDarkBg
                                        size='large'
                                        variant='contained'
                                        startIcon={<AddIcon />}
                                        onClick={e => createCluster(e)}
                                        disabled={!optionList || !enableAddClustersBtn}
                                        sx={{ width: 3 / 4, float: 'right', mb: 2 }}
                                        color='secondary'
                                    >
                                        Add Clusters
                                    </SecondaryBtnOnDarkBg>
                                )}

                            {create &&
                                filesReady &&
                                clusters.length > 0 &&
                                !clusterLoading && (
                                    <SecondaryBtnOnDarkBg
                                        size='large'
                                        variant='contained'
                                        startIcon={<AddCircleOutlineIcon />}
                                        onClick={() => {
                                            setOpen(true);
                                        }}
                                        disabled={!optionList || !enableEditClustersBtn || !clusters ||
                                            clusters.length === 0 || stepValue !== 4}
                                        sx={{ width: 3 / 4, mb: 2, float: 'right' }}
                                        color='secondary'
                                    >
                                        Edit Clusters
                                    </SecondaryBtnOnDarkBg>
                                )}
                            {useQuery &&
                                editUniverse && (
                                    <Fragment>
                                        <SecondaryBtnOnDarkBg
                                            size='large'
                                            variant='contained'
                                            startIcon={<EditIcon />}
                                            onClick={editQuery}
                                            disabled={!enableEditUniverseBtn}
                                            sx={{ width: 3 / 4, float: 'right' }}
                                            color='secondary'
                                        >
                                            Edit Universe
                                        </SecondaryBtnOnDarkBg>
                                    </Fragment>
                                )}
                        </Container>
                    )}
                </Grid>

                <Container sx={{ mt: 2 }} maxWidth='false'>
                    {filesReady && (
                        <Stepper
                            activeStep={stepValue}
                            alternativeLabel
                            sx={{ mt: 2, mb: 4, width: 1 }}
                        >
                            {steps.map((label, index) => {
                                const labelProps = {};
                                if (isStepFailed(index)) {
                                    labelProps.optional = (
                                        <Typography variant='caption' color='error'>
                                            Error processing this step. Please retry clustering.
                                        </Typography>
                                    );

                                    labelProps.error = true;
                                }

                                return (
                                    <Step key={label}>
                                        <StepLabel {...labelProps} sx={{ textAlign: 'center' }}>
                                            {label}
                                        </StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    )}

                    {universe.universePatientCount === 0 && (
                        <Alert severity='error' sx={{ mt: 4, mb: 4 }}>
                            The patient count for this universe is zero. Adjust the parameters and regenerate the
                            universe to enable clustering.
                        </Alert>
                    )}

                    {!filesReady && universe.universePatientCount > 0 && (
                        <Alert severity='info' sx={{ mt: 4, mb: 4 }}>
                            Your universe will be available for profiling soon. Once completed, the option to create
                            clusters will be enabled.
                        </Alert>
                    )}

                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap-reverse',
                            columnGap: 1,
                            rowGap: 2
                        }}
                    >
                        <Box>
                            <Typography sx={{ clear: 'both' }}>
                                View and customize healthcare and lifestyle dimensions across all clusters.
                            </Typography>
                            <Button
                                size='large'
                                variant='contained'
                                startIcon={<CompareArrowsIcon />}
                                onClick={e => openClusterComparison(e, 'kmeans')}
                                disabled={
                                    !clusters ||
                                    clusters.length === 0 ||
                                    clusterLoading ||
                                    stepValue !== 4
                                }
                                sx={{ mt: 2, mb: 4 }}
                            >
                                Compare Kmeans Clusters
                            </Button>
                            <Button
                                size='large'
                                variant='contained'
                                startIcon={<CompareArrowsIcon />}
                                onClick={e => openClusterComparison(e, 'dbscan')}
                                disabled={
                                    !clusters ||
                                    clusters.length === 0 ||
                                    clusterLoading ||
                                    stepValue !== 4
                                }
                                sx={{ mt: 2, mb: 4, ml: 2 }}
                            >
                                Compare DBScan Clusters
                            </Button>
                        </Box>
                        <Box>
                            <Typography>
                                <strong>Download Options:</strong>
                                <Button
                                    variant='text'
                                    sx={{
                                        pt: 0,
                                        pb: '3px',
                                        pl: 1,
                                        pr: 1,
                                        minWidth: 'auto',
                                        fontSize: '1rem',
                                        lineHeight: 'unset'
                                    }}
                                    disabled={!filesReady || !enableDownloadUniverseBtn ||
                                        universe.universePatientCount === 0}
                                    onClick={() => {
                                        downloadXLS();
                                    }}
                                >
                                    Universe XLS
                                </Button>{' '}
                                |
                                <Button
                                    variant='text'
                                    sx={{
                                        pt: 0,
                                        pb: '3px',
                                        pl: 1,
                                        pr: 1,
                                        minWidth: 'auto',
                                        fontSize: '1rem',
                                        lineHeight: 'unset'
                                    }}
                                    disabled={!filesReady || clusters.length === 0}
                                    onClick={() => {
                                        downloadXLS('kmeans');
                                    }}
                                >
                                    Kmeans XLS
                                </Button>{' '}
                                |
                                <Button
                                    variant='text'
                                    sx={{
                                        pt: 0,
                                        pb: '3px',
                                        pl: 1,
                                        pr: 1,
                                        minWidth: 'auto',
                                        fontSize: '1rem',
                                        lineHeight: 'unset'
                                    }}
                                    disabled={!filesReady || clusters.length === 0}
                                    onClick={() => {
                                        downloadXLS('dbscan');
                                    }}
                                >
                                    DBScan XLS
                                </Button>{' '}
                                |
                                <Button
                                    variant='text'
                                    sx={{
                                        pt: 0,
                                        pb: '3px',
                                        pl: 1,
                                        pr: 1,
                                        minWidth: 'auto',
                                        fontSize: '1rem',
                                        lineHeight: 'unset'
                                    }}
                                    disabled={!clusterZip}
                                    onClick={downloadZip}
                                >
                                    Model (Zipped)
                                </Button>{' '}
                            </Typography>{' '}
                        </Box>
                    </Box>

                    <Divider variant='middle' sx={{ ml: 0, mr: 0 }} />
                    {!universeEditable && (
                        <Button
                            size='small'
                            variant='outlined'
                            startIcon={<AddIcon />}
                            onClick={() => {
                                setOpenGroup(true);
                            }}
                            disabled={!optionList || clusters.length === 0}
                            sx={{ mt: 2 }}
                            color='primary'
                        >
                            Add Cluster Group
                        </Button>
                    )}

                    <Box style={{}} sx={{ mt: 2, clear: 'both' }}>
                        <StripedDataGrid
                            getRowClassName={params =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                            }
                            className='cluster-grid'
                            getRowHeight={() => 'auto'}
                            getRowId={row => `cluster_${row.id}`}
                            rows={clusters}
                            isRowSelectable={() => false}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[10]}
                            autoHeight
                            autoPageSize={true}
                            loading={clusterLoading}
                            disableColumnFilter={true}
                        />
                    </Box>
                </Container>
            </Box>

            {/* Create new Cluster Group Dialog */}
            <Dialog
                open={openGroup}
                onClose={() => {
                    setOpenGroup(false);
                }}
                TransitionComponent={Transition}
                aria-describedby='alert-dialog-addUser-description'
                maxWidth='sm'
            >
                <DialogTitle sx={{ pb: 1 }}>
                    <span style={{ textTransform: 'capitalize' }}>Add</span> Cluster Group
                </DialogTitle>

                <form onSubmit={createClusterGroup}>
                    <DialogContent sx={{ pt: 0, rowGap: 2 }}>
                        <DialogContentText id='alert-dialog-addUser-description'>
                            Add a new cluster group to the available universe list.
                        </DialogContentText>

                        <TextField
                            sx={{ mb: 2, mt: 2 }}
                            required
                            margin='dense'
                            id='groupName'
                            label='Name'
                            type='text'
                            fullWidth
                            variant='standard'
                            defaultValue=''
                        />
                        <TextField
                            required
                            variant='standard'
                            multiline
                            rows={5}
                            sx={{ mt: 2, mb: 2, width: 1 }}
                            id='groupDescription'
                            label='Description'
                            inputProps={{
                                'data-testid': 'clusterGroup-textfield-description'
                            }}
                            defaultValue=''
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button type='submit' disabled={saving}>
                            Add
                        </Button>

                        <Button
                            onClick={() => {
                                setOpenGroup(false);
                            }}
                            color='error'
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
            {/* Dialog for moving away of universe cart with custom query enabled confirmation */}
            <Dialog
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                aria-labelledby='warning-dialog-title'
                aria-describedby='warning-dialog-description'
            >
                <DialogTitle
                    id='warning-dialog-title'
                    sx={{ alignItems: 'center', display: 'flex', columnGap: 2 }}
                >
                    <WarningIcon color='primary' sx={{ fontSize: '25px' }} />
                    {'Cluster Warning'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='warning-dialog-description'>
                        Saving new parameters and running the clustering on the wizard will override
                        any of the clusters currently created. Are you sure you want to view the
                        clustering wizard?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={createCluster}>
                        Yes, please take me to the clustering wizard
                    </Button>
                    <Button
                        color='error'
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}
