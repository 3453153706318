export const BUILDQUERY = 'BUILDQUERY';
export const BUILDOPTIONLIST = 'BUILDOPTIONLIST';
export const UPDATEOPTIONLIST = 'UPDATEOPTIONLIST';
export const UPDATEQUERY = 'UPDATEQUERY';
export const UPDATECUSTOMQUERY = 'UPDATECUSTOMQUERY';
export const UPDATEUSECUSTOM = 'UPDATEUSECUSTOM';
export const UPDATEDEMOGRAPHICS = 'UPDATEDEMOGRAPHICS';
export const BUILDDIAGNOSISDATA = 'BUILDDIAGNOSISDATA';
export const UPDATEDIAGNOSIS = 'UPDATEDIAGNOSIS';
export const BUILDPROCEDURESDATA = 'BUILDPROCEDURESDATA';
export const UPDATEPROCEDURES = 'UPDATEPROCEDURES';
export const BUILDPRESCRIPTIONSDATA = 'BUILDPRESCRIPTIONSDATA';
export const UPDATEPRESCRIPTIONS = 'UPDATEPRESCRIPTIONS';
export const BUILDSPECIALISTSDATA = 'BUILDSPECIALISTSDATA';
export const UPDATESPECIALISTS = 'UPDATESPECIALISTS';
