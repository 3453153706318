// React
import React, { useState, useEffect, Fragment } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentCluster } from '../../redux/actions/cluster/clusterActions';

// Components
import ProjectHeader from '../layout/ProjectHeader';

// Materialize Includes
import {
    Container,
    Box,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Breadcrumbs,
    Link
} from '@mui/material';

// Import Icons
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export default function UniverseOverview(props) {
    const dispatch = useDispatch();

    // Component Hooks
    const [loading, setLoading] = useState(false);

    // Redux Hooks
    const universe = useSelector(state => state.universe.current);
    const cluster = useSelector(state => state.cluster.current);
    const clusters = useSelector(state => state.cluster.clusterList);
    const clusterGroups = useSelector(state => state.cluster.clusterGroupList);

    useEffect(() => {
        let mounted = true;

        const fetchData = async () => {
            setLoading(true);

            // In order to pull the information from an universe, we use two different sources. In one hand, we will be retrieving the basic universe information from the universeList, which means that we can render the universeOverview faster without having to wait for any sort of data. This will update the state for the universe, as we don't need any real-time updates to be reflected here.
            if (clusters && clusters.length > 0) {
                dispatch(setCurrentCluster(clusters.find(c => c.id === parseInt(props.id))));
                setLoading(false);
            }
        };

        // We only fetchData is there is no current universe in the state
        if (!cluster.clusteName) {
            fetchData();
        }

        // We only fetchData is there is no current universe in the state
        if (mounted) {
            fetchData();
        }

        return () => (mounted = false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Handle the click of going back to the Project Overview page.
    function goBack(event) {
        event.preventDefault();
        props.goTo(`universe_overview/${parseInt(universe.id)}`);
    }

    // Adding a constant value to keep track of the breadcrumbs of this page. For Query Builder, we are only working with the Project Overview page and the Query Builder
    const breadcrumbs = [
        <Link
            underline='hover'
            key='2'
            color='primary'
            href='/material-ui/getting-started/installation/'
            onClick={goBack}
            sx={{ fontWeight: 500 }}
        >
            {universe.universeName} Overview
        </Link>,
        <Typography key='3' color='text.primary' sx={{ color: 'white' }}>
            {cluster.clusterName} Overview
        </Typography>
    ];

    return (
        <Fragment>
            <ProjectHeader goTo={props.goTo} />
            <Box className='cluster-page'>
                <Container
                    sx={{
                        pt: 2,
                        pb: 2,
                        backgroundColor: '#0c1742',
                        position: 'relative',
                        color: 'white'
                    }}
                    maxWidth='xl'
                >
                    <Breadcrumbs
                        separator={<NavigateNextIcon fontSize='small' color='whitePalette' />}
                        aria-label='breadcrumb'
                    >
                        {breadcrumbs}
                    </Breadcrumbs>
                    <Typography variant='h1' sx={{ fontSize: '1.5rem', mt: 2 }}>
                        {cluster.name}
                    </Typography>
                    <FormControl
                        sx={{
                            width: 1 / 4,
                            position: 'absolute',
                            right: '16px',
                            top: '16px',
                            color: 'white'
                        }}
                        size='small'
                    >
                        <InputLabel id='clusterGroup-helper-label'>Cluster Group</InputLabel>
                        <Select
                            color='whitePalette'
                            required
                            size='small'
                            labelId='clusterGroup-helper-label'
                            id='clusterGroup-select'
                            label='Cluster Group'
                            // onChange={updateClient}
                            defaultValue=''
                            value={cluster.groupId}
                            inputProps={{
                                'data-testid': 'client-select-element'
                            }}
                            sx={{ width: 1 }}
                        >
                            {clusterGroups.map((clusterOption, index) => (
                                <MenuItem key={index} value={clusterOption.id}>
                                    {clusterOption.clusterGroupName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Typography variant='body1' sx={{ mt: 4, mb: 2 }}>
                        {cluster.description}
                    </Typography>
                </Container>
                <Container sx={{ mt: 4 }} maxWidth='xl'>
                    <Typography sx={{ clear: 'both' }}></Typography>
                </Container>
            </Box>
        </Fragment>
    );
}
