import {
    CREATEUNIVERSE,
    UPDATEUNIVERSE,
    UPDATEUNIVERSELIST,
    REGISTERUPDATE
} from './universeActionTypes';

export const createUniverse = universe => ({
    type: CREATEUNIVERSE,
    payload: universe
});

export const updateUniverseList = universeList => ({
    type: UPDATEUNIVERSELIST,
    payload: universeList
});

export const updateUniverse = universe => ({
    type: UPDATEUNIVERSE,
    payload: universe
});

export const registerUpdate = universe => ({
    type: REGISTERUPDATE,
    payload: universe
});