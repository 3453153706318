import axios from 'axios';
import * as env from './EnvironmentVariablesReader';

class LLCaller {
    sendRequest = async (path, data) => {
        let url = `${env.WEB_API_BASE_URL}/${path}`;
        return await axios.post(url, data);
    };
}

export default new LLCaller();
