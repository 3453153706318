// React
import { useState, useEffect } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { createUniverse, updateUniverseList } from '../../redux/actions/universe/universeActions';
import { buildQuery } from '../../redux/actions/query/queryActions';

// Utilities
import APICaller from '../../utils/APICaller';
import TextFormatter from '../../utils/TextFormatter';

// Materialize UI Includes
import { Box } from '@mui/system';
import {
    Typography,
    TextField,
    Button,
    Select,
    FormControl,
    InputLabel,
    MenuItem
} from '@mui/material';

export default function CreateUniverse(props) {
    const dispatch = useDispatch();

    // Component Hooks
    const [data_sources, setDataSources] = useState([]);
    const [chosenDataSource, setChosenDataSource] = useState(1);
    const [saving, setSaving] = useState(false);

    // Redux Hooks
    const user = useSelector(state => state.user);
    const project = useSelector(state => state.project.current);
    const universe = useSelector(state => state.universe.current);

    // Adding a constant variable to store the proper structure of a new universe to use below
    const queryTemplate = {
        type: 'add', // We are going to add a key for type just to keep track if this is a new query or an updated one, which will help us define which endpoints to call on universe as well as some UI changes
        demographics: {
            age: [],
            gender: [],
            region: [],
            state: []
        },
        // The diagnosis is an array of groups, each with their own values and operators
        diagnosis: [
            {
                type: 'inclusion',
                dimension: []
            },
            {
                type: 'exclusion',
                dimension: []
            }
        ],
        // The prescriptions is an array of groups, each with their own values and operators
        prescriptions: [
            {
                type: 'inclusion',
                dimension: []
            },
            {
                type: 'exclusion',
                dimension: []
            }
        ],
        procedures: [
            {
                type: 'inclusion',
                dimension: []
            },
            {
                type: 'exclusion',
                dimension: []
            }
        ],
        specialists: [
            {
                type: 'inclusion',
                dimension: []
            },
            {
                type: 'exclusion',
                dimension: []
            }
        ]
    };

    useEffect(() => {
        // window.dataLayer.push({
        //     event: 'pageview',
        //     page: {
        //         url: '',
        //         title: 'Create/Edit Universe Page'
        //     }
        // });

        const fetchData = async () => {
            let source_systems = await getSourceSystems();

            // Update the descriptions of each of the elements on the data source
            source_systems[0].description =
                'De-Identified Patient Data Set 1: 3 million patient sample, closed dataset of de-identified patient claims, 4 years of longitudinal history ';
            source_systems[1].description =
                'De-Identified Patient Data Set 2: 30 million well-distributed patient sample, open dataset of de-identified claims, 3 years of history updated monthly ';
            setDataSources(source_systems);
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getSourceSystems = async () => {
        return APICaller.sendRequest('listsourcesystems', {});
    };

    const handleSourceChange = event => {
        setChosenDataSource(event.target.value);
    };

    const createUniverseOnclick = async e => {
        try {
            e.preventDefault();

            // Set up component hooks to display text on updates and status
            setSaving(true);
            props.setMessage({
                text: (
                    <Typography>
                        Creating the {e.target.universe_name.value} universe. Please wait.
                    </Typography>
                ),
                status: 'info'
            });
            props.setOpen(true);

            let universe_data = {
                universe_metadata: {
                    project_id: project.id,
                    universe_name: await TextFormatter.formatText(e.target.universe_name.value),
                    universe_description: await TextFormatter.formatText(
                        e.target.universe_description.value
                    ),
                    source_system: chosenDataSource,
                    user_id: user.id
                }
            };

            let universe;

            let createUniversePromise = new Promise((resolve, reject) => {
                APICaller.sendRequest('createuniverse', universe_data)
                    .then(result => {
                        if (!result) {
                            reject('No Universe created.');
                        } else {
                            resolve(result);
                        }
                    })
                    .catch(err => reject(err));
                // setTimeout(resolve, 50, 'one');
            });

            // We create a timeout promise, and a promise race, so we can ensure that we are only waiting 30 seconds when creating a new universe. Taking longer than that will means that something ocurr and we can consider this as an issue.
            let timeOutPromise = new Promise((_, reject) =>
                setTimeout(() => reject('Timeout'), 30000)
            );

            Promise.race([createUniversePromise, timeOutPromise])
                .then(async data => {
                    props.setMessage({
                        text: (
                            <Typography>
                                The {e.target.universe_name.value} universe has been created
                                successfully.
                            </Typography>
                        ),
                        status: 'success'
                    });
                    props.setOpen(true);

                    universe = data;
                    dispatch(createUniverse(universe));

                    // Once we have the newly created universe, we call the endpoint again to make sure that the universeList is updated
                    dispatch(
                        updateUniverseList(
                            await APICaller.sendRequest('listprojectuniverses', {
                                project_id: project.id
                            })
                        )
                    );

                    // A user might have access to create a universe but not a query. If that is the case, the app will not build a query nor will it sent the view to query_builder, going to the project details instead.
                    setSaving(false);

                    if (user.role.query.create) {
                        dispatch(buildQuery(queryTemplate));
                    }

                    props.goTo('query_builder');
                })
                .catch(err => {
                    setSaving(false);
                    console.error(err);
                    props.setMessage({
                        text: (
                            <Typography>
                                Error creating the {e.target.universe_name.value} universe, per{' '}
                                {err.message}
                            </Typography>
                        ),
                        status: 'error'
                    });
                    props.setOpen(true);
                    return;
                });
        } catch (error) {
            console.error(error);
        }
    };

    const cancelUniverseCreation = e => {
        e.preventDefault();
        props.goTo('project_overview');
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                pt: 3,
                pb: 3,
                height: '95%'
            }}
        >
            <Box
                sx={{
                    width: 3 / 4,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexFlow: 'column'
                }}
            >
                <Typography variant='h1' sx={{ textAlign: 'left', mb: 2, fontSize: '2.5rem' }}>
                    <span style={{ textTransform: 'capitalize' }}>Add</span> Universe Name and
                    Select Data Sources
                </Typography>
                <form onSubmit={e => createUniverseOnclick(e)} style={{ width: '100%' }}>
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
                            columnGap: 2,
                            width: 1
                        }}
                    >
                        <Box>
                            <TextField
                                required
                                sx={{ width: 1, mb: 2 }}
                                id='universe_name'
                                label='Universe Name'
                                variant='filled'
                                defaultValue={universe ? universe.universeName : ''}
                                diasbled={saving}
                            />
                            <TextField
                                required
                                multiline
                                rows={5}
                                sx={{ width: 1, mt: 2, mb: 2 }}
                                id='universe_description'
                                label='Universe Description'
                                variant='filled'
                                inputProps={{
                                    'data-testid': 'universe-textfield-description'
                                }}
                                disabled={saving}
                            />
                            <FormControl sx={{ width: 1, mb: 2, mt: 2 }} size='large'>
                                <InputLabel id='client-helper-label'>Data Source</InputLabel>
                                <Select
                                    required
                                    labelId='client-helper-label'
                                    id='client-select-select'
                                    label='Data Source'
                                    onChange={handleSourceChange}
                                    defaultValue=''
                                    value={chosenDataSource || 1}
                                >
                                    {data_sources.map((dataSource, index) => (
                                        <MenuItem key={index} value={dataSource.id}>
                                            {dataSource.description}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            columnGap: 2,
                            width: '50%',
                            paddingRight: 1
                        }}
                    >
                        <Button
                            variant='contained'
                            size='large'
                            sx={{ mt: 2, width: 1 }}
                            type='submit'
                            disabled={!data_sources || data_sources.length === 0 || saving}
                            color='primary'
                        >
                            Create Universe
                        </Button>
                        <Button
                            variant='contained'
                            size='large'
                            sx={{ mt: 2, width: 1 }}
                            type='submit'
                            color='error'
                            onClick={e => cancelUniverseCreation(e)}
                            disabled={saving}
                        >
                            Cancel
                        </Button>
                    </Box>
                </form>
            </Box>
        </Box>
    );
}
