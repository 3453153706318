// React
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Components
import App from './App';
import Home from './Home';
import Login from './components/pages/Login';
import Ask from './components/pages/Ask';

// Redux
import { Provider } from 'react-redux';
import { setupStore } from './redux/store';

// Utilities
import reportWebVitals from './reportWebVitals';
import * as env from './utils/EnvironmentVariablesReader';

//identify if you are on development or production
//when you build your app process.env.NODE_ENV is set to 'production'

// Setup store
const store = setupStore({});

if (env.TEST_MODE_FLAG) {
    const axe = require('@axe-core/react');
    axe(React, ReactDOM, 1000);
}

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <Routes>
                <Route path='/' element={<App />} />
                <Route path='/home' element={<Home />} />
                <Route path='/login' element={<Login />} />
                <Route path='/ask' element={<Ask />} />
                {/* Todo: Create routes pointing to all pages. This should allow that copy and pasting an URL should take users to the right page. While allowing this, there needs to be a validation where not having access to a page should redirect automatically to home, such as the QB for Guest Users.  */}
            </Routes>
        </Router>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
