import {
    ADDUSER,
    UPDATEUSERROLE,
    CLEARUSERS,
    UPDATEUSERSTATUS,
    UPDATEUSERDETAILS
} from './usersActionTypes';

export const addUser = user => ({
    type: ADDUSER,
    payload: user
});

export const updateUserRole = user => ({
    type: UPDATEUSERROLE,
    payload: user
});

export const updateUserStatus = user => ({
    type: UPDATEUSERSTATUS,
    payload: user
});

export const updateUserDetails = user => ({
    type: UPDATEUSERDETAILS,
    payload: user
});

export const clearUsers = () => ({
    type: CLEARUSERS,
    payload: []
});
