// React
import React, { useState, useEffect, Fragment } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { updateDemographics } from '../../redux/actions/query/queryActions';
import { updateOptionList } from '../../redux/actions/query/queryActions';

// Materialize Includes for Checkboxes and Form Controls
import {
    Typography,
    Box,
    FormLabel,
    FormControl,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Skeleton,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Utilities
import APICaller from '../../utils/APICaller';

export default function Demographics(props) {
    const dispatch = useDispatch();

    // optionList is a variable that we only care about in the context of this component. There is no need to add this to the state, so we handle this through hooks of the component
    // Redux State
    const query = useSelector(state => state.query);
    const optionList = useSelector(state => state.query.optionList);

    // Component State
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            let listages = await APICaller.sendRequest('listagegroups', {});
            let listgenders = await APICaller.sendRequest('listgenders', {});
            let listregions = await APICaller.sendRequest('listregions', {});
            let liststates = await APICaller.sendRequest('liststates', {});

            // Add an empty states array in every region
            listregions.map(r => {
                return (r.states = []);
            });

            // Once we have both regions and states, we need to alter the array of states to add the respective regions
            liststates.forEach(state => {
                listregions
                    .find(r => r.id === state.regionId)
                    .states.push({
                        id: state.id,
                        code: state.code,
                        description: state.description
                    });
            });

            dispatch(
                updateOptionList({
                    listages: listages,
                    listgenders: listgenders,
                    listregions: listregions,
                    liststates: liststates
                })
            );
        };
        if (optionList) {
            setLoading(false);
        } else {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [optionList]);

    // Whenever we have a change on the checkboxes, we want to update the current demographics appropiate value on the state to reflect the new options selected.
    const handleChange = event => {
        props.registerChanges();
        // Once we get a change on the checkmarks, we want to do an analysis to which form group it belongs to, of the 3 available options. We also want to know if the checkbox was checked or not, as that will determine what we will do with the demographics state.
        let groupName = event.target.parentNode.attributes.category.value;
        let isChecked = event.target.checked;

        // Because when we update the state array we want to use integers instead of strings, but the ID is returned as a string, we want to update this right from the top.
        let optionId = parseInt(event.target.id.split('_')[1]);

        // Variable to keep track of the array that we will want to change
        let arrayToChange = [...query.demographics[groupName]];

        // First we work with what we will do whenever isChecked is true, which is adding the option to the proper array
        if (isChecked) {
            // Due to issues when updating the state and checked value, we need to check the checkmark itself if the value is true.
            event.target.checked = true;

            // When we are checking another checkbox, and there's already a value of 0 (ALL), we need to remove it from the array
            if (optionId !== 0 && arrayToChange.includes(0)) {
                arrayToChange.splice(
                    arrayToChange.findIndex(o => o === 0),
                    1
                );
            } else if (optionId === 0) {
                arrayToChange = [];
            }

            // Push the new element of the checkbox to the array
            arrayToChange.push(parseInt(optionId));
        } else {
            arrayToChange = arrayToChange.filter(function (el) {
                return el !== optionId;
            });
        }

        // Dispatch change to the demographics state
        switch (groupName) {
            case 'age':
                dispatch(
                    updateDemographics({
                        ...query.demographics,
                        age: arrayToChange
                    })
                );
                break;
            case 'gender':
                dispatch(
                    updateDemographics({
                        ...query.demographics,
                        gender: arrayToChange
                    })
                );
                break;
            case 'region': {
                // Whenever we are updating a region, we should also update the states inside, depending if the isChecked is true or not, which means adding all states or none. We do need to replicate the state array because we want to know previous selections that might already be saved in the data.
                let regionStateSelection = [...query.demographics.state];

                // Before we proceed no further, if the regionId is 0, we need to completely clear the state array
                if (optionId === 0) {
                    regionStateSelection = [];
                }

                if (isChecked) {
                    optionList.listregions
                        .find(r => r.id === optionId)
                        .states.forEach(state => {
                            // We only want to add to the array in case the ID is not there.
                            if (!query.demographics.state.find(s => s === state.id)) {
                                regionStateSelection.push(state.id);
                            }
                        });
                } else {
                    // We need to carefully delete only the states that are from the region, in case there was another a selection in place.
                    optionList.listregions
                        .find(r => r.id === optionId)
                        .states.forEach(state => {
                            // We only want to add to the array in case the ID is not there.
                            if (query.demographics.state.find(s => s === state.id)) {
                                regionStateSelection.splice(
                                    regionStateSelection.findIndex(sI => sI === state.id),
                                    1
                                );
                            }
                        });
                }

                dispatch(
                    updateDemographics({
                        ...query.demographics,
                        region: arrayToChange,
                        state: regionStateSelection
                    })
                );
                break;
            }
            case 'state': {
                // When we do state changes, we want to also change the region checkmark under two conditions: Either all States are selected manually (the region should be checked) or none of the states are selected (the state should be unchecked).
                let shouldComplete = false;
                let shouldRemove = false;
                const regionData = optionList.listregions.find(r =>
                    r.states.find(rS => rS.id === optionId)
                );
                const regionStatesArray = regionData.states.map(s => s.id);
                let newRegionArray = [...query.demographics.region];

                if (isChecked) {
                    // If the element was checked, we want to know if all the states of the region are now part of the states Array
                    shouldComplete = regionStatesArray.every(s => arrayToChange.includes(s));
                } else {
                    // If the element was unchecked, we want to know if there are no states of the region on the array
                    shouldRemove = !arrayToChange.some(r => regionStatesArray.indexOf(r) >= 0);
                }

                // Once we are done evaluating this, we want to know if the region should be completed to update the region array.
                if (shouldComplete) {
                    newRegionArray.push(regionData.id);
                } else if (shouldRemove) {
                    newRegionArray = newRegionArray.filter(function (el) {
                        return el !== regionData.id;
                    });
                }

                dispatch(
                    updateDemographics({
                        ...query.demographics,
                        state: arrayToChange,
                        region: newRegionArray
                    })
                );
                break;
            }
            default:
                break;
        }
    };

    // Function to control if the region checkbox should show up as partial, which only applies when the state array includes one to several (but not all) states of the region
    const checkIfPartial = regionId => {
        let ifPartial = false;
        const regionData = optionList.listregions.find(r => r.id === regionId);
        const regionStatesArray = regionData.states.map(s => s.id);

        // Check if the current state includes at least one of the elements of the state array
        ifPartial = query.demographics.state.some(r => regionStatesArray.indexOf(r) >= 0);

        // Once we set this up, we want to set partial to false if all the elements of the state are found. We only do this if the partial might be true from the previous validation
        if (ifPartial) {
            ifPartial = !regionStatesArray.every(s => query.demographics.state.includes(s));
        }

        return ifPartial;
    };

    return (
        <Box sx={{ mt: 4 }}>
            <Typography variant='h3' sx={{ fontSize: '1.5rem' }}>
                Demographic Dimensions
            </Typography>
            <Box sx={{ display: 'flex' }}>
                <FormControl sx={{ m: 3, width: 1 / 4 }} component='fieldset' variant='standard'>
                    <FormLabel component='legend'>Age Ranges:</FormLabel>
                    <FormGroup>
                        {loading ? (
                            <Skeleton
                                animation='pulse'
                                sx={{
                                    width: 1,
                                    height: '300px',
                                    mt: '-50px',
                                    mb: '-100'
                                }}
                            ></Skeleton>
                        ) : (
                            <Fragment>
                                {optionList &&
                                    optionList.listages.length > 0 &&
                                    optionList.listages.map((ageGroup, index) => (
                                        <FormControlLabel
                                            key={index}
                                            control={
                                                <Checkbox
                                                    checked={query.demographics.age.includes(
                                                        ageGroup.id
                                                    )}
                                                    onChange={handleChange}
                                                    name={ageGroup.description}
                                                    category='age'
                                                    id={`age_${ageGroup.id.toString()}`}
                                                    inputProps={{
                                                        'aria-labelledby': ageGroup.description,
                                                        'data-testid': `test_age_${ageGroup.description}`
                                                    }}
                                                />
                                            }
                                            label={ageGroup.description}
                                        />
                                    ))}
                            </Fragment>
                        )}
                    </FormGroup>
                </FormControl>
                <FormControl sx={{ m: 3, width: 1 / 4 }} component='fieldset' variant='standard'>
                    <FormLabel component='legend'>Genders:</FormLabel>
                    <FormGroup>
                        {loading ? (
                            <Skeleton
                                animation='pulse'
                                sx={{
                                    width: 1,
                                    height: '300px',
                                    mt: '-50px',
                                    mb: '-100'
                                }}
                            ></Skeleton>
                        ) : (
                            <Fragment>
                                {optionList &&
                                    optionList.listages.length > 0 &&
                                    optionList.listgenders.map((genderGroup, index) => (
                                        <FormControlLabel
                                            key={index}
                                            control={
                                                <Checkbox
                                                    checked={query.demographics.gender.includes(
                                                        genderGroup.id
                                                    )}
                                                    onChange={handleChange}
                                                    name={genderGroup.description}
                                                    category='gender'
                                                    id={`gender_${genderGroup.id.toString()}`}
                                                    inputProps={{
                                                        'aria-labelledby': genderGroup.description,
                                                        'data-testid': `test_gender_${genderGroup.description}`
                                                    }}
                                                />
                                            }
                                            label={genderGroup.description}
                                        />
                                    ))}
                            </Fragment>
                        )}
                    </FormGroup>
                </FormControl>
                <FormControl sx={{ m: 3, width: 1 / 4 }} component='fieldset' variant='standard'>
                    <FormLabel component='legend'>Regions:</FormLabel>
                    <FormGroup>
                        {loading ? (
                            <Skeleton
                                animation='pulse'
                                sx={{
                                    width: 1,
                                    height: '300px',
                                    mt: '-50px',
                                    mb: '-100'
                                }}
                            ></Skeleton>
                        ) : (
                            <Fragment>
                                {optionList &&
                                    optionList.listregions.length > 0 &&
                                    optionList.listregions.map((regionGroup, index) => (
                                        <Fragment key={index}>
                                            {regionGroup.id === 0 && (
                                                <FormControlLabel
                                                    key={index}
                                                    control={
                                                        <Checkbox
                                                            checked={query.demographics.region.includes(
                                                                regionGroup.id
                                                            )}
                                                            onChange={handleChange}
                                                            name={regionGroup.description}
                                                            category='region'
                                                            id={`region_${regionGroup.id.toString()}`}
                                                            inputProps={{
                                                                'aria-labelledby':
                                                                    regionGroup.description,
                                                                'data-testid': `test_region_${regionGroup.description}`
                                                            }}
                                                        />
                                                    }
                                                    label={regionGroup.description}
                                                />
                                            )}
                                            {regionGroup.id !== 0 && (
                                                <Accordion
                                                    key={index}
                                                    className='qbd-accordion'
                                                    sx={{
                                                        marginTop: 0,
                                                        boxShadow: 0,
                                                        padding: 0
                                                    }}
                                                >
                                                    <AccordionSummary
                                                        style={{
                                                            margin: '0px 0px',
                                                            minHeight: '0px',
                                                            padding: '0px'
                                                        }}
                                                        className='qbd-accordion-summary'
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls={`panel${index}a-content`}
                                                        id={`panel${index}a-header`}
                                                    >
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={query.demographics.region.includes(
                                                                        regionGroup.id
                                                                    )}
                                                                    onChange={handleChange}
                                                                    name={regionGroup.description}
                                                                    category='region'
                                                                    key={
                                                                        'regionOopt_' +
                                                                        regionGroup.id.toString()
                                                                    }
                                                                    id={`region_${regionGroup.id.toString()}`}
                                                                    indeterminate={checkIfPartial(
                                                                        regionGroup.id
                                                                    )}
                                                                    inputProps={{
                                                                        'aria-labelledby':
                                                                            regionGroup.description,
                                                                        'data-testid': `test_region_${regionGroup.description}`
                                                                    }}
                                                                />
                                                            }
                                                            label={regionGroup.description}
                                                        />
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        {regionGroup.states.length > 0 && (
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    ml: 3
                                                                }}
                                                            >
                                                                {regionGroup.states.map(
                                                                    (state, jindex) => (
                                                                        <FormControlLabel
                                                                            key={jindex}
                                                                            control={
                                                                                <Checkbox
                                                                                    checked={query.demographics.state.includes(
                                                                                        state.id
                                                                                    )}
                                                                                    onChange={
                                                                                        handleChange
                                                                                    }
                                                                                    name={
                                                                                        state.description
                                                                                    }
                                                                                    category='state'
                                                                                    key={
                                                                                        'stateOopt_' +
                                                                                        state.id.toString()
                                                                                    }
                                                                                    id={`state_${state.id.toString()}`}
                                                                                    inputProps={{
                                                                                        'aria-labelledby':
                                                                                            state.description,
                                                                                        'data-testid': `test_state_${state.description}`
                                                                                    }}
                                                                                />
                                                                            }
                                                                            label={
                                                                                state.description
                                                                            }
                                                                        />
                                                                    )
                                                                )}
                                                            </Box>
                                                        )}
                                                    </AccordionDetails>
                                                </Accordion>
                                            )}
                                        </Fragment>
                                    ))}
                            </Fragment>
                        )}
                    </FormGroup>
                </FormControl>
            </Box>
        </Box>
    );
}
